import { IUploadFileModel, UploadStatus } from 'shared/components/FileHandler/file-service';
import appConstants, { products } from 'App/app-constants';
import { vlogger } from 'shared/service/logger/vlogger';

/**
 * Report filename delimiters and parameter locations
 */
const reportFilenameDelimiter = appConstants.reportFilenameDelimiter;
const reportFilenameFormat = appConstants.reportsNameFormat;
const reportErrorMessages = appConstants.reportsErrorMessages;
const reportAcceptFileFormats = appConstants.reportsAcceptFileFormats;


/**
 * File Checks Interface  - use for DI (or DI like)
 * @interface
 */
export interface IFileChecks {
  filenameFormat(file: File): IUploadFileModel;
}

/** 
 * Class representing the File Integrity checks http interface object
 * @class
 * @implements IFileChecks
 */
export class FileChecks implements IFileChecks {
  private _defaultFileModel: IUploadFileModel;
  private _clientIds: number[];

  /**
   * CTOR
   * @constructor
   * @param {number[]} clientIds 
   */
  public constructor(clientIds: number[]) {
    this._clientIds = clientIds;

    this._defaultFileModel = {
      name: '', date: '', product: '', format: '', error: [], uploadStatus: UploadStatus.NotStarted,
    };
  }


  /**
   * Valiation checks on the file to upload
   * @param {FILE} file 
   * @returns {IUploadFileModel}
   */
  filenameFormat(file: File): IUploadFileModel {
    //  NOTE:  when you have nested objects, the spread operator 
    // creates a deep clone of the first level, and a shallow clone of the deeper levels.
    const fileModel: IUploadFileModel = { ...this._defaultFileModel };
    fileModel.error = [];

    try {
      const filename = file.name;
      fileModel.name = filename;
      fileModel.uploadStatus = UploadStatus.NotStarted;
      fileModel.file = file;

      // parse filename
      const parsedFilename = filename.split(reportFilenameDelimiter);
      if (parsedFilename.length <= Object.keys(appConstants.reportsNameFormat).length)
        throw 'bad file naming format';

      // get / verify clientId
      fileModel.clientId = parseInt(parsedFilename[reportFilenameFormat.clientId]);
      const isInClientIdList = this._clientIds.indexOf(fileModel.clientId) >= 0;
      if (!isInClientIdList) {
        fileModel.error.push(reportErrorMessages['clientId']);
      }

      // get / verify date
      fileModel.date = parsedFilename[reportFilenameFormat.date];
      const hasYearMonth = (parsedFilename[reportFilenameFormat.date].match(/\./g) || [])?.length === 1;
      const dateStr = parsedFilename[reportFilenameFormat.date].replace('.', '-');
      const date = Date.parse(dateStr);
      if (!hasYearMonth || isNaN(date)) {
        fileModel.error.push(reportErrorMessages['date']);
      } else {
        // verify date has correct number of digits (year is dateArray[0] and month is dateArray[1])
        const dateArray = dateStr.split('-');
        if (dateArray[0].length !== 4 || dateArray[1].length !== 2) {
          fileModel.error.push(reportErrorMessages['dateYearMonth']);
        }
      }

      // get / verify product
      fileModel.product = parsedFilename[reportFilenameFormat.product].toLowerCase();
      const isKnownProduct = products[fileModel.product]?.length > 0 || false;
      if (!isKnownProduct) {
        fileModel.error.push(reportErrorMessages['product']);
      }

      // get / verify format
      const fileFormat = filename.substring(filename.lastIndexOf('.') + 1).toLowerCase();
      fileModel.format = fileFormat.toUpperCase();
      const isAcceptedFormat = reportAcceptFileFormats.findIndex(i => i.indexOf(fileFormat) >= 0) >= 0;
      if (!isAcceptedFormat) {
        fileModel.error.push(reportErrorMessages['fileType']);
      }

      // verify file size is between 0 and 25MB
      const filesize = file.size;
      if (filesize === 0) {
        fileModel.error.push(reportErrorMessages['fileSize']);
      } else if (file.size > 25000000) {
        fileModel.error.push(reportErrorMessages['fileTooLarge']);
      }

      if (fileModel.error.length > 0) {
        fileModel.uploadStatus = UploadStatus.Error;
      }
    }
    catch (e) {
      fileModel.error.push(reportErrorMessages['fileName']);
      vlogger.error(`Upload: exception: ${JSON.stringify(e)}; filename: ${file.name}`);
      fileModel.uploadStatus = UploadStatus.Error;
    }

    return fileModel;
  }

}
