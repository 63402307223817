import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

export const Logout = () => {
  const { instance } = useMsal();

  // instance.clearCache();
  // instance.logoutRedirect();

  useEffect(() => {
    instance.logoutRedirect();
  }, [instance]);

  // return (<></>);
  return (<div>Logging out...</div>);
};
