/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */

//  User Flow constants
const signinUserFlow = (import.meta.env.VITE_APP_IDENTITY_SIGNINUSERFLOW || '').trim();
const resetPasswordUserFlow = (import.meta.env.VITE_APP_IDENTITY_RESETPASSWORDUSERFLOW || '').trim();
const editProfileUserFlow = (import.meta.env.VITE_APP_IDENTITY_EDITPROFILEUSERFLOW || '').trim();

//  Authority Domain constants
const tenant = (import.meta.env.VITE_APP_IDENTITY_TENANT || 'velocityidentitysolution').trim();
const tenantId = (import.meta.env.VITE_APP_IDENTITY_TENANTID || '').trim();
const authorityDomain = `${tenant}.b2clogin.com`;
const identityDomain = `${tenant}.onmicrosoft.com`;

export const b2cPolicies = {
  names: {
    signUpSignIn: `${signinUserFlow}`,
    resetPassword: `${resetPasswordUserFlow}`,
    editProfile: `${editProfileUserFlow}`,
  },
  authorities: {
    signUpSignIn: { authority: `https://${authorityDomain}/${identityDomain}/${signinUserFlow}` },
    resetPassword: { authority: `https://${authorityDomain}/${identityDomain}/${resetPasswordUserFlow}` },
    forgotPassword: { authority: `https://${authorityDomain}/${identityDomain}/${resetPasswordUserFlow}` },
    editProfile: { authority: `https://${authorityDomain}/${identityDomain}/${editProfileUserFlow}` },
  },
  authorityDomain: `${authorityDomain}`,
  tenantId: `${tenantId}`,
  tenant: `${tenant}`,
  identityDomain: `${identityDomain}`,
};
