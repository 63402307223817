import React, { createContext } from 'react';
import { IRoutePath } from 'shared/interfaces/iroute-path';
import { Home } from 'App/Home';

/* eslint-disable @typescript-eslint/no-empty-function */

/**
 * VipContext interface
 * @interface
 */
export interface IVipContext {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  routes: IRoutePath[];
  flatRoutes: IRoutePath[];
}

/**
 * Default implementation of IVipContext
 */
const defaultVipContext: IVipContext = {
  isLoading: false,
  setIsLoading: (isLoading: boolean) => { },
  routes: [{ path: '/', displayName: 'Home', isDisplayed: true, element: <Home />, title: 'home' }],
  flatRoutes: [{ path: '/', displayName: 'Home', isDisplayed: true, element: <Home />, title: 'home' }],
};

/**
 * VipContext w/ default implementation
 */
export const VipContext = createContext<IVipContext>(defaultVipContext);

/**
 * VipContext Consumer
 */
export const VipContextConsumer = VipContext.Consumer;
