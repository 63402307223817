import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { ApplicationSection, hasAccess, Permission } from 'App/User/user-role';
import { NotAuthorized } from 'shared/components/NotAuthorized';
import { Footer } from './Footer';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { routes } from 'shared/service/router/routes';
import { RouteCategory } from '../shared/service/router/route-control';
import styled from '@emotion/styled';


/**
 * Styling of MainContent
 */
const MainContent = styled('main')`
  display: flex;
  position: relative;
  background-color: var(--page-background);



  //  overflow must be visible to collapsible menu widget to be sticky
  overflow-x: visible;

  & #content-area {
    //  set overflow hidden to keep right edge aligned with page
    overflow-x: hidden;
  }
`;

/**
 * defines the Admin page layout
 * @returns 
 */
export const AdminPageLayout = () => {
  const user = useAppSelector((state) => state.profile.user);
  const hasAdminAccess = hasAccess(user, ApplicationSection.USER_ADMIN, Permission.VIEW) ||
    hasAccess(user, ApplicationSection.CLIENT_ADMIN, Permission.VIEW);

  //  number of layout pages (AuthGuard, AdminPageLayoutPage, AdminPages)
  const routeDepth = -3;

  return (
    <>
      <Header />

      <MainContent>
        {/* has admin access */}
        {hasAdminAccess &&
          <>
            <Sidebar routes={routes} routeDepth={routeDepth} routeCategory={RouteCategory.ADMIN_PAGE} />
            <div id='content-area'>
              <Outlet />
            </div>
          </>
        }

        {!hasAdminAccess &&
          <NotAuthorized />
        }
      </MainContent>

      <Footer />
    </>
  );
};
