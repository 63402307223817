import { routes as preliminary_routes } from './routes';
import { IRoutePath, IDashboardRouteValue } from 'shared/interfaces/iroute-path';
import { RouterParser } from './router-parser';
import { ILogger } from 'shared/service/logger/vlogger';


/**
 * Router Service Interface  - use for DI (or DI like)
 * @interface
 */
export interface IRouterService {
  getRoutes(): Promise<[IRoutePath[], IRoutePath[]]>;
}


/** 
 * Class representing a Router Service object
 * @class
 * @implements IRouterService
 */
export class RouterService implements IRouterService {
  _logger: ILogger;

  constructor(logger: ILogger) {
    this._logger = logger;
  }

  public async getRoutes(): Promise<[IRoutePath[], IRoutePath[]]> {
    const preliminaryRoutes = preliminary_routes;

    let dashboardRouteValues: IDashboardRouteValue[];
    const loc = import.meta.env.VITE_APP_LOOKER_API3_BASE_URL?.toLowerCase() || '';

    // staging
    if (loc.indexOf('staging') >= 0) {
      dashboardRouteValues = [
        { id: '1', model: 'ILS_operations_management', title: 'ILS Operations Management' },
        { id: '19', onPremId: '33', model: 'ILS_executive_management', title: 'ILS Executive Management' },
        { id: '20', onPremId: '35', model: 'ILS_operations', title: 'ILS Operations' },
        { id: '38', model: 'ILS_executive_management', title: 'ILS NSF/OD Re-Presentments' },
        { id: '22', model: 'fed_client', title: 'ILS Peer Comparisons' },
        { id: '9', model: 'ars_dashboard', title: 'ARS Usage Summary' },
        { id: '11', model: 'ars_accounts_dashboard', title: 'ARS Accounts' },
        { id: '10', model: 'ars_debitcard_dashboard', title: 'ARS Debit Card' },
        { id: '12', model: 'ars_reg_e_dashboard', title: 'ARS Reg E' },
        { id: '30', model: 'ars_roi_dashboard', title: 'ARS Executive Summary' },
        { id: '34', model: 'ars_roi_dashboard', title: 'ARS Revenue Performance' },
        { id: '32', model: 'ars_roi_dashboard', title: 'ARS Actuals' },
        { id: '29', model: 'ars_roi_dashboard', title: 'ARS All Months Rolled Up' },
        { id: '31', model: 'ars_roi_dashboard', title: 'ARS DCO' },
        { id: '13', model: 'mrpc_group_revenue_to_FI_dashboard', title: 'MRPC Revenue to FI' },
        { id: '14', model: 'mrpc_enrollments_dashboard', title: 'MRPC Enrollments' },
        { id: '15', model: 'mrpc_performance_dashboard', title: 'MRPC Performance' },
        { id: '16', model: 'mrpc_retention_dashboard', title: 'MRPC Retention' },
        { id: '18', model: 'cashplease_loan_summary_and_payoff', title: 'CP Loan Summary and Payoff' },
        { id: '37', model: 'ics', title: 'ICS Referral Data' }

      ];
    } else {
      // production
      dashboardRouteValues = [
        { id: '39', model: 'ILS_operations_management', title: 'ILS Operations Management' },
        { id: '31', onPremId: '53', model: 'ILS_executive_management', title: 'ILS Executive Management' },
        { id: '32', onPremId: '54', model: 'ILS_operations', title: 'ILS Operations' },
        { id: '60', model: 'ILS_executive_management', title: 'ILS NSF/OD Re-Presentments' },
        { id: '40', model: 'fed_client', title: 'ILS Peer Comparisons' },
        { id: '18', model: 'ars_dashboard', title: 'ARS Usage Summary' },
        { id: '24', model: 'ars_accounts_dashboard', title: 'ARS Accounts' },
        { id: '37', model: 'ars_debitcard_dashboard', title: 'ARS Debit Card' },
        { id: '25', model: 'ars_reg_e_dashboard', title: 'ARS Reg E' },
        { id: '42', model: 'ars_roi_dashboard', title: 'ARS Executive Summary' },
        { id: '61', model: 'ars_roi_dashboard', title: 'ARS Revenue Performance' },
        { id: '43', model: 'ars_roi_dashboard', title: 'ARS Actuals' },
        { id: '58', model: 'ars_roi_dashboard', title: 'ARS All Months Rolled Up' },
        { id: '41', model: 'ars_roi_dashboard', title: 'ARS DCO' },
        { id: '26', model: 'mrpc_group_revenue_to_FI_dashboard', title: 'MRPC Revenue to FI' },
        { id: '27', model: 'mrpc_enrollments_dashboard', title: 'MRPC Enrollments' },
        { id: '28', model: 'mrpc_performance_dashboard', title: 'MRPC Performance' },
        { id: '29', model: 'mrpc_retention_dashboard', title: 'MRPC Retention' },
        { id: '30', model: 'cashplease_loan_summary_and_payoff', title: 'CP Loan Summary and Payoff' },
        { id: '59', model: 'ics', title: 'ICS Referral Data' }
      ];
    }

    // create populated routes from prelim_routes and drv
    const [routes, flatRoutes]: [IRoutePath[], IRoutePath[]] =
      new RouterParser(this._logger).parse(preliminaryRoutes, dashboardRouteValues);
    this._logger.verbose(`dashboard route values: ${JSON.stringify(dashboardRouteValues)}`);
    this._logger.verbose(`routes (post parse): ${JSON.stringify(routes)}`);
    this._logger.verbose(`flatRoutes: ${JSON.stringify(flatRoutes)}`);
    return [routes, flatRoutes];
  }
}
