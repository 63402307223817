import { useRoutes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import { VipProvider } from 'shared/context/VipProvider';
import { useVipContext } from 'shared/hooks/useVipContext';
import { routes } from 'shared/service/router/routes';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'shared/components/ErrorFallback';
import { StyledEngineProvider } from '@mui/material';
import { Loading } from 'shared/components/Loading';


/**
 * App component
 * @remarks QueryClientProvider - react query api interface provider
 * @remarks Provider - redux global state provider
 * @remarks VipProvider - used for route context
 * @remarks ErrorBoundary - react error boundary
 * @remarks RouterProvider - react router v6.4+
 */
export default function App() {
  const element = useRoutes(routes);
  const { isLoading } = useVipContext();

  return (
    <Provider store={store}>
      <VipProvider>
        <StyledEngineProvider injectFirst>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Loading isLoading={isLoading} />
            {element}
          </ErrorBoundary>
        </StyledEngineProvider>
      </VipProvider>
    </Provider>
  );
}

App.displayName = 'App';
