import React from 'react';
import { GridOverlay, NoRowsOverlayPropsOverrides } from '@mui/x-data-grid';
import styled from '@emotion/styled';

/**
 * Props passed
 */
// interface INothingAvailableOverlayProps extends NoRowsOverlayPropsOverrides {
//   text: string;
// }

declare module '@mui/x-data-grid' {
  interface NoRowsOverlayPropsOverrides {
    text: string;
  }
}

/**
 * Styling
 */
const NoResultsDiv = styled.div`
  display: flex;

  & p {
    display: flex;
    justify-content: center;
  }
`;

/**
 *
 * @param { INothingAvailableOverlayProps } props
 * @param { NoRowsOverlayPropsOverrides } props   this is a kludge to get typescript to accept the overlay prop
 * @returns
 */
export const NothingAvailableOverlay = (props: NoRowsOverlayPropsOverrides) => {
  return (
    <GridOverlay>
      <NoResultsDiv>
        <p>No {props.text ?? ''} available at this time, please check back later.</p>
      </NoResultsDiv>
    </GridOverlay>
  );
};
