import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import App from './App/App';
import { routes } from 'shared/service/router/routes';
import { IRoutePath } from 'shared/interfaces/iroute-path';
import reportWebVitals from 'shared/report-web-vitals';
import 'styles/stylesheet.scss';


/**
 * Primary render method (entry point)
 */
// const router = createBrowserRouter(routes as RouteObject[]);
const router = createBrowserRouter(routes as IRoutePath[]);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    {/* <RouterProvider router={router} /> */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
reportWebVitals(console.log);
