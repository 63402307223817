/* eslint-disable react/no-multi-comp */
import React from 'react';
import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';

/**
 * Sorted Decending Icon
 * @function
 */
export const SortedDescendingIcon = () => {
  return <ExpandMoreOutlined className='icon' />;
};

/**
 * Sorted Ascending Icon
 * @function
 */
export const SortedAscendingIcon = () => {
  return <ExpandLessOutlined className='icon' />;
};
