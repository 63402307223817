import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import { Waiting } from 'shared/components/Waiting';
import styled from '@emotion/styled';


const MainContainer = styled(Container)`
  margin: 2rem;
`;

/**
 * Redirect to SignUp page
 * @returns
 */
export const SignUp = () => {
  const tenant = (import.meta.env.VITE_APP_IDENTITY_TENANT || 'velocityidentitysolution').trim();
  const authorityDomain = `${tenant}.b2clogin.com`;
  const identityDomain = `${tenant}.onmicrosoft.com`;
  const signupUserFlow = (import.meta.env.VITE_APP_IDENTITY_SIGNUPUSERFLOW || '').trim();
  const clientId = (import.meta.env.VITE_APP_IDENTITY_CLIENTID || '').trim();
  const redirectUrl = (import.meta.env.VITE_APP_IDENTITY_REDIRECT_URI || '').trim();

  const signup = `https://${authorityDomain}/${identityDomain}/oauth2/v2.0/authorize?` +
    `p=${signupUserFlow}&` +
    `clientId=${clientId}&` +
    'nonce=defaultNonce&' +
    `redirect_uri=${redirectUrl}&` +
    'scope=openid&response_type=id_token&option=signup';

  useEffect(() => {
    window.location.href = signup;
  }, []);

  return (
    <>
      <MainContainer>
        <Waiting delay={1000} />
      </MainContainer>
    </>
  );
};
