import React, { MouseEvent } from 'react';
import { UserRole, UserRoleDisplay } from 'App/User/user-role';
import { VelocityUser } from 'shared/types/velocityUser';
import { DataGrid, GridColDef, GridCellParams, GridRowModel } from '@mui/x-data-grid';
import { CheckCircle } from '@mui/icons-material';
import { SortedDescendingIcon, SortedAscendingIcon } from 'shared/components/Datagrid/SortedIcon';
import { UserEditMenu } from './UserEditMenu';
import styled from '@emotion/styled';
import { LinearProgress } from '@mui/material';


/**
 * Styling
 */
const DataGridDiv = styled.div`
  & .atom-radio {
    color: var(--color-success);
    width: 2rem;
    height: 2rem;
  
  & .center-cell {
      display: flex;
      align-items: center;
    }
  }
`;

/**
 * Props passed 
 */
interface IUserDataTableProps {
  users: VelocityUser[];  //  list of users to display
  onEditClick: (event: MouseEvent<HTMLDivElement>, rowData: GridRowModel) => void;
}


/**
 * User data grid component
 * @param {IUserDataTableProps} props 
 * @returns 
 */
export const UserDataTable = (props: IUserDataTableProps) => {
  const { users, onEditClick } = props;
  const rowsPerPage: number[] = [5, 10, 20];
  const [paginationModel, setPaginationModel] = React.useState({ pageSize: 5, page: 0 });

  //  DataGrid Column definition
  const columns: GridColDef[] = [
    { field: 'colName', headerName: 'Name', flex: 2, minWidth: 180, headerAlign: 'left' },
    { field: 'colEmail', headerName: 'Email', flex: 2, minWidth: 200, headerAlign: 'left' },
    { field: 'colClient', headerName: 'Client', flex: 2, minWidth: 180, headerAlign: 'left' },
    {
      field: 'colRole', headerName: 'Role', flex: 2, minWidth: 100, headerAlign: 'left',
      renderCell: (params: GridCellParams) => (
        <label>{UserRoleDisplay[params.value as UserRole]}</label>
      )
    },
    {
      field: 'colActive', headerName: 'Active', flex: 1, minWidth: 110, type: 'boolean', headerAlign: 'center',
      renderCell: (params: GridCellParams) => (
        (params.value)
          ? <CheckCircle className="atom-radio center-cell" />
          : <>{null}</>
      )
    },
    {
      field: 'colEdit', headerName: ' ', width: 20, align: 'center', hideSortIcons: true,
      renderCell: (params: GridCellParams) => (
        <div className='edit-user' onClick={(e) => onEditClick(e, params.row)}>
          <UserEditMenu aria-label="user edit menu" />
        </div>
      )
    }
  ];

  let index = 1;
  const rows = users.map(u => {
    return {
      id: index++,
      colName: u.displayName,
      colEmail: u.email,
      colClient: u.companyName,
      colRole: u.userRole,
      colActive: u.isDisabled === false,
      colEdit: ' '
    };
  });

  return (
    <DataGridDiv id='user-table'>
      <DataGrid rows={rows} columns={columns}
        pagination
        pageSizeOptions={rowsPerPage}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        autoHeight
        disableColumnMenu
        disableRowSelectionOnClick
        // loading
        slots={{
          loadingOverlay: LinearProgress,
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
        }}
      // components={{ Pagination: UserTablePagination, }}
      // componentsProps={{
      //   pagination: { background: 'red', counter: rows.length },
      // }}
      />
    </DataGridDiv>
  );
};
