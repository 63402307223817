import React, { useEffect, useState } from 'react';
import { AuthError, BrowserAuthError, InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { vlogger } from 'shared/service/logger/vlogger';
import { LOGINREQUEST } from 'App/auth-configuration';


/**
 * Props
 */
interface IErrorComponentsProps {
  error: AuthError | null;
}

/**
 * Authentication (MSAL) Error Component
 * @param {IErrorComponents} props
 * @returns 
 * remark:  This component is not an error boundary but an error component from a failed login
 */
export const ErrorComponent = (props: IErrorComponentsProps) => {
  const { error } = props;
  const { instance, inProgress } = useMsal();
  const [open, setOpen] = useState(false);

  const handleClose = async () => {
    setOpen(false);
    await instance.loginRedirect(LOGINREQUEST);
  };

  useEffect(() => {
    vlogger.debug(`MSAL interaction status:  ${inProgress}`);
    vlogger.debug(`InteractionRequiredAuthError: ${error instanceof InteractionRequiredAuthError}`);
    vlogger.debug(`BrowserAuthError: ${error instanceof BrowserAuthError}`);

    if (error) {
      if (!(error instanceof InteractionRequiredAuthError))
        setOpen(true);

      vlogger.error('A login error occurred:\n' +
        `corrId: ${error?.correlationId}  code: ${error?.errorCode}:\n` +
        `msg: ${error?.name} ${error?.errorMessage}\n${error?.subError}}`);
      vlogger.debug(`stack: ${error?.stack}`);
    }
  }, [error]);

  return (
    <div id="dialog">
      <Dialog open={open} onClose={handleClose}
        slotProps={{ backdrop: { style: { backgroundColor: 'transparent' } } }}
        aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Login Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <>
              User is not active
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
