import { useEffect, useState, MouseEvent } from 'react';
import { IVipCompany, defaultUser } from 'redux/profileSlice';
import { Button, Divider, List, ListItem, ListItemText, Paper } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import styled from '@emotion/styled';


/**
 * Styling
 */
const MultiSelectDiv = styled.div`
  display:flex;

  & .container {
    padding: 0rem;
    width: 300px;
    // margin-bottom: 2.5rem;
  }

  & #left-box {
    margin-right: 0.5rem;
  }

  & #right-box {
    margin-left: 0.5rem;
  }

  & .paper {
    width: 100%;
    text-align: center;
    border: solid 2px var(--outline);
    border-radius: 0px;
    // margin-bottom: -2.5rem;
    height: 18.25rem;
  }

  & .list {
    max-height: 290px;
    width: 100%;
    overflow: auto;
    padding: 0rem;
  }

  & .list-selected {
    background-color: var(--color-secondary-light)
  }

  & .button-select {
    height: 2.875rem;
    margin-top: 1.25rem;
  }

  & .add-select {
    width: 6.125rem;
  }

  & .remove-select {
    // width: 6.125rem;
  }
`;

/**
 * Props passed 
 */
interface IClientMultiSelectProps {
  companies: IVipCompany[];
  selected: IVipCompany[];
  onChangeSelected: (selected: IVipCompany[]) => void;
}

/**
 * Client Multi Select 
 * @param props 
 * @returns 
 */
export const ClientMultiSelect = (props: IClientMultiSelectProps) => {
  const { companies, selected, onChangeSelected } = props;

  const [availableClients, setAvailableClients] = useState<IVipCompany[]>([]);
  const [assignedClients, setAssignedClients] = useState<IVipCompany[]>([]);
  const [availableClientSelect, setAvailableClientSelect] = useState<IVipCompany[]>([]);
  const [assignedClientSelect, setAssignedClientSelect] = useState<IVipCompany[]>([]);

  useEffect(() => {
    setAssignedClients(selected.sort((a, b) => a.name.localeCompare(b.name)));

    const selectedClientIds = selected.map(s => s.clientId);
    const clients = companies.filter(c => !selectedClientIds.includes(c.clientId));
    setAvailableClients(clients.sort((a, b) => a.name.localeCompare(b.name)));
  }, [selected]);


  //  move left from available clients to assigned clients column
  const handleMoveToAssignedClients = () => {
    //  add selected available clients to assigned client list
    const so = [...assignedClients, ...availableClientSelect];
    const sos = so.sort((a, b) => a.name.localeCompare(b.name));
    setAssignedClients(sos);
    onChangeSelected(sos);

    //  remove selected available clients from available client list
    const ids = availableClientSelect.map(c => c.clientId);
    const ac = availableClients.filter(c => !ids.includes(c.clientId));
    setAvailableClients(ac);
    setAvailableClientSelect([]);
  };

  //  move right from assigned clients to available options clients
  const handleMoveToAvailableClients = () => {
    //  remove selected assigned clients from available client list
    const ids = assignedClientSelect.map(c => c.clientId);
    const so = assignedClients.filter(c => !ids.includes(c.clientId));
    setAssignedClients(so);
    onChangeSelected(so);

    //  add selected available clients to assigned client list
    const ac = [...availableClients, ...assignedClientSelect];
    const acs = ac.sort((a, b) => a.name.localeCompare(b.name));
    setAvailableClients(acs);
    setAssignedClientSelect([]);
  };

  const handleSelectAvailableClient = (event: MouseEvent<HTMLDivElement>, option: IVipCompany) => {
    const inArray = availableClientSelect.includes(option);

    if (!inArray) {
      //  insert option into selection array
      setAvailableClientSelect([...availableClientSelect, option]);
      (event.currentTarget as HTMLDivElement).className = 'list-selected';
    } else {
      //  remove option from selection array
      const arr = availableClientSelect.filter(acs => acs.clientId !== option.clientId);
      setAvailableClientSelect(arr);
      (event.currentTarget as HTMLDivElement).className = '';
    }
  };

  const handleSelectAssignedClient = (event: MouseEvent<HTMLDivElement>, option: IVipCompany) => {
    if (option.clientId === defaultUser.clientId)
      return;

    const inArray = assignedClientSelect.includes(option);
    if (!inArray) {
      //  insert option into selection array
      setAssignedClientSelect([...assignedClientSelect, option]);
      (event.currentTarget as HTMLDivElement).className = 'list-selected';
    } else {
      //  remove option from selection array
      const arr = assignedClientSelect.filter(acs => acs.clientId !== option.clientId);
      setAssignedClientSelect(arr);
      (event.currentTarget as HTMLDivElement).className = '';
    }
  };

  return (
    <MultiSelectDiv>
      <div id='left-box' className='container'>
        <label>Clients</label>
        <Paper variant='outlined' className='paper'>
          <List dense className='list'>
            {availableClients.map((option) => (
              <div key={`ac-${option.clientId}`}
                onClick={(event) => handleSelectAvailableClient(event, option)}
              >
                <ListItem>
                  <ListItemText primary={`(${option.clientId})${option.name}`} />
                </ListItem>
                <Divider />
              </div>
            ))}
          </List>
        </Paper>

        <div className='add-select'>
          <Button variant="outlined" className='button-select' onClick={handleMoveToAssignedClients} >
            <label>Add</label>
            <ArrowForward />
          </Button>
        </div>
      </div>

      <div id='right-box' className='container'>
        <label>Assigned Clients</label>
        <Paper variant='outlined' className='paper' >
          <List dense className='list'>
            {assignedClients.map((option) => (
              <div key={`so-${option.clientId}`}
                onClick={(event) => handleSelectAssignedClient(event, option)}
              >
                <ListItem >
                  <ListItemText primary={`(${option.clientId})${option.name}`} />
                </ListItem>
                <Divider />
              </div>
            ))}
          </List>
        </Paper>

        <div className='remove-select'>
          <Button variant="outlined" className='button-select' onClick={handleMoveToAvailableClients} >
            <ArrowBack />
            <label>Remove</label>
          </Button>
        </div>
      </div>
    </MultiSelectDiv>
  );
};
