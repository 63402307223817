import { useEffect, useRef } from 'react';
import { vlogger } from 'shared/service/logger/vlogger';


/**
 * setTimeout hook
 * @param {() => void} callback  typically callback function
 * @param {number} delay   delay in ms
 * @param {string[]} events  optional array of events to listen for
 * @example useTimeout(() => { console.log('timeout') }, 1000, ['mousemove', 'touchmove'])
 */
export function useTimeout(callback: () => void, delay: number | null, events?: string[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const savedCallback = useRef<any>();
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Reset timer function
  const resetTimeout = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    if (delay !== null) {
      timeoutId.current = setTimeout(() => {
        savedCallback.current();
      }, delay);
    } else {
      vlogger.debug('useTimeout: delay is null');
    }
  };

  // Set up the timeout timer.
  useEffect(() => {
    resetTimeout();

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [delay]);

  // Reset the timeout whenever any of the specified events are fired.
  useEffect(() => {
    if (events && events.length > 0) {
      const reset = () => resetTimeout();

      events.forEach((event) => {
        window.addEventListener(event, reset);
      });

      return () => {
        events.forEach((event) => {
          window.removeEventListener(event, reset);
        });
      };
    }

    return () => void 0;
  }, [events, resetTimeout]);
}
