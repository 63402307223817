import React, { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { fetchUsers, addUpdateUser } from 'redux/appSlice';
import { IVipUser, IVipCompany } from 'redux/profileSlice';
import { useMsal } from '@azure/msal-react';
import { VelocityUser } from 'shared/types/velocityUser';
import { GridRowModel } from '@mui/x-data-grid';
import { Alert, Button, Checkbox, Snackbar, FormControlLabel } from '@mui/material';
import { NotAuthorized } from 'shared/components/NotAuthorized';
import { UserDataTable } from './UserDataTable';
import { AddEditUser } from './AddEditUser';
import { ISendRegistrationRequest, RegistrationService } from 'App/ResendRegistration/registration-service';
import { SearchBar } from 'shared/components/SearchBar';
import { ModalYesNo } from 'shared/components/Modal/ModalYesNo';
import { EmailHelper } from 'shared/utils/email-helper';
import { UserSettingsService } from './user-settings-service';
import { ApplicationSection, canUserFilterByAdmin, hasAccess, Permission, UserRole } from 'App/User/user-role';
import styled from '@emotion/styled';
import { EditMenuOptions } from './user-settings-constants';
import { vlogger } from 'shared/service/logger/vlogger';
import { UserProfileService } from 'shared/service/profile/user-profile';


/**
 * Styling
 */
const AdminContent = styled.div`
  margin: 0px 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  overflow: hidden;
`;

const AdminContentHeader = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const UserButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  white-space: nowrap;

  & #add-users {
    margin-right: 1.5rem;
  };

  & #all-users {
    margin-left: 0.625rem;
    margin-right: -2px;
  };

  & #admin-users {
    // margin-right: 1.5rem;
  };
`;

const ResendRegistrationDiv = styled.div`
  height: 0px;
`;

/**
 * UserSettings page
 * @returns 
 */
export const UserSettings = () => {
  const { instance } = useMsal();
  const dispatch = useAppDispatch();

  const user: IVipUser = useAppSelector((state) => state.profile.user);
  const users: IVipUser[] = useAppSelector((state) => state.app.users);
  const companies: IVipCompany[] = useAppSelector((state) => state.app.companies);

  const [isUsersSelected, setIsUsersSelected] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>('');
  const [savedUsers, setSavedUsers] = useState<VelocityUser[]>([]);

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const hasAdminFilter = canUserFilterByAdmin(user.userRole);


  // Verify user has authorization
  const hasUserSettingsAuthorization = hasAccess(user, ApplicationSection.USER_ADMIN, Permission.VIEW);
  if (!hasUserSettingsAuthorization)
    return (<NotAuthorized />);

  //  Implement user-settings
  let registeredUsers: VelocityUser[] = [];
  useEffect(() => {
    registeredUsers = users.map(u => {
      return {
        ...u,
        companyName: companies.find(c => c.clientId === u.clientId)?.name ?? '',
        isVelocityUser: EmailHelper.isVelocityEmail(u.email),
      };
    });

    setSavedUsers(registeredUsers);
  }, [users, companies]);

  const [rows, setRows] = useState<VelocityUser[]>(registeredUsers);


  //  Search filtering
  useEffect(() => {
    const filteredUsers = (isUsersSelected)
      ? savedUsers
      : savedUsers.filter(f => f.userRole === UserRole.ADMINISTRATOR);

    const lowerSearchText = searchText?.toLowerCase() ?? '';
    const filteredSearchUsers = (!searchText)
      ? filteredUsers
      : filteredUsers.filter(f => {
        return (
          f.displayName?.toLowerCase().includes(lowerSearchText) ||
          f.companyName.toLowerCase().includes(lowerSearchText) ||
          f.email.toLowerCase().includes(lowerSearchText)
        );
      });

    setRows(filteredSearchUsers.sort((a, b) => ((a.userId ?? 0) - (b.userId ?? 0))));
  }, [isUsersSelected, searchText, savedUsers]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  //  Add/Edit Users
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [editActionType, setEditActionType] = useState<string>('');
  const [editUser, setEditUser] = useState<VelocityUser | null>(null);

  const onDrawerCloseClick = () => {
    setIsDrawerOpen(false);
  };

  const handleOnClickAddButton = () => {
    setEditUser(null);
    setEditActionType('Add');
    setIsDrawerOpen(true);
  };

  const [responseState, setResponseState] = useState('');
  const returnResponseInput = useRef<HTMLInputElement>(null);

  /**
   * send reset password email and assign a temporary password
   * @param {VelocityUser} user 
   */
  const resetUserPassword = async (user: VelocityUser) => {
    const returnResponse = returnResponseInput.current;
    const includeComponents = returnResponse?.checked ?? false;

    const result =
      await new UserProfileService(instance, vlogger).resetUserPassword({ ...user }, includeComponents);

    setResponseState(JSON.stringify(result));
    vlogger.informational(`Sent reset password email to ${user.email}`);
  };

  /**
   * send registration email and assign a temporary password
   * @param {VelocityUser} user 
   */
  const registerNewUser = async (user: VelocityUser) => {
    const returnResponse = returnResponseInput.current;
    const includeComponents = returnResponse?.checked ?? false;

    const result =
      await new UserProfileService(instance, vlogger).registerUserAccount({ ...user }, includeComponents);

    setResponseState(JSON.stringify(result));
    vlogger.informational(`Sent registration email to ${user.email}`);
  };

  //  Left side kebab menu
  const handleOnClickEditButton = async (event: MouseEvent<HTMLDivElement>, rowData: GridRowModel) => {
    const selectedUser = rows.find(u => u.email === rowData.colEmail) ?? null;
    setEditUser(selectedUser);
    if (!selectedUser) return;

    const userMenuType = (event.target as HTMLDivElement).dataset.useredit;

    if (userMenuType === EditMenuOptions.Edit) {
      vlogger.debug(`edit user: ${selectedUser.firstName} ${selectedUser.lastName}`);
      //  open 'edit' drawer
      setEditActionType('Edit');
      setIsDrawerOpen(true);
    } else if (userMenuType === EditMenuOptions.Delete) {
      vlogger.debug(`delete user: ${selectedUser.firstName} ${selectedUser.lastName}`);
      //  modal popup for confirmation
      setDeleteConfirmOpen(true);
    } else if (userMenuType === EditMenuOptions.ResetPassword) {
      vlogger.debug(`reset user password: ${selectedUser.firstName} ${selectedUser.lastName}`);
      await resetUserPassword(selectedUser);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // file delete confirmation response
  const handleOnCancelDelete = () => {
    setDeleteConfirmOpen(false);
  };

  // determine what roles hard delete vs soft delete
  const canHardDelete = (user: IVipUser) => {
    const ha = hasAccess(user, ApplicationSection.USER_ADMIN, Permission.DELETE);

    const role = user.userRole;
    const ur = (role === UserRole.ADMINISTRATOR || role === UserRole.DEVELOPER);

    return ha && ur;
  };

  const handleOnDeleteUser = async () => {
    if (editUser) {
      let completed = false;
      if (canHardDelete(user)) {
        completed = await new UserSettingsService().deleteUser(instance, editUser);
        await dispatch(fetchUsers({ pca: instance, userId: user.userId }));
      } else {
        const formUser = { ...editUser, isDeleted: true };
        const response =
          await dispatch(addUpdateUser({ pca: instance, formUser: formUser, user: user }));
        completed = response.payload !== null;
      }
      setSnackbarOpen(!completed);

      vlogger.informational(`Deleted user ${editUser.email}`);
    }

    setDeleteConfirmOpen(false);
  };

  //  check access to UserAdmin section
  const isUserAdminAdd = hasAccess(user, ApplicationSection.USER_ADMIN, Permission.ADD);

  return (
    <AdminContent id='admin-content'>
      <AddEditUser key={`${editActionType}${isDrawerOpen}`} editUser={editUser}
        actionType={editActionType} isOpen={isDrawerOpen} onCloseClick={onDrawerCloseClick}
        registerNewUser={registerNewUser}
      />

      <AdminContentHeader id='content-header'>
        <SearchBar id='search-bar' searchQuery={searchText} setSearchQuery={(e) => handleSearchChange(e)} />

        <UserButtons>
          {isUserAdminAdd &&
            <Button id='add-users' variant="outlined" onClick={handleOnClickAddButton} >
              Add User
            </Button>
          }

          {hasAdminFilter &&
            <Button id='all-users'
              variant={isUsersSelected ? 'contained' : 'outlined'}
              onClick={() => setIsUsersSelected(true)}
            >
              All Users
            </Button>
          }
          {hasAdminFilter &&
            <Button id='admin-users'
              variant={isUsersSelected ? 'outlined' : 'contained'} onClick={() => setIsUsersSelected(false)}
            >
              Admin Users
            </Button>
          }
        </UserButtons>
      </AdminContentHeader>

      <div id='content-body' className={'width-100'}>
        <UserDataTable key={'UserDataTable'} users={rows} onEditClick={handleOnClickEditButton} />
      </div>


      {/* delete confirmation modal */}
      <ModalYesNo id="modal-delete"
        open={deleteConfirmOpen} className={'yes-no-modal'} disableBackdropClick={true}
        title={'Confirm Delete'}
        text={`Are you sure you want to delete <br/> ${editUser?.email} <br/> account?`}
        onYesClick={handleOnDeleteUser}
        onNoClick={handleOnCancelDelete}
      />

      {/* upload success or failure */}
      <Snackbar id={'snackbar-result'} open={snackbarOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert severity='error'>The user selected encountered an error, please refresh the page and try again.</Alert>
      </Snackbar>

      <ResendRegistrationDiv className='hidden' id="return-response">
        <FormControlLabel id='response-checkbox' control={<Checkbox inputRef={returnResponseInput} />}
          label="Return Response" labelPlacement='start'
        />
        <label id='response-label'>{responseState}</label>
      </ResendRegistrationDiv>
    </AdminContent>
  );
};

UserSettings.displayName = 'UserSettings';

