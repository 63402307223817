import * as forge from 'node-forge';

/**
 * Static Guid Helper class
 * @class
 */
export class HashHelper {
  /**
   * Create new Hmac
   * @param input
   * @param secret
   * @returns sha1 hmac string
   * @description  Used to return a signature (sha1 hash) of an input string
   */
  static CreateHmac(input: string, secret = 'secret'): string {
    const hmac = forge.hmac.create();
    hmac.start('sha1', secret);
    hmac.update(this.forceUnicodeEncoding(input));

    return forge.util.encode64(hmac.digest().getBytes()).trim();
  }

  /**
   * Create similar to Java/C# hashcode
   * @param input
   * @returns Hexadecimal hashcode string
   * @description Quick hashcode generator
   */
  static CreateHashCode(input: string): string {
    let hash = 0;

    if (input.length === 0) return hash.toString(16);

    for (let i = 0; i < input.length; i++) {
      const chr = input.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash).toString(16);
  }

  /**
   * Verify that the string is unicode encoded
   * @param string imput
   * @returns unicode input
   */
  private static forceUnicodeEncoding(string: string) {
    return decodeURIComponent(encodeURIComponent(string));
  }
}
