import { IPublicClientApplication } from '@azure/msal-browser';
import { HttpService, IHeaders } from 'shared/service/http/http-service';
import { VelocityErrorHelper } from 'shared/utils/velocity-error-helper';
import { getAccessToken } from 'shared/service/http/token-retriever';
import { GuidHelper } from 'shared/utils/guid-helper';
import { vlogger } from 'shared/service/logger/vlogger';
import { VelocityUser } from 'shared/types/velocityUser';
import { IVipCompany } from 'redux/profileSlice';
import { JwtHelper } from 'shared/utils/jwt-helper';

/**
 * UserClient request interface
 */
export interface IUserClientRequest {
  clientId?: number;
  userId?: number;
  isEnabled?: boolean;
  orderBy?: boolean;
  returnEntity?: boolean;
}

/**
 * Delete user request interface
 * @interface
 */
export interface IDeleteUserRequest {
  email: string;
}

/**
 * User Settings Service Interface  - use for DI (or DI like)
 * @interface
 */
export interface IUserSettingsService {
  deleteUser(pca: IPublicClientApplication, user: VelocityUser): Promise<boolean>;
  getClientsByUserId(pca: IPublicClientApplication, user: VelocityUser, orderBy: boolean): Promise<IVipCompany[] | null>;
  addUserClient(pca: IPublicClientApplication, req: IUserClientRequest): Promise<boolean>;
}


/** 
 * Class representing the UserSettings Service http interface object
 * @class
 * @implements IUserSettingsService
 */
export class UserSettingsService implements IUserSettingsService {

  /**
   * Delete user from both Azure AD B2C and VIP ACL db
   * @param {IPublicClientApplication} pca authentication service
   * @param {VelocityUser} user
   * @returns true = deleted
   */
  async deleteUser(pca: IPublicClientApplication, user: VelocityUser): Promise<boolean> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getAccessToken(pca)}`
    };
    const httpService = new HttpService(vlogger, baseUrl, headers);
    const request: IDeleteUserRequest = { email: user.email };

    //  HTTP Post to Identity (AD B2C)
    const resultIdentity =
      await httpService.post<IDeleteUserRequest, void>('api/v1/identity/user/deleteuseraccount', request, headers, uuid);

    if (resultIdentity === null) return false;
    if (resultIdentity.errors.length !== 0) {
      VelocityErrorHelper.Logify(resultIdentity.errors, resultIdentity.operationId, false, false);
      return false;
    }

    //  HTTP Post to VIP (ACL)
    const resultVip =
      await httpService.post<IDeleteUserRequest, void>('api/v1/user/deleteuseraccount', request, headers, uuid);

    if (resultVip === null) return false;
    if (resultVip.errors.length !== 0) {
      VelocityErrorHelper.Logify(resultVip.errors, resultVip.operationId, false, false);
    }

    return ((resultIdentity.errors.length === 0) && (resultVip.errors.length === 0));
  }


  /**
   * Get client list for a given userId
   * @param {IPublicClientApplication} pca authentication service
   * @param {VelocityUser} user
   * @returns {IVipCompany[]} list of clients for a given userId
   */
  async getClientsByUserId(pca: IPublicClientApplication, user: VelocityUser, orderBy = false): Promise<IVipCompany[] | null> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const accessToken = await getAccessToken(pca);
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      // 'X-CID': `${JwtHelper.GetClientId(accessToken)}`
    };
    const httpService = new HttpService(vlogger, baseUrl, headers);
    const request: IUserClientRequest = { userId: user.userId, orderBy: orderBy };

    //  HTTP Get for Client FeatureFlag
    const result =
      await httpService.get<IVipCompany[]>('api/v1/userclient/getClientsByUserId', request, headers, uuid);

    if (result === null) return null;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }

    return result.result;
  }


  /**
   * Add an user / client relationship
   * @param {IPublicClientApplication} pca authentication service
   * @param {IUserClientRequest} req
   * @returns {boolean} success
   */
  async addUserClient(pca: IPublicClientApplication, req: IUserClientRequest): Promise<boolean> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const accessToken = await getAccessToken(pca);
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      // 'X-CID': `${JwtHelper.GetClientId(accessToken)}`
    };
    const httpService = new HttpService(vlogger, baseUrl, headers);
    const request: IUserClientRequest = { ...req, returnEntity: true };

    //  HTTP Get for Client FeatureFlag
    const resultVip =
      await httpService.post<IUserClientRequest, IUserClientRequest | null>('api/v1/userclient/addUserClient', request, headers, uuid);

    if (resultVip === null) return false;
    if (resultVip.errors.length !== 0) {
      VelocityErrorHelper.Logify(resultVip.errors, resultVip.operationId, false, false);
      return false;
    }

    return true;
  }
}
