import React, { MouseEvent } from 'react';
import { format } from 'date-fns-tz';
import { DataGrid, GridColDef, GridCellParams, GridRenderCellParams, GridRowModel, GridValidRowModel } from '@mui/x-data-grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { StyledTextField } from 'shared/components/Textbox/StyleTextField';
import { DateInput } from 'shared/components/DateInput';
import { SortedDescendingIcon, SortedAscendingIcon } from 'shared/components/Datagrid/SortedIcon';
import { NothingAvailableOverlay } from 'shared/components/Datagrid/NothingAvailableOverlay';
import { BranchGoalDataTableFooter } from './BranchGoalDataTableFooter';
import styled from '@emotion/styled';


/**
 * Styling
 */
const DataGridDiv = styled.div`
  & .header-branch-number .MuiDataGrid-columnHeaderTitle {
    overflow-x: auto;
    white-space: pre-wrap;
    line-height: 1.25rem;
  }

  & .header-goal-number .MuiDataGrid-columnHeaderTitle {
    overflow-x: auto;
    white-space: pre-wrap;
    line-height: 1.25rem;
  }

  & .header-last-modified .MuiDataGrid-columnHeaderTitle {
    overflow-x: auto;
    white-space: pre-wrap;
    line-height: 1.25rem;
  }

  & .cell-goal .MuiTextField-root {
    margin-bottom: 0px;

    &  .MuiInputBase-root {
      height: 45px;
      border: solid 2px var(--outline);
    }
  }

  & .cell-effective-date .MuiFormControl-root {
    margin-bottom: inherit;
    height: 45px;
    border: solid 2px var(--outline);
  }

  & .button-box {
    margin: 1.5625rem 0rem;
    justify-content: space-between;

    & > button {
      width: 6.875rem;
      height: 2.875rem;
      margin-left: 2rem;
    }
  }
`;


/**
 * Branch Goal data grid row
 */
export interface IBranchGoalDataRow {
  id: number;
  clientId: number;
  branchName: string;
  branchNumber: number;
  goal: number;
  effectiveDate: string;
  created: string | null;
  lastModified: string | null;
  isError: boolean;
  isChanged: boolean;
}

/**
 * Props passed 
 */
interface IBranchGoalDataTableProps {
  branchGoals: IBranchGoalDataRow[];
  isDisabled: boolean;
  canEdit: boolean;
  processRowUpdate: (newRow: GridRowModel) => GridValidRowModel;
  handleSaveClick: (event: MouseEvent<HTMLButtonElement>) => void;
  handleCancelClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

/**
 * Branch Goal data grid component
 * @param {IBranchGoalDataTableProps} props 
 * @returns 
 */
export const BranchGoalDataTable = (props: IBranchGoalDataTableProps) => {
  const { branchGoals, isDisabled, canEdit, processRowUpdate, handleSaveClick, handleCancelClick } = props;
  const rowsPerPage: number[] = [5, 10, 20];
  const [paginationModel, setPaginationModel] = React.useState({ pageSize: 5, page: 0 });

  const isGoalError = (value: unknown) => {
    return (isNaN(value as number) || (value as number) < 0 || (value as number) > 999);
  };

  //  DataGrid Column definition
  const columns: GridColDef[] = [
    { field: 'colClientId', headerName: 'Client ID', flex: 1, minWidth: 100, maxWidth: 140, headerAlign: 'left' },
    { field: 'colBranchName', headerName: 'Branch Name', flex: 2, minWidth: 120, headerAlign: 'left' },
    {
      field: 'colBranchNumber', headerName: 'Branch Number', headerClassName: 'header-branch-number',
      minWidth: 120, headerAlign: 'left', flex: 1
    },
    {
      field: 'colCreated', headerName: 'Created', headerAlign: 'left', sortable: false,
      flex: 1, minWidth: 110,
    },
    {
      field: 'colLastModified', headerName: 'Last Modified', headerClassName: 'header-last-modified',
      headerAlign: 'left', sortable: false,
      flex: 1, minWidth: 110,
    },
    {
      field: 'colGoal', headerName: 'Goal Number', width: 80,
      headerAlign: 'left', headerClassName: 'header-goal-number', sortable: false,
      renderCell: (params: GridCellParams) => (
        <div className='cell-goal' onKeyUp={(e) => {
          processRowUpdate({
            id: params.row.id,
            colClientId: params.row.colClientId,
            colBranchName: params.row.colBranchName,
            colBranchNumber: params.row.colBranchNumber,
            colCreated: params.row.colCreated,
            colLastModified: params.row.colLastModified,
            colGoal: Number((e.target as HTMLInputElement).value),
            colEffectiveDate: params.row.colEffectiveDate,
            isError: isGoalError((e.target as HTMLInputElement).value)
          } as GridRowModel);
        }}
        >
          <StyledTextField variant='outlined'
            defaultValue={params.row.colGoal}
            error={isGoalError(params.value)}
            InputProps={{ readOnly: !canEdit }}
          // readOnly= {!canEdit}
          />
        </div>
      )
    },
    {
      field: 'colEffectiveDate', headerName: 'Effective Date', width: 200,
      headerAlign: 'left', sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <div className='cell-effective-date'>
          <DateInput
            defaultValue={params.row.colEffectiveDate}
            tabIndex={0}
            required={false}
            name={''}
            id={`ed-${params.id}`}
            errorMessage={'invalid date'}
            disabled={false}
            readOnly={!canEdit}
            changeHandler={(value: string | undefined) => {
              processRowUpdate({
                id: params.row.id,
                colClientId: params.row.colClientId,
                colBranchName: params.row.colBranchName,
                colBranchNumber: params.row.colBranchNumber,
                colCreated: params.row.colCreated,
                colLastModified: params.row.colLastModified,
                colGoal: params.row.colGoal,
                colEffectiveDate: format((value) ? new Date(value) : new Date(), 'yyyy-MM-dd'),
                isError: params.row.isError
              } as GridRowModel);
            }}
          />
        </div>
      )
    },
  ];

  const rows = branchGoals.map(c => {
    return {
      id: c.id,
      colClientId: c.clientId,
      colBranchName: c.branchName,
      colBranchNumber: c.branchNumber,
      colCreated: c.created,
      colLastModified: c.lastModified,
      colGoal: c.goal,
      colEffectiveDate: c.effectiveDate,
      isError: c.isError  //  this row has an error
    };
  });

  return (
    <DataGridDiv id='branch-goals-table'>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DataGrid rows={rows} columns={columns}
          pagination
          pageSizeOptions={rowsPerPage}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          autoHeight
          disableColumnMenu
          disableRowSelectionOnClick
          slots={{
            columnSortedDescendingIcon: SortedDescendingIcon,
            columnSortedAscendingIcon: SortedAscendingIcon,
            noRowsOverlay: NothingAvailableOverlay,
            footer: BranchGoalDataTableFooter,
          }}
          slotProps={{
            noRowsOverlay: { text: 'branches for this client are' },
            footer: {
              isDisabled: isDisabled,
              canSave: canEdit,
              handleSaveClick: handleSaveClick,
              handleCancelClick: handleCancelClick
            }
          }}
          sortingOrder={['desc', 'asc']}
          initialState={{
            // sorting: { sortModel: [{ field: 'colBranchNumber', sort: 'asc' }] },
            sorting: { sortModel: [{ field: 'colBranchName', sort: 'asc' }] },
          }}
          processRowUpdate={processRowUpdate}
        />
      </LocalizationProvider >
    </DataGridDiv>
  );
};


