import { IPublicClientApplication } from '@azure/msal-browser';
import { HttpService, IHeaders } from 'shared/service/http/http-service';
import { VelocityErrorHelper } from 'shared/utils/velocity-error-helper';
import { getAccessToken } from 'shared/service/http/token-retriever';
import { UserRole } from 'App/User/user-role';
import { GuidHelper } from 'shared/utils/guid-helper';
import { vlogger } from 'shared/service/logger/vlogger';
import { JwtHelper } from 'shared/utils/jwt-helper';


/**
 * IUserRole interface
 */
export interface IUserRole {
  id?: number;
  name: string;
  description?: string;
  isEnabled?: boolean;
  priority?: number;
}

/**
 * User role request interface
 * @interface
 */
export interface IUserRoleRequest extends IUserRole {
  returnEntity?: boolean;
}


/**
 * Role Settings Service Interface  - use for DI (or DI like)
 * @interface
 */
export interface IRoleProfileService {
  deleteRole(pca: IPublicClientApplication, role: UserRole): Promise<boolean>;
}

/**
 * Default User role
 */
export const default_userRole = UserRole.FI_USER;
export const default_userPriority = 0;

export const defaultRole: IUserRole = {
  name: UserRole[default_userRole],
  description: 'default user',
  isEnabled: false,
  priority: default_userPriority,
};


/** 
 * Class representing the Role Settings Service http interface object
 * @class
 * @implements IRoleProfileService
 */
export class RoleProfileService implements IRoleProfileService {

  /**
   * Fetch user role 
   * @param name unique role identifier
   * @returns user role profile
   * @async
   * @public
   */
  async getRole(pca: IPublicClientApplication, name: string): Promise<IUserRole | null> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const accessToken = await getAccessToken(pca);
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      // 'X-CID': `${JwtHelper.GetClientId(accessToken)}`
    };
    const httpService = new HttpService(vlogger, baseUrl, headers);
    const request: IUserRoleRequest = { name: name };

    const result =
      await httpService.post<IUserRoleRequest, IUserRole>('api/v1/role/getUserRole', request, headers, uuid);

    if (result === null) return null;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
      return null;
    }

    return result.result;
  }

  /**
  * Fetch all user role 
  * @param name unique role identifier
  * @returns user role profile
  * @async
  * @public
  */
  async getRoles(pca: IPublicClientApplication): Promise<IUserRole[] | null> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const accessToken = await getAccessToken(pca);
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      // 'X-CID': `${JwtHelper.GetClientId(accessToken)}`
    };
    const httpService = new HttpService(vlogger, baseUrl, headers);

    const result =
      await httpService.get<IUserRole[]>('api/v1/role/getAllRoles', {}, headers, uuid);

    if (result === null) return null;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
      return null;
    }

    const roles = result.result?.sort((a, b) => ((a.priority ?? 0) - (b?.priority ?? 0)));
    return roles;
  }


  /**
   * Add or Update user role 
   * @param name unique role identifier
   * @returns user role profile
   * @async
   * @public
   */
  async addUpdateRole(pca: IPublicClientApplication, role: IUserRole, returnEntity = false): Promise<IUserRole | null> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const accessToken = await getAccessToken(pca);
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      // 'X-CID': `${JwtHelper.GetClientId(accessToken)}`
    };
    const httpService = new HttpService(vlogger, baseUrl, headers);
    const request: IUserRoleRequest = { ...role, returnEntity };

    const result =
      await httpService.post<IUserRoleRequest, IUserRole | null>('api/v1/role/addUserRole', request, headers, uuid);

    if (result === null) return null;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
      return null;
    }

    return result.result ?? defaultRole;
  }


  /**
   * Delete user role 
   * @param role unique role identifier (enum)
   * @returns true if delete was successful
   * @async
   * @public
   */
  async deleteRole(pca: IPublicClientApplication, role: UserRole): Promise<boolean> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const accessToken = await getAccessToken(pca);
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      // 'X-CID': `${JwtHelper.GetClientId(accessToken)}`
    };
    const httpService = new HttpService(vlogger, baseUrl, headers);
    const request: IUserRoleRequest = { name: `${role}` };

    //  HTTP Post to VIP (ACL)
    const result =
      await httpService.post<IUserRoleRequest, void>('api/v1/role/deleteUserRole', request, headers, uuid);

    if (result === null) return false;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }

    return (result.errors.length === 0);
  }
}
