import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { IHeaders } from 'shared/service/http/http-service';
import { ILogger, LogLevel } from './vlogger';
import { GuidHelper } from 'shared/utils/guid-helper';


/**
 * Error request interface
 * @interface
 */
interface IErrorRequest {
  exception?: string;
  level: string;
  logEvent?: string;
  message: string;
  timestamp: string;
  operationId: string;
}

/**
 * LoggerHttpTransport interface
 * @interface
 */
export interface ILoggerHttpTransport {
  log(level: LogLevel, message: string, exception?: string): void;
}

/**
 * Logger Http Transport class
 * @class
 */
export class LoggerHttpTransport implements ILoggerHttpTransport {
  private _headers: IHeaders;
  private _logger: ILogger;


  /**
   * CTOR
   * @param options 
   */
  constructor(logger: ILogger, headers?: IHeaders) {
    this._headers = headers ?? {};
    this._logger = logger;
  }

  /**
   * Core logging method exposed to Winston.
   * @param {Object} info - TODO: add param description.
   * @param {function} callback - TODO: add param description.
   * @returns {void}
   */
  log(level: LogLevel, message: string, exception?: string): void {
    const operationId = GuidHelper.NewGuid();
    const timestamp = new Date().toISOString();

    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'OperationId': operationId
    };

    const data: IErrorRequest = {
      operationId: operationId,
      timestamp: timestamp,
      level: `${level}`,
      message: `${level}  ${timestamp}  ${message}`,
      exception: exception ?? '',
    };
    data.logEvent = JSON.stringify({
      'Timepstamp': data.timestamp,
      'Level': data.level,
      'Message': data.message,
      'Properities': {
        'OperationId': operationId
      }
    });

    const axiosConfig: AxiosRequestConfig = { baseURL: baseUrl, headers: headers };
    axios.post('/api/v1/error/adderror', data, axiosConfig)
      .catch((error: AxiosError) => {
        const errorMessage = `Logger Error: name: ${error?.name}, status: ${error?.code || 500}, ` +
          `operationId: ${operationId}, message: ${error?.message}`;
        console.log(errorMessage);
      });
  }
}
