import { useEffect, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import { getAccessToken } from 'shared/service/http/token-retriever';


/**
 * Resend Registration component
 * @returns
 */
export const AuthorizationSession = () => {
  const { instance } = useMsal();
  const aTokenRef = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getAccessToken(instance);
      if (aTokenRef.current) {
        aTokenRef.current.innerText = token;
      }
    };

    fetchToken();
  }, []);


  return (
    <label id="access-token" ref={aTokenRef} className='hidden' />
  );
};
