import React from 'react';
import { IRoutePath } from 'shared/interfaces/iroute-path';
import { Logout } from 'Layout/Logout';
import { NotAuthorized } from 'shared/components/NotAuthorized';
import { NotFound } from 'shared/components/NotFound';


import { AdminPageLayout } from 'Layout/AdminPageLayout';
import { Administration } from 'App/Administration';
import { UserSettings } from 'App/Administration/UserSettings';
import { ClientSettings } from 'App/Administration/ClientSettings';
import { RoleSettings } from 'App/Administration/RoleSettings';
import { Configuration } from 'App/Administration/Configuration';
import { Status } from 'App/Administration/Status';
import { ProductCheck } from 'App/Administration/Status/ProductCheck';


import { MainPageLayout } from 'Layout/MainPageLayout';
import { AuthGuard } from 'Layout/AuthGuard';
import { Home } from 'App/Home';
import { Dashboard } from 'App/Dashboards/Dashboard';
import { ComingSoon } from 'App/Dashboards/ComingSoon';
import { Reports as StaticReport } from 'App/Reports';
import { Report as PowerBiReport } from 'App/Insights/Report';
import { Training } from 'App/Training';
import { ResendRegistration } from 'App/ResendRegistration';
import { AuthorizationSession } from 'App/AuthorizationSession';


import { AnonymousPageLayout } from 'Layout/AnonymousPageLayout';
import { AnonymousPages } from 'App/AnonymousPages';
import { SignUp } from 'App/AnonymousPages/SignUp';

import { products } from 'App/app-constants';
import { StringHelper } from 'shared/utils/string-helper';
import { BranchGoals } from 'App/Administration/BranchGoals';


/**
 * Insights pages / routes
 */
const insightsRoutes: string[] = [
  'Summary Dashboard',
  'Core Deposits Analysis',
  'New Accounts Analysis',
  'NSF Analysis',
  'Deposit Movement'
];

/**
 * Declared routes
 */
export const routes: IRoutePath[] = [
  {
    key: 'auth-guard',
    displayName: 'AuthGuard',
    isLayoutPage: true,
    isDisplayed: false,
    element: <AuthGuard />,
    children: [
      {
        key: 'main-layout',
        displayName: 'MainPageLayout',
        isLayoutPage: true,
        isDisplayed: false,
        element: <MainPageLayout />,
        children: [
          //  Root
          {
            path: '/',
            key: 'index.html',
            displayName: '',
            isDisplayed: false,
            element: <Home />,
          },
          //  Home
          {
            path: '/home',
            key: 'home',
            // index: true,
            displayName: 'Home',
            isDisplayed: false,
            element: <Home />,
          },
          //  Insights
          {
            path: '/insights',
            key: 'insights',
            displayName: 'Insights',
            isDisplayed: true,
            children: insightsRoutes.map<IRoutePath>(n => {
              const route: IRoutePath = {
                path: `${StringHelper.SpaceRemover(n)}`,
                displayName: n,
                key: StringHelper.SpaceRemover(n.toLowerCase(), '-'),
                isDisplayed: true,
                element: <PowerBiReport />,
              };
              return route;
            }),
          },
          //  Dashboards
          {
            path: '/dashboard',
            key: 'dashboard',
            displayName: 'Dashboards',
            isDisplayed: true,
            children: [
              {
                path: 'ars',
                displayName: products['ars'],
                key: 'ars',
                isDisplayed: true,
                children: [
                  {
                    path: 'financial-performance',
                    displayName: 'ARS Financial Performance',
                    key: 'ars_fp',
                    isDisplayed: true,
                    children: [
                      {
                        displayName: 'ARS Executive Summary',
                        key: 'ars_fp_aes',
                        isDisplayed: true,
                        element: <Dashboard />,
                      },
                      {
                        displayName: 'ARS Revenue Performance',
                        key: 'ars_fp_arp',
                        isDisplayed: true,
                        element: <Dashboard />,
                      },
                      {
                        displayName: 'ARS Actuals',
                        key: 'ars_fp_aa',
                        isDisplayed: true,
                        element: <Dashboard />,
                      },
                      {
                        displayName: 'ARS All Months Rolled Up',
                        key: 'ars_fp_aamru',
                        isDisplayed: true,
                        element: <Dashboard />,
                      },
                      {
                        displayName: 'ARS DCO',
                        key: 'ars_fp_ad',
                        isDisplayed: true,
                        element: <Dashboard />,
                      },
                    ],
                  },
                  {
                    displayName: 'ARS Usage Summary',
                    key: 'ars_aus',
                    isDisplayed: true,
                    element: <Dashboard />,
                  },
                  {
                    displayName: 'ARS Debit Card',
                    key: 'ars_adc',
                    isDisplayed: true,
                    element: <Dashboard />,
                  },
                  {
                    displayName: 'ARS Accounts',
                    key: 'ars_aa',
                    isDisplayed: true,
                    element: <Dashboard />,
                  },
                  {
                    displayName: 'ARS Reg E',
                    key: 'ars_are',
                    isDisplayed: true,
                    element: <Dashboard />,
                  },
                ],
              },
              {
                path: 'cp',
                displayName: products['cp'],
                key: 'cp',
                isDisplayed: true,
                children: [
                  {
                    displayName: 'CP Loan Summary and Payoff',
                    key: 'cp_lsap',
                    isDisplayed: true,
                    element: <Dashboard />,
                  },
                ],
              },
              {
                path: 'mrpc',
                displayName: products['mrpc'],
                key: 'mrpc',
                isDisplayed: true,
                children: [
                  {
                    displayName: 'MRPC Revenue to FI',
                    key: 'mrpc_rtfi',
                    isDisplayed: true,
                    element: <Dashboard />,
                  },
                  {
                    displayName: 'MRPC Enrollments',
                    key: 'mrpc_e',
                    isDisplayed: true,
                    element: <Dashboard />,
                  },
                  {
                    displayName: 'MRPC Performance',
                    key: 'mrpc_p',
                    isDisplayed: true,
                    element: <Dashboard />,
                  },
                  {
                    displayName: 'MRPC Retention',
                    key: 'mrpc_r',
                    isDisplayed: true,
                    element: <Dashboard />,
                  },
                ],
              },
              {
                path: 'ils',
                displayName: products['ils'],
                key: 'ils',
                isDisplayed: true,
                children: [
                  {
                    displayName: 'ILS Operations',
                    key: 'ils_o',
                    isDisplayed: true,
                    element: <Dashboard />,
                  },
                  {
                    displayName: 'ILS Operations Management',
                    key: 'ils_om',
                    isDisplayed: true,
                    element: <Dashboard />,
                  },
                  {
                    displayName: 'ILS Executive Management',
                    key: 'ils_em',
                    isDisplayed: true,
                    element: <Dashboard />,
                  },
                  {
                    displayName: 'ILS NSF/OD Re-Presentments',
                    key: 'ils_r',
                    isDisplayed: true,
                    element: <Dashboard />,
                  },
                  {
                    displayName: 'ILS Peer Comparisons',
                    key: 'ils_pc',
                    isDisplayed: true,
                    element: <Dashboard />,
                  },
                ],
              },
              {
                path: 'ics',
                displayName: products['ics'],
                key: 'ics',
                isDisplayed: true,
                children: [
                  {
                    displayName: 'ICS Referral Data',
                    key: 'ics_rd',
                    isDisplayed: true,
                    element: <Dashboard />,
                  },
                ]
              }
            ],
          },
          //  Reports
          {
            path: '/reports',
            key: 'reports',
            displayName: 'Reports',
            isDisplayed: true,
            children: [
              {
                path: 'all',
                displayName: 'All Reports',
                key: 'all',
                isDisplayed: true,
                element: <StaticReport />,
              },
              {
                path: 'ars',
                displayName: products['ars'],
                key: 'ars',
                isDisplayed: true,
                element: <StaticReport />,
              },
              {
                path: 'cp',
                displayName: products['cp'],
                key: 'cp',
                isDisplayed: true,
                element: <StaticReport />,
              },
              {
                path: 'mrpc',
                displayName: products['mrpc'],
                key: 'mrpc',
                isDisplayed: true,
                element: <StaticReport />,
              },
              {
                path: 'ils',
                displayName: products['ils'],
                key: 'ils',
                isDisplayed: true,
                element: <StaticReport />,
              },
              {
                path: 'ics',
                displayName: products['ics'],
                key: 'ics',
                isDisplayed: true,
                element: <StaticReport />,
              },
              {
                path: 'ois',
                displayName: products['ois'],
                key: 'ois',
                isDisplayed: true,
                element: <StaticReport />,
              },
            ],
          },
          //  Training
          {
            path: '/training',
            key: 'training',
            displayName: 'Training',
            isDisplayed: true,
            children: [
              {
                path: 'all',
                displayName: 'All Trainings',
                key: 'all',
                isDisplayed: true,
                element: <Training />,
              },
              {
                path: 'ars',
                displayName: products['ars'],
                key: 'ars',
                isDisplayed: true,
                element: <Training />,
              },
              {
                path: 'cp',
                displayName: products['cp'],
                key: 'cp',
                isDisplayed: true,
                element: <Training />,
              },
              {
                path: 'mrpc',
                displayName: products['mrpc'],
                key: 'mrpc',
                isDisplayed: true,
                element: <Training />,
              },
              {
                path: 'ils',
                displayName: products['ils'],
                key: 'ils',
                isDisplayed: true,
                element: <Training />,
              },
              {
                path: 'ics',
                displayName: products['ics'],
                key: 'ics',
                isDisplayed: true,
                element: <Training />,
              },
              {
                path: 'ois',
                displayName: products['ois'],
                key: 'ois',
                isDisplayed: true,
                element: <Training />,
              },
            ],
          },
          // Resend Registration Email
          {
            path: '/resend-registration',
            displayName: 'Resend Email Registration',
            isDisplayed: false,
            element: <ResendRegistration />,
          },
          // Authorization Session
          {
            path: '/authorization-session',
            displayName: 'Authorization Session',
            isDisplayed: false,
            element: <AuthorizationSession />,
          },
          //  Logout
          {
            path: '/Logout',
            displayName: 'Logout',
            key: 'lo',
            isDisplayed: false,
            element: <Logout />,
          },
          // NotAuthorized
          {
            path: '/NotAuthorized',
            displayName: 'Not Authorized',
            isDisplayed: false,
            element: <NotAuthorized />,
          },
          // CatchAll
          {
            path: '*',
            displayName: 'Not Found',
            isDisplayed: false,
            element: <NotFound />,
          },
        ],
      },
      //  Admin Pages
      {
        key: 'admin-layout',
        displayName: 'AdminPageLayout',
        isLayoutPage: true,
        isDisplayed: false,
        element: <AdminPageLayout />,
        children: [
          {
            key: 'administration-pages',
            path: 'administration',
            displayName: 'Administration',
            isLayoutPage: true,
            isDisplayed: false,
            element: <Administration />,
            children: [
              {
                path: 'user-settings',
                displayName: 'User Settings',
                key: 'user-settings',
                isDisplayed: true,
                element: <UserSettings />,
              },
              {
                path: 'referral-goals',
                displayName: ' FI Referral Goals',
                key: 'referral-goals',
                isDisplayed: true,
                element: <BranchGoals />,
              },
              {
                path: 'client-settings',
                displayName: 'Client Settings',
                key: 'client-settings',
                isDisplayed: true,
                element: <ClientSettings />,
              },
              {
                path: 'role-settings',
                displayName: 'Role Settings',
                key: 'role-settings',
                isDisplayed: true,
                element: <RoleSettings />,
              },
              {
                path: 'status',
                displayName: 'Status',
                key: 'status',
                isDisplayed: true,
                children: [
                  {
                    path: 'product',
                    displayName: 'Product Check',
                    key: 'product',
                    isDisplayed: true,
                    element: <ProductCheck />,
                  },
                ],
              },
              {
                path: 'configuration',
                displayName: 'Configuration Page',
                key: 'configuration',
                isDisplayed: true,
                element: <Configuration />
              },
            ],
          }
        ]
      }
    ] as IRoutePath[],
  },
  {
    //  Anonymous Pages
    key: 'anonymous-layout',
    displayName: 'AnonymousPageLayout',

    isLayoutPage: true,
    isDisplayed: false,
    element: <AnonymousPageLayout />,
    children: [
      {
        key: 'anonymous-pages',
        displayName: 'AnonymousPages',
        isLayoutPage: true,
        isDisplayed: false,
        element: <AnonymousPages />,
        children: [
          //  Sign up
          {
            path: '/sign-up',
            displayName: 'Vip User SignUp',
            isDisplayed: false,
            element: <SignUp />,
          }
        ],
      }
    ] as IRoutePath[],
  },
];
