import React from 'react';
import { Divider } from '@mui/material';
import styled from '@emotion/styled';

const ContentContainer = styled.div`
  width: calc(100% - 2 * (22.1px + (120 - 22.1) * ((100vw - 320px) / (1440 - 320))));
  margin: 45px calc(22.1px + (120 - 22.1) * ((100vw - 320px) / (1440 - 320)));

  & .divider {
    background-color: var(--color-primary);
    margin: 15px 0 0;
    height: 5px;
    width: 100%;
  }
`;

export const NotFound = () => {
  return (
    <ContentContainer>
      <h2 className='titleText'>404</h2>
      <Divider className='divider' />
      <p className='textContainer'>Page Not Found</p>
    </ContentContainer>
  );
};
