import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addUpdateClientFeatures } from 'redux/featureSlice';
import { IVipCompany, IVipUser } from 'redux/profileSlice';
import { ApplicationSection, Permission, hasAccess } from 'App/User/user-role';
import { NotAuthorized } from 'shared/components/NotAuthorized';
import { featureFlagValues, IClientFeature, IFeatureFlag } from 'shared/interfaces/iclient-feature';
import { SearchBar } from 'shared/components/SearchBar';
import { ClientDataTable, IClientFeatureClickModel, IClientFeatureDataRow } from './ClientDataTable';
import { ProductHelper } from 'shared/utils/product-helper';
import styled from '@emotion/styled';
import { vlogger } from 'shared/service/logger/vlogger';


/**
 * Styling
 */
const ClientSettingsContent = styled.div`
  margin: 0px 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & #content-header {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;


/**
 * ClientSettings page
 * @returns 
 */
export const ClientSettings = () => {
  const { instance } = useMsal();
  const dispatch = useAppDispatch();

  const featureFlags: IFeatureFlag[] = useAppSelector((state) => state.feature.featureFlags);
  const clientsFeatures: IClientFeature[] = useAppSelector((state) => state.feature.clientsFeatures);
  const companies: IVipCompany[] = useAppSelector((state) => state.app.companies);
  const user: IVipUser = useAppSelector((state) => state.profile.user);

  const [savedClientFeature, setSavedClientFeature] = useState<IClientFeatureDataRow[]>([]);
  const [filteredClientFeature, setFilteredClientFeature] = useState<IClientFeatureDataRow[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  // Verify user has authorization
  const hasClientSettingsAuthorization = hasAccess(user, ApplicationSection.CLIENT_ADMIN, Permission.VIEW);
  if (!hasClientSettingsAuthorization)
    return (<NotAuthorized />);

  //  Implement client-settings


  // set client feature
  useEffect(() => {
    const insightsFFId =
      featureFlags.find(ff => ff.featureName === featureFlagValues['insights'])?.featureId;
    const arsFpFFId =
      featureFlags.find(ff => ff.featureName === featureFlagValues['ars_fp'])?.featureId;

    const rows = companies.map(c => {
      const cf = clientsFeatures.filter(cf => cf.clientId === c.clientId);

      const cfdr: IClientFeatureDataRow = {
        clientId: c.clientId,
        name: c.name,
        products: ProductHelper.companyToAbbrev(c),
        isMultiMonth: c.isMultiMonthRevenueModel,
        isInsights: cf.find(i => i.featureFlagId === insightsFFId)?.isEnabled || false,
        isArsFp: cf.find(i => i.featureFlagId === arsFpFFId)?.isEnabled || false,
      };

      return cfdr;
    });

    setSavedClientFeature(rows.sort((a, b) => (a.clientId - b.clientId)));
  }, [companies, featureFlags, clientsFeatures]);

  //  Search filtering
  useEffect(() => {
    const lowerSearchText = searchText?.toLowerCase() ?? '';
    const filteredSearchClientFeatures = (!searchText)
      ? savedClientFeature
      : savedClientFeature.filter(f => {
        return (
          f.name.toLowerCase().includes(lowerSearchText) ||
          f.clientId.toString().includes(lowerSearchText)
        );
      });

    setFilteredClientFeature(filteredSearchClientFeatures);
  }, [searchText, savedClientFeature]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  //  Feature flag click
  const handleOnClickFeature =
    async (event: MouseEvent<HTMLButtonElement>, eventdata: IClientFeatureClickModel) => {
      const feature = featureFlags.find(ff => ff.featureName === eventdata.flag);
      if (!feature) {
        vlogger.error(`client-settings: unable to find feature flag ${eventdata.flag}`);
        return;
      }

      const cf: IClientFeature = {
        clientId: eventdata.clientId,
        featureFlagId: featureFlags.find(f => f.featureName === eventdata.flag)?.featureId || -1,
        isEnabled: eventdata.value,
      };

      dispatch(addUpdateClientFeatures({ pca: instance, cf }));
    };

  return (
    <ClientSettingsContent id='client-settings-content'>
      <div id='content-header'>
        <SearchBar id='search-bar' searchQuery={searchText} setSearchQuery={(e) => handleSearchChange(e)} />
      </div>

      <div id='content-body' className={'width-100'}>
        <ClientDataTable key={'ClientDataTable'} clientFeatures={filteredClientFeature} onFeatureClick={handleOnClickFeature} />
      </div>
    </ClientSettingsContent>
  );
};
