import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useAppSelector } from 'redux/hooks';
import { IVipCompany } from 'redux/profileSlice';
import { IProductFlags, ProductCheckService } from './product-check-service';
import { LabelWithError } from './LabelWithError';
import { Autocomplete, Box, Divider, TextField } from '@mui/material';
import styled from '@emotion/styled';


/**
 * Styling
 */
const ProductCheckDiv = styled.div`
  & #header {
    display: flex;

    & .left, &.right {
      width: 50%;
    }
  }

  & .MuiAutocomplete-root {
    // width: 50%;
    // margin: 1rem 1rem;
  }

  & .MuiInputBase-root {
    & input {
      width: auto;
      font-size: 1.125rem;
      padding: 0.5rem 0.5rem !important;
    }  
  }

  & .right .MuiFormControl-root {
    padding: 1px;
  }

  & .MuiDivider-root {
    margin: 1rem 0rem;
  }

  & .client-box {
    border: solid 1px var(--outline);
    padding: 0.25rem;
  }

  & .section {
    margin: 1rem 0rem;

    & .heading {
      font-weight: bold;
    }

    & label {
      margin: 0rem 0.5rem;
    }
  }
`;

/**
 * Product page
 * @returns
 */
export const ProductCheck = () => {
  const companies: IVipCompany[] = useAppSelector((state) => state.app.companies);
  const { instance } = useMsal();
  const [reqClientId, setReqClientId] = useState<number>(-1);
  const [selectedCompany, setSelectedCompany] = useState<IVipCompany>();
  const [productChecks, setProductChecks] = useState<IProductFlags[]>([]);

  const productCheckService = new ProductCheckService();

  useEffect(() => {
    async function getProductCheck() {
      const productFlags =
        await productCheckService.getProductChecks(instance, { clientId: reqClientId });
      if (productFlags)
        setProductChecks(productFlags);
    }
    getProductCheck();

    const newCompany = companies.find(c => c.clientId === reqClientId);
    if (newCompany)
      setSelectedCompany(newCompany);
  }, [reqClientId]);

  return (
    <ProductCheckDiv>

      <div id='header'>
        <div className='left'>
          <h4>Select a client:</h4>
          <Autocomplete id="company-selector" autoComplete autoHighlight autoSelect disableClearable
            options={companies}
            getOptionLabel={(option) => `(${option.clientId})${option.name}`}
            renderInput={(params) =>
              <TextField {...params} variant="outlined" placeholder="Select Client" />
            }
            isOptionEqualToValue={(option, value) => option.clientId === value.clientId}
            onChange={(event, newValue) => { setReqClientId(newValue.clientId); }}
            value={(reqClientId > 0) ? companies.find(c => c.clientId === reqClientId) : undefined}
          />
        </div>
        <div className='right'>
          <h4>Enter client id:</h4>
          <TextField id='client-id' variant='outlined' placeholder="Enter Client Id"
            value={(reqClientId > 0) ? reqClientId : undefined}
            onChange={(event) => { setReqClientId(parseInt(event.target.value)); }}
          />
        </div>
      </div>

      <Divider />

      {productChecks.map((pc, index) => {
        const isLicenseCP = pc.ilsProduct?.toLowerCase() === 'cashplease' ||
          pc.ilsLicenseProducts?.toLowerCase().includes('cashplease');
        const isLicenseMRPC = pc.ilsProduct?.toLowerCase() === 'mrpc' ||
          pc.ilsLicenseProducts?.toLowerCase().includes('mrpc');
        const isLicenseOIS = pc.ilsProduct?.toLowerCase() === 'ois' ||
          pc.ilsLicenseProducts?.toLowerCase().includes('ois');

        return (
          <div key={`c-${pc.clientId}.${index}`} className='client-box'>
            <Box className='section'>
              <label id='client-id' className='heading'>Client:</label>
              <label>Client Id: {`${pc.clientId}`}</label>
              <label>Client Name: {`${pc.clientName}`}</label>
              <LabelWithError message='Domain Id:' value={`${pc.domainId ?? 'null'}`} isError={!pc.domainId} />
              <LabelWithError message='Corp Id:' value={`${pc.srdbCorpId ?? 'null'}`} isError={!pc.srdbCorpId} />
            </Box>
            <Box className='vip-section section'>
              <label id='vip' className='heading'>VIP:</label>
              <LabelWithError message='Client Found:' value={`${pc.isClientFound}`} isError={!pc.isClientFound} />
              <LabelWithError message='FedFis Found:' value={`${pc.isFedFisFound}`} isError={!pc.isFedFisFound} />
            </Box>

            <Box className='ars-section section'>
              <label id='ars' className='heading'>ARS:</label>
              <LabelWithError message='is_ARS:' value={`${selectedCompany?.isARS}`} isError={!selectedCompany?.isARS} />
              <LabelWithError message='has_DomainId:' value={`${!!pc.domainId}`} isError={!pc.domainId} />
              <LabelWithError message='SRDB Active:' value={`${pc.isSRDBActive}`} isError={!pc.isSRDBActive} />
              <LabelWithError message='ARS Db:' value={`${pc.arsLegacyDb ?? 'null'}`} isError={!pc.arsLegacyDb} />
              <LabelWithError message='Imported Within Last Year:' value={`${pc.hasImportedWithinLastYear}`} isError={!pc.hasImportedWithinLastYear} />
              <LabelWithError message='ICS Program Start Set:' value={`${pc.isICSProgramStartSet}`} isError={!pc.isICSProgramStartSet} />
              <label>Multi Month ROI: {`${pc.isMultiMonth}`}</label>
            </Box>

            <Box className='mrpc-section section'>
              <label id='mrpc' className='heading'>MRPC:</label>
              <LabelWithError message='is_MRPC:' value={`${selectedCompany?.isMRPC}`} isError={!selectedCompany?.isMRPC} />
              <LabelWithError message='has_DomainId:' value={`${!!pc.domainId}`} isError={!pc.domainId} />
              <LabelWithError message='MRPC License:' value={`${isLicenseMRPC}`} isError={!isLicenseMRPC} />
            </Box>

            <Box className='ois-section section'>
              <label id='ois' className='heading'>OIS:</label>
              <LabelWithError message='is_OIS:' value={`${selectedCompany?.isOIS}`} isError={!selectedCompany?.isOIS} />
              <LabelWithError message='has_DomainId:' value={`${!!pc.domainId}`} isError={!pc.domainId} />
              <LabelWithError message='OIS License:' value={`${isLicenseOIS}`} isError={!isLicenseOIS} />
            </Box>

            <Box className='ics-section section'>
              <label id='ics' className='heading'>ICS:</label>
              <LabelWithError message='is_ICS:' value={`${selectedCompany?.isICS}`} isError={!selectedCompany?.isICS} />
              <LabelWithError message='has_DomainId:' value={`${!!pc.domainId}`} isError={!pc.domainId} />
              <LabelWithError message='ICS Program Start Set:' value={`${pc.isICSProgramStartSet}`} isError={pc.isICSProgramStartSet !== true} />
              <LabelWithError message='SRDB Active:' value={`${pc.isSRDBActive}`} isError={!pc.isSRDBActive} />
              <LabelWithError message='ARS Db:' value={`${pc.arsLegacyDb ?? 'null'}`} isError={!pc.arsLegacyDb} />
              <LabelWithError message='FedFis Found:' value={`${pc.isFedFisFound}`} isError={!pc.isFedFisFound} />
              <LabelWithError message='Imported Within Last Year:' value={`${pc.hasImportedWithinLastYear}`} isError={!pc.hasImportedWithinLastYear} />
            </Box>

            <Box className='ils-section section'>
              <label id='ils' className='heading'>ILS:</label>
              <LabelWithError message='is_ILS:' value={`${selectedCompany?.isILS}`} isError={!selectedCompany?.isILS} />
              <LabelWithError message='ILS Enabled:' value={`${pc.isILSEnabled}`} isError={!pc.isILSEnabled} />
              <LabelWithError message='ILS Hosted:' value={`${pc.isILSHosted ?? 'null'}`} isError={!pc.isILSHosted} />
              <LabelWithError message='ILS Db:' value={`${pc.ilsDbName ?? 'null'}`} isError={!pc.ilsDbName && pc.isILSHosted} />
              <label>ILS CurrentBuild: {`${pc.ilsCurrentBuild}`}</label>
              <LabelWithError message='ILS LicenseEnabled:' value={`${pc.isILSLicenseEnabled}`} isError={!pc.isILSLicenseEnabled} />
              <LabelWithError message='ILS Product:' value={`${pc.ilsProduct}`} isError={!pc.ilsProduct} />
              {/* <LabelWithError message='ILS License Products:' value={`${pc.ilsLicenseProducts}`} isError={!pc.ilsLicenseProducts} /> */}
              {/* <LabelWithError message='ILS License Functions:' value={`${pc.ilsLicenseFunctions}`} isError={!pc.ilsLicenseFunctions} /> */}
              <LabelWithError message='ILS Production:' value={`${pc.isILSProduction}`} isError={!pc.isILSProduction} />
            </Box>

            <Box className='cp-section section'>
              <label id='cp' className='heading'>CP:</label>
              <LabelWithError message='is_CP:' value={`${selectedCompany?.isCP}`} isError={!selectedCompany?.isCP} />
              <LabelWithError message='has_DomainId:' value={`${!!pc.domainId}`} isError={!pc.domainId} />
              <LabelWithError message='CP License:' value={`${isLicenseCP}`} isError={!isLicenseCP} />
            </Box>
          </div>
        );
      })
      }

    </ProductCheckDiv>
  );
};
