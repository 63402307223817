import { IPublicClientApplication } from '@azure/msal-browser';
import axios, { AxiosRequestConfig } from 'axios';
import { HttpService, IHeaders } from 'shared/service/http/http-service';
import { VelocityErrorHelper } from 'shared/utils/velocity-error-helper';
import { getAccessToken } from 'shared/service/http/token-retriever';
import { GuidHelper } from 'shared/utils/guid-helper';
import { NullObject } from 'shared/model/null-object';
import { vlogger } from 'shared/service/logger/vlogger';
import { StorageStatus } from 'App/app-constants';

/**
 * Directory listing interface
 * @interface
 */
export interface IDirectoryListing {
  directory: string;
  isDirectory: boolean;
  fullpath: string;
  name: string;
  format: string;
  filedate: string;
}


/**
 * Directory listing request interface
 * @interface
 */
export interface IDirectoryListingRequest {
  clientId: number;
  applications: string[];
  fileSegment: string;
}

/**
 * Current status of upload request
 * @enum
 */
export enum UploadStatus {
  NotStarted,
  Processing,
  Success,
  Error,
}

/**
 * Upload file interface
 * @interface
 */
export interface IUploadFileModel {
  name: string;
  clientId?: number;
  date: string;
  product: string;
  format: string;
  file?: File;
  error: string[];
  uploadStatus: UploadStatus;
}

/**
 * Upload listing request interface
 * @interface
 */
export interface IUploadListingRequest {
  clientId?: number;
  filename: string;
  formfile: FormData;
}

/**
 * Read file request interface
 * @interface
 */
export interface IFileReadRequest {
  clientId?: number;
  product: string;
  fileSegment: string;
  filename: string;
}

/**
 * Read file response interface
 * @interface
 */
export interface IFileReadResponse {
  clientId?: number;
  stream: ReadableStream;
}

/**
 * Set file access tier interface
 * @interface
 */
export interface IFileSetAccessTierRequest {
  clientId?: number;
  product: string;
  fileSegment: string;
  filename: string;
  storageStatus: StorageStatus;
}


/**
 * File Service Interface  - use for DI (or DI like)
 * @interface
 */
export interface IFileService {
  getDirectoryListing(pca: IPublicClientApplication, request: IDirectoryListingRequest): Promise<IDirectoryListing[]>;
  uploadListing(pca: IPublicClientApplication, data: FormData): Promise<string | null>;
  readFile(pca: IPublicClientApplication, request: IFileReadRequest): Promise<Blob | null>;
  deleteFile(pca: IPublicClientApplication, request: IFileReadRequest): Promise<string | null>;
  setAccessTierFile(pca: IPublicClientApplication, request: IFileSetAccessTierRequest): Promise<string | null>;
}

/** 
 * Class representing the File Service http interface object
 * @class
 * @implements IFileService
 */
export class FileService implements IFileService {

  /**
   * Get listing of the client files  
   * @param {IPublicClientApplication} pca 
   * @param {IDirectoryListingRequest} request 
   * @returns IDirectoryListing[]
   */
  async getDirectoryListing(pca: IPublicClientApplication, request: IDirectoryListingRequest): Promise<IDirectoryListing[]> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getAccessToken(pca)}`
    };
    const httpService = new HttpService(vlogger, baseUrl, headers);

    const result =
      await httpService.post<IDirectoryListingRequest, IDirectoryListing[]>('api/v1/client/file/companylistfiles', request, headers, uuid);

    if (result === null) return [];
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }

    return result.result ?? [];
  }


  /**
   * Upload files to client's storage
   * @param {IPublicClientApplication} pca 
   * @param {IUploadListingRequest} request 
   * @returns {string|null} null for success or error string
   */
  async uploadListing(pca: IPublicClientApplication, data: FormData): Promise<string | null> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const headers: IHeaders = {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${await getAccessToken(pca)}`
    };
    const httpService = new HttpService(vlogger, baseUrl, headers);

    const result =
      await httpService.post<FormData, NullObject>('api/v1/client/file/fileupload', data, headers, uuid);

    if (result === null) return `Error in request: ${uuid}`;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);

      const isVirusFound = result.errors.findIndex(e => e.message.indexOf('virus')) >= 0;
      if (isVirusFound) {
        return 'virusfound';
      } else {
        return result.errors[0].message;
      }
    }

    return null;
  }

  /**
   * Read files from client's storage
   * @param {IPublicClientApplication} pca 
   * @param {IFileReadRequest} request 
   * @returns {Blob|null} file blob
   */
  async readFile(pca: IPublicClientApplication, request: IFileReadRequest): Promise<Blob | null> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getAccessToken(pca)}`,
      'OperationId': uuid
    };

    const axiosConfig: AxiosRequestConfig = {
      headers: headers,
      responseType: 'arraybuffer'
    };
    const result: Blob | null =
      await axios.post(`${baseUrl}api/v1/client/file/fileread`, request, axiosConfig)
        .then((resp) => { return resp.data; })
        .catch((err) => {
          vlogger.error(`Error: name-${err?.name}, status-${err?.code}`, false, false);
        });
    return result;
  }


  /**
   * Delete files from client's storage
   * @param {IPublicClientApplication} pca 
   * @param {IFileReadRequest} request 
   * @returns {string|null} null for success or error string
   */
  async deleteFile(pca: IPublicClientApplication, request: IFileReadRequest): Promise<string | null> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getAccessToken(pca)}`,
      'OperationId': uuid
    };

    const httpService = new HttpService(vlogger, baseUrl, headers);

    const result =
      await httpService.post<IFileReadRequest, NullObject>(`${baseUrl}api/v1/client/file/fileremove`, request, headers, uuid);

    if (!!result && result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }

    return result?.errors[0]?.message || null;
  }

  /**
   * Set Access Tier on client storage file
   * @param {IPublicClientApplication} pca 
   * @param {IFileSetAccessTierRequest} request 
   * @returns {string|null} null for success or error string
   */
  async setAccessTierFile(pca: IPublicClientApplication, request: IFileSetAccessTierRequest): Promise<string | null> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getAccessToken(pca)}`,
      'OperationId': uuid
    };

    const httpService = new HttpService(vlogger, baseUrl, headers);

    const result =
      await httpService.post<IFileSetAccessTierRequest, NullObject>(`${baseUrl}api/v1/client/file/filesetaccesstier`, request, headers, uuid);

    if (!!result && result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }

    return result?.errors[0]?.message || null;
  }
}
