import React from 'react';
import DOMPurify from 'dompurify';

/**
 * Text Label props interface
 * @interface
 * @param {string} innerHtml - either text or html
 * @param {string} id - element id
 * @param {string} className - css class to apply to text/html
 * @param {number} tabIndex - tabIndex of element
 */
export interface HtmlSpanProps {
  innerHtml: string;
  id?: string;
  className?: string;
  tabIndex?: number;
}

/**
 * Component that sanitizes <span> Text/HTML string for customization
 * @param {HtmlSpanProps} props 
 */
export const HtmlSpan = (props: HtmlSpanProps) => {
  const { id, innerHtml, className, tabIndex } = props;

  return (
    <span
      id={id} className={className} tabIndex={tabIndex}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(innerHtml, { USE_PROFILES: { html: true } }) }}
    />
  );
}; 
