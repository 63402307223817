import React, { useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import { DisplayListingMenuOptions } from './display-listing-constants';


/**
 * Props passed 
 */
export interface IDisplayListingMenuProps {
  isAdmin: boolean;
}

/**
 * Display Listing pulldown
 * @returns 
 */
export const DisplayListingMenu = (props: IDisplayListingMenuProps) => {
  const { isAdmin } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  //  Close user edit menu
  const handleDisplayListingClick = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <div onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVert id="menukabob" className='kabob' titleAccess='Display Menu' aria-controls='menu-display-listing' />
      </div>

      <Menu id="menu-display-listing" role="menu" aria-labelledby="menukabob"
        anchorEl={anchorEl} keepMounted
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open} onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleDisplayListingClick} data-displaylisting={DisplayListingMenuOptions.Download}
          aria-label="download file" role="menuitem"
        >
          Download
        </MenuItem>
        {(isAdmin) &&
          <MenuItem onClick={handleDisplayListingClick} data-displaylisting={DisplayListingMenuOptions.Delete}
            aria-label="delete file" role="menuitem"
          >
            Delete
          </MenuItem>
        }
      </Menu>
    </>
  );
};
