import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * A custom hook that builds on useLocation to parse the pathname for you.
 * @returns memoized list of url pathname parameters
 * @remarks path starts with '/' so this is removed
 */
export function usePathname() {
  const { pathname } = useLocation();

  return useMemo(() => pathname.split('/').slice(1), [pathname]);
}
