
/**
 * User Settings Enums
 */
export enum DisplayListingMenuOptions {
  None = 'NONE',
  View = 'VIEW',
  Download = 'DOWNLOAD',
  Delete = 'DELETE',
}
