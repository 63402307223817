import React, { MouseEvent, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { fetchRoles } from 'redux/appSlice';
import { GridRowModel } from '@mui/x-data-grid';
import { IUserRole } from 'shared/service/profile/role-profile';
import { UserRole } from 'App/User/user-role';
import { RoleProfileService } from 'shared/service/profile/role-profile';
import { RoleDataTable } from './RoleDataTable';
import { AddEditRole } from './AddEditRole';
import { EditMenuOptions } from './role-settings-constants';
import { ModalYesNo } from 'shared/components/Modal/ModalYesNo';
import styled from '@emotion/styled';
import { vlogger } from 'shared/service/logger/vlogger';


/**
 * Styling
 */
const RoleSettingsContent = styled.div`
  margin: 1.5rem;

  & #content-body {
    display: flex;
  }

`;


/**
 * RoleSettings page
 * @returns 
 */
export const RoleSettings = () => {
  const dispatch = useAppDispatch();
  const { instance } = useMsal();
  const roles: IUserRole[] = useAppSelector((state) => state.app.roles);

  //  Add/Edit Users
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [editActionType, setEditActionType] = useState<string>('');
  const [editRole, setEditRole] = useState<IUserRole | null>(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);


  const handleOnEditClick = async (event: MouseEvent<HTMLDivElement>, rowData: GridRowModel) => {
    const userMenuType = (event.target as HTMLDivElement).dataset.useredit;
    const selectedRole = roles.find(u => u.name === rowData.colName) ?? null;
    setEditRole(selectedRole);

    if (userMenuType === EditMenuOptions.Edit) {
      vlogger.debug(`edit role: ${selectedRole?.name}`);
      //  open 'edit' drawer
      setEditActionType('Edit');
      setIsDrawerOpen(true);
    } else if (userMenuType === EditMenuOptions.Delete) {
      vlogger.debug(`delete role: ${selectedRole?.name}`);
      //  modal popup for confirmation
      setDeleteConfirmOpen(true);
    } else if (userMenuType === EditMenuOptions.Add) {
      vlogger.debug('add role');
      //  open 'add' drawer
      setEditActionType('Add');
      setIsDrawerOpen(true);
    }
  };

  //  close drawer
  const onDrawerCloseClick = () => {
    setIsDrawerOpen(false);
  };

  // file delete confirmation response
  const handleOnCancelDelete = () => {
    setDeleteConfirmOpen(false);
  };

  const handleOnDeleteRole = async () => {
    if (editRole) {
      await new RoleProfileService().deleteRole(instance, editRole.name as unknown as UserRole);
      dispatch(fetchRoles(instance));
    }

    setDeleteConfirmOpen(false);
  };

  return (
    <RoleSettingsContent id='role-settings-content'>
      <AddEditRole key={`${editActionType}${isDrawerOpen}`} editRole={editRole}
        actionType={editActionType} isOpen={isDrawerOpen} onCloseClick={onDrawerCloseClick}
      />

      <div id='content-body'>
        <RoleDataTable key='RoleDataTable' roles={roles} onEditClick={handleOnEditClick} />
      </div>

      {/* delete confirmation modal */}
      <ModalYesNo id="modal-delete"
        open={deleteConfirmOpen} className={'yes-no-modal'} disableBackdropClick={true}
        title={'Confirm Delete'}
        text={`Are you sure you want to delete <br/> ${editRole?.name} <br/> role?`}
        onYesClick={handleOnDeleteRole}
        onNoClick={handleOnCancelDelete}
      />
    </RoleSettingsContent>
  );
};
