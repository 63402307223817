import React, { useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import { EditMenuOptions } from './role-settings-constants';


/**
 * Role edit menu pulldown
 * @returns 
 */
export const RoleEditMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  //  Close user edit menu
  const handleRoleEditClick = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <div onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVert className='kabob' titleAccess='Edit User' aria-label='edit user' />
      </div>

      <Menu id="menu-user-edit" anchorEl={anchorEl} keepMounted
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open} onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleRoleEditClick} data-useredit={EditMenuOptions.Add} aria-label="add role">
          Add Role
        </MenuItem>
        <MenuItem onClick={handleRoleEditClick} data-useredit={EditMenuOptions.Edit} aria-label="edit role">
          Edit Role
        </MenuItem>
        <MenuItem onClick={handleRoleEditClick} data-useredit={EditMenuOptions.Delete} aria-label="delete role">
          Delete Role
        </MenuItem>
      </Menu>
    </>
  );
};
