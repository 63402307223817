import { IRoutePath } from 'shared/interfaces/iroute-path';
import { IFeatureFlagEnabled } from 'shared/interfaces/iclient-feature';
import { IVipCompany, IVipUser } from 'redux/profileSlice';
import { ApplicationSection, Permission, UserRole, hasAccess } from 'App/User/user-role';

/**
 * RouteControl Enum declarations
 */
export enum RouteCategory {
  MAIN_PAGE = 'MAIN_PAGE',
  ADMIN_PAGE = 'ADMIN_PAGE',
  ANONYMOUS_PAGE = 'ANONYMOUS_PAGE',
}

/**
 * Route Control page interface
 * @interface
 */
export interface IRouteControlPage {
  routeControlMainPage(params: IRouteControlMainPageParams): boolean;
  routeControlAdminPage(params: IRouteControlAdminPageParams): boolean;
}

/**
 * Route Control Main Page parameters
 * @interface
 */
export interface IRouteControlMainPageParams {
  route: IRoutePath;
  actor: IVipCompany;
  isFeatureFlagEnabled: IFeatureFlagEnabled;
}

/**
 * Route Control Administration Page parameters
 * @interface
 */
export interface IRouteControlAdminPageParams {
  route: IRoutePath;
  user: IVipUser;
}


/**
 * Route Control class for category page
 * @class
 * @public
 */
export class RouteControlPage implements IRouteControlPage {

  /**
   * Route control for the Main Page Layout
   * @param {IRouteControlMainPageParams} params Primary route control function for main page
   * @returns {boolean} is route to be displayed
   */
  routeControlMainPage(params: IRouteControlMainPageParams): boolean {
    const { route, actor, isFeatureFlagEnabled } = params;
    const { isARSFpEnabled, isInsightsEnabled } = isFeatureFlagEnabled;

    let isEnabled = false;

    switch (route.key) {
      case 'insights':
        isEnabled = isInsightsEnabled;
        break;
      case 'ils':
        isEnabled = actor.isILS ?? false;
        break;
      case 'ils_om':   //  special case: don't show for on-prem clients
        isEnabled = (actor.isILS && actor.isHosted) ?? false;
        break;
      case 'ils_r':   //  special case: don't show if client doesn't have re-presentments or on-prem
        isEnabled = (actor.isILS && actor.isRepresentment && actor.isHosted) ?? false;
        break;
      case 'ars':
        isEnabled = (actor.isARS && !!actor.domainId) ?? false;
        break;
      case 'ars_fp':
        isEnabled = (isARSFpEnabled && actor.isARS && actor.isMultiMonthRevenueModel) ?? false;
        break;
      case 'mrpc':
        isEnabled = (actor.isMRPC && !!actor.domainId) ?? false;
        break;
      case 'cp':
        isEnabled = (actor.isCP && !!actor.domainId) ?? false;
        break;
      case 'ics':
        isEnabled = (actor.isICS && !!actor.domainId) ?? false;
        break;
      case 'ois':
        isEnabled = (actor.isOIS && !!actor.domainId) ?? false;
        break;
      case 'odm':
        isEnabled = (actor.isODM && !!actor.domainId) ?? false;
        break;
      default:
        isEnabled =
          (route.path?.indexOf('insights') ?? -1) >= 0 ||
          (route.path?.indexOf('dashboard') ?? -1) >= 0 ||
          (route.path?.indexOf('reports') ?? -1) >= 0 ||
          (route.path?.indexOf('training') ?? -1) >= 0;
    }

    return isEnabled;
  }

  /**
 * Route control for the Administration Page Layout
 * @param {IRouteControlAdminPageParams} params Primary route control function for administration page
 * @returns {boolean} is route to be displayed
 */
  routeControlAdminPage(params: IRouteControlAdminPageParams): boolean {
    const { route, user } = params;

    let isEnabled = false;

    switch (route.key) {
      case 'user-settings':
        isEnabled = hasAccess(user, ApplicationSection.USER_ADMIN, Permission.VIEW);
        break;
      case 'referral-goals':
        isEnabled = hasAccess(user, ApplicationSection.BRANCH_GOAL_ADMIN, Permission.VIEW);
        break;
      case 'client-settings':
        isEnabled = hasAccess(user, ApplicationSection.CLIENT_ADMIN, Permission.VIEW);
        break;
      case 'role-settings':
        isEnabled = user.userRole === UserRole.DEVELOPER;
        break;
      case 'status':
        isEnabled = hasAccess(user, ApplicationSection.STATUS, Permission.VIEW);
        break;
      case 'product':
        isEnabled = hasAccess(user, ApplicationSection.STATUS, Permission.VIEW);
        break;
      case 'configuration':
        isEnabled = user.userRole === UserRole.DEVELOPER;
        break;
      default:
        isEnabled = false;
    }

    return isEnabled;
  }
}
