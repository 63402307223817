import React, { useEffect, useState, MouseEvent, ChangeEvent, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import { useAppSelector } from 'redux/hooks';
import { useLocation } from 'react-router-dom';
import { Alert, Button, Snackbar } from '@mui/material';
import { Add } from '@mui/icons-material';
import { GridRowModel } from '@mui/x-data-grid';
import { FileSegments, IFileHandleConfiguration } from '.';
import { FileService, IDirectoryListing, IDirectoryListingRequest, IFileReadRequest, IFileSetAccessTierRequest } from 'shared/components/FileHandler/file-service';
import { DisplayAllReports } from './DisplayAllReports';
import { DisplayListingDataTable } from './DisplayListingDataTable';
import { ModalYesNo } from 'shared/components/Modal/ModalYesNo';
import { SearchBar } from 'shared/components/SearchBar';
import styled from '@emotion/styled';
import { vlogger } from 'shared/service/logger/vlogger';
import appConstants, { products, StorageStatus } from 'App/app-constants';
import { DisplayListingMenuOptions } from './display-listing-constants';
import { ApplicationSection, hasAccess, Permission } from 'App/User/user-role';


/**
 * Report filename delimiters and parameter locations
 */
const reportFilenameDelimiter = appConstants.reportFilenameDelimiter;
const reportFilenameFormat = appConstants.reportsNameFormat;

/**
 * Styling
 */
const DisplayContainer = styled.div`
  margin: 0rem 1rem;
  min-width: 35rem;   //  TODO:   remove once tablet is defined

  & div#search-bar {
    justify-content: flex-end;

    & .MuiTextField-root {
      min-width: 8rem;
    }
  }

  & div#upload-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & h2 {
      // line-height:0.375;
      margin: 10px 0px;
      white-space: nowrap;
      text-overflow: clip;
      overflow-x: hidden;
      max-width: 720px;
    }

    & label {
      display:flex;
      align-items: center;
      margin-left: 1rem;

      & button {
        white-space: nowrap;
      }
    }

    & hr {
      margin-bottom: 1.25rem;
    }
  }
`;

const FileInput = styled('input')({
  opacity: 0,
  width: '0rem',
});

/**
 * Props passed 
 */
interface IFileDisplay {
  configuration: IFileHandleConfiguration;
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Display existing stored reports
 * @function
 */
export const Display = (props: IFileDisplay) => {
  const { configuration, onInputChange } = props;
  const { instance } = useMsal();
  const { user, actor } = useAppSelector((state) => state.profile);
  const [listing, setListing] = useState<IDirectoryListing[]>([]);
  const [searchText, setSearchText] = useState('');
  const [updateListing, setUpdateListing] = useState<number>(0);
  const [deleteFilename, setDeleteFilename] = useState<string>('');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const inputFile = useRef<HTMLInputElement>(null);
  const acceptFileFormats = appConstants.reportsAcceptFileFormats.join(',');

  //  get current page from url
  const { pathname } = useLocation();
  const currentProduct = pathname.substring(pathname.lastIndexOf('/') + 1).toLowerCase();
  const isAllReports = (currentProduct === 'all');
  const displayProducts = (isAllReports) ? Object.keys(products) : [currentProduct];

  // const isAdmin = isAdminHelper(user);
  let hasUploadAccess = false;
  if (configuration.section === FileSegments.Reports)
    hasUploadAccess = hasAccess(user, ApplicationSection.REPORT, Permission.EDIT);
  else if (configuration.section === FileSegments.Training)
    hasUploadAccess = hasAccess(user, ApplicationSection.TRAINING, Permission.EDIT);


  const productFromFilename = (filename: string): string => {
    const parsedFilename = filename.split(reportFilenameDelimiter);
    const currentProduct = parsedFilename[reportFilenameFormat.product].toLowerCase();
    return currentProduct;
  };

  //  list files for a specific clientId (actor)
  useEffect(() => {
    const fetchDirectory = async () => {
      const request: IDirectoryListingRequest = {
        clientId: actor.clientId,
        applications: Object.keys(products),
        fileSegment: configuration.sectionHeader.toUpperCase(),
      };

      const fileService = new FileService();
      const listing = await fileService.getDirectoryListing(instance, request);
      setListing(listing);
    };

    fetchDirectory();
  }, [actor, updateListing]);

  //  fire upload file request
  const onButtonClick = () => {
    inputFile.current?.click();
  };

  const handleOnClickDisplayListingMenu = async (event: MouseEvent<HTMLDivElement>, rowData: GridRowModel) => {
    const selectedFile = listing.find(l => l.name === rowData.colName) ?? null;
    if (!selectedFile) return;

    const displayListingMenuType = (event.target as HTMLDivElement).dataset.displaylisting;

    if (displayListingMenuType === DisplayListingMenuOptions.Delete) {
      vlogger.debug(`delete file: ${selectedFile.fullpath}`);
      await handleOnClickDeleteFile(event, rowData);
    } else if (displayListingMenuType === DisplayListingMenuOptions.Download) {
      vlogger.debug(`download file: ${selectedFile.fullpath}`);
      await handleOnClickDownloadFile(event, rowData);
    }
  };

  //  fire download file request
  const handleOnClickDownloadFile = async (event: MouseEvent<HTMLElement>, rowData: GridRowModel | null = null) => {
    if (!rowData || !rowData.colName)
      return;

    const filename: string = rowData.colName;
    const currentProduct = productFromFilename(filename);

    const request: IFileReadRequest = {
      clientId: actor.clientId,
      product: currentProduct,
      fileSegment: configuration.sectionHeader.toUpperCase(),
      filename: filename,
    };

    try {
      const fileService = new FileService();
      const result = await fileService.readFile(instance, request);

      if (!result) {
        vlogger.error(`file (${filename}) was not downloaded properly`);
        setSnackbarOpen(true);
        return;
      }

      const blob = new Blob([result]);
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.click();
    }
    catch (e) {
      vlogger.error(`download file: exception: ${JSON.stringify(e)}`);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  //  fire delete file request
  const handleOnClickDeleteFile = (event: MouseEvent<HTMLElement>, rowData: GridRowModel | null = null) => {
    if (!rowData || !rowData.colName)
      return;
    setDeleteFilename(rowData.colName);
    setDeleteConfirmOpen(true);
  };

  // file delete confirmation response
  const handleOnCancelDelete = () => {
    setDeleteConfirmOpen(false);
  };

  const handleOnDeleteFile = async () => {
    setDeleteConfirmOpen(false);

    if (!deleteFilename)
      return;
    const currentProduct = productFromFilename(deleteFilename);

    const request: IFileSetAccessTierRequest = {
      clientId: actor.clientId,
      product: currentProduct,
      fileSegment: configuration.sectionHeader.toUpperCase(),
      filename: deleteFilename,
      storageStatus: StorageStatus.cool,
    };

    try {
      const fileService = new FileService();
      const errorResult = await fileService.setAccessTierFile(instance, request);

      if (errorResult) {
        vlogger.error(`file (${deleteFilename}) access tier was not set properly\nerror message: ${errorResult}`);
        setSnackbarOpen(true);
        return;
      }

      //  force a listing update to keep file storage insync with display
      setUpdateListing(updateListing + 1);
    }
    catch (e) {
      vlogger.error(`set access tier file: exception: ${JSON.stringify(e)}`);
      setSnackbarOpen(true);
    }
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };


  return (
    <DisplayContainer id='display-container'>
      <div id='upload-header'>
        <h2>{(isAllReports) ? 'All' : products[currentProduct]} {configuration.sectionHeader}</h2>

        {!isAllReports &&
          <SearchBar id='search-bar' searchQuery={searchText} setSearchQuery={(e) => handleSearch(e)} />
        }

        {/* {user.isAdmin && */}
        {hasUploadAccess &&
          <label htmlFor="upload-report-files">
            <FileInput type="file" id="upload-report-files" ref={inputFile} multiple
              onChange={onInputChange}
              accept={`${acceptFileFormats}`}
            />

            <Button variant="contained" onClick={onButtonClick} >
              <Add />
              {configuration.uploadHeaderButtonText}
            </Button>
          </label>
        }
      </div>
      <hr className={'bar'} />

      {
        !isAllReports &&
        <DisplayListingDataTable key={'DisplayListingDataTable'}
          listing={listing
            .filter(l => l.directory.includes(displayProducts[0]))
            .filter(l => l.name.toLowerCase().includes(searchText.toLowerCase()))
          }
          // isAdmin={user.isAdmin}
          isAdmin={hasUploadAccess}
          onMenuClick={handleOnClickDisplayListingMenu}
          section={configuration.sectionHeader.toLowerCase()}
        />
      }

      {
        isAllReports &&
        <DisplayAllReports key={'DisplayListingDataTable'} listing={listing}
          // isAdmin={user.isAdmin}
          isAdmin={hasUploadAccess}
          onMenuClick={handleOnClickDisplayListingMenu} section={configuration.sectionHeader.toLowerCase()}
        />
      }

      {/* delete confirmation modal */}
      <ModalYesNo id="modal-delete"
        open={deleteConfirmOpen} className={'yes-no-modal'}
        title={'Confirm Delete'} text={`Are you sure you want to delete ${deleteFilename}?`}
        onYesClick={handleOnDeleteFile}
        onNoClick={handleOnCancelDelete}
      />

      {/* upload success or failure */}
      <Snackbar id={'snackbar-result'} open={snackbarOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert severity='error'>The file selected encountered an error, please refresh the page and try again.</Alert>
      </Snackbar>
    </DisplayContainer>
  );
};
