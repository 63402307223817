import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Autocomplete, TextField, Tooltip } from '@mui/material';
import { IProfile, IVipCompany, userImpersonate } from 'redux/profileSlice';
import styled from '@emotion/styled';
import { vlogger } from 'shared/service/logger/vlogger';


/**
 * Styling
 */
const ClientSelectorDiv = styled.div`
  display:flex;
  margin: 1.125rem;
  white-space: nowrap;
  width: 100%;
`;

/**
 * Client Selector props
 * @interface
 */
export default interface IClientSelectorProps {
  tabIndex?: number;
  isVisible?: boolean;
  isDisabled?: boolean;
  changeHandler?: (event: React.SyntheticEvent<Element, Event>, value: IVipCompany) => void;
}

/**
 * Impersonation (act as) component
 * @returns 
 */
export const ClientSelector = (props: IClientSelectorProps) => {
  const { tabIndex, isVisible, isDisabled, changeHandler } = props;
  const dispatch = useAppDispatch();
  const { user, company, actor } = useAppSelector((state) => state.profile);
  const cpm: IVipCompany[] = useAppSelector((state) => state.app.companies);

  /**
   * onChange event with impersonate dropdown changes value
   * @param event onChange event
   * @param value selected company
   */
  function onChangeValue(event: React.SyntheticEvent<Element, Event>, value: IVipCompany) {
    const payload: IProfile = { user: user, company: company, actor: value };
    dispatch(userImpersonate(payload));
    vlogger.verbose(`user: ${user.displayName} impersonating FI (${value.name})`);
  }

  //  Don't display the Impersonation dropdown if not visible  
  //  (must be defined as false; undefined = true)
  if (isVisible === false) { return null; }

  //  Display dropdown only when cpm has non-default values
  if (cpm.length < 1 || cpm[0].clientId < 0) { return null; }

  return (
    <ClientSelectorDiv id='client-selector'>
      <Tooltip className="tooltip"
        title={`${actor.name}`} placement="top-end" arrow
        disableFocusListener enterDelay={500}
      >
        <Autocomplete id="actor-select" autoComplete autoSelect disableClearable
          tabIndex={tabIndex ?? 0}
          disabled={isDisabled ?? false}
          options={cpm}
          getOptionLabel={(option) => `(${option.clientId})${option.name}`}
          renderInput={(params) =>
            <TextField {...params} variant="outlined" placeholder="Select Client" />
          }
          isOptionEqualToValue={(option, value) => option.clientId === value.clientId}
          onChange={(event, newValue) => { changeHandler ? changeHandler(event, newValue) : onChangeValue(event, newValue); }}
          value={actor}
        />
      </Tooltip>
    </ClientSelectorDiv>
  );
};
