import React, { HTMLAttributes, MouseEvent } from 'react';
import styled from '@emotion/styled';
import appConstants from 'App/app-constants';

/**
 * Styling
 */
const Bar = styled.div`
  height: max(24px, 1.875rem);
  display: flex;
  justify-content: flex-end;
  background-color: var(--header-edit-mode);

  label {
    height: 1.5rem;
    font-size: 1.25rem;
    color: var(--primary-text);
  }

  img {
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 5px;
  }
`;

/**
 * Props type definition
 */
type CloseBarProps = HTMLAttributes<HTMLDivElement> | MouseEvent<HTMLDivElement>;

/**
 * Close bar
 * @param {CloseBarProps} props
 * @returns
 */
export const CloseBar = (props: CloseBarProps) => {
  return (
    <Bar {...props}>
      <label aria-label='close'>Close</label>
      <img alt='close' src={`${appConstants.iconDirectory}/icons-close-big.svg`} />
    </Bar>
  );
};
