import { IPublicClientApplication } from '@azure/msal-browser';
import { HttpService, IHeaders } from 'shared/service/http/http-service';
import { getAccessToken } from 'shared/service/http/token-retriever';
import { vlogger } from 'shared/service/logger/vlogger';
import { GuidHelper } from 'shared/utils/guid-helper';
import { VelocityErrorHelper } from 'shared/utils/velocity-error-helper';


/**
 * Product check request interface
 * @interface
 */
export interface IProductCheckRequest {
  clientId?: number;
}

/**
 * Product check request interface
 * @interface
 */
export interface IProductFlags {
  clientId: number;
  clientName: string;
  domainId?: number;
  srdbCorpId?: number;
  isClientFound: boolean;
  isFedFisFound: boolean;
  hasImportedWithinLastYear: boolean;

  isSRDBActive: boolean;
  arsLegacyDb: string;
  isMultiMonth: boolean;
  isICSProgramStartSet: boolean;

  isILSDbFound: boolean;
  isILSEnabled: boolean;
  ilsProduct: string;
  ilsLicenseProducts: string;
  ilsLicenseFunctions: string;
  isILSHosted: boolean;
  ilsDbName: string;
  ilsCurrentBuild: string;
  isILSLicenseEnabled: boolean;
  isILSProduction: boolean;
}

/**
 * User Settings Service Interface  - use for DI (or DI like)
 * @interface
 */
export interface IProductCheckService {
  getProductChecks(pca: IPublicClientApplication, request: IProductCheckRequest): Promise<IProductFlags[] | null>;
}


/** 
 * Class representing the ProductCheck Service http interface object
 * @class
 * @implements IProductCheckService
 */
export class ProductCheckService implements IProductCheckService {

  /**
   * Check product status
   * @param {IPublicClientApplication} pca authentication service
   * @param {IProductCheckRequest} request  clientId or null
   * @returns {IProductCheckResponse} array of clients product check statuses
   */
  async getProductChecks(pca: IPublicClientApplication, request: IProductCheckRequest): Promise<IProductFlags[] | null> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getAccessToken(pca)}`
    };
    const httpService = new HttpService(vlogger, baseUrl, headers);
    const result =
      await httpService.get<IProductFlags[]>('api/v1/status/productcheck', request, headers, uuid);

    if (result === null) return null;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }

    return result.result;
  }
}
