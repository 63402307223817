import React, { useState, MouseEvent, ChangeEvent } from 'react';
import { Upload } from './Upload';
import { Display } from './Display';

/**
 * FileHandler segment enum
 * @enum
 */
export enum FileSegments {
  Reports,
  Training,
  // All
}

/**
 * Current status of upload request
 * @enum
 */
export enum UploadStatus {
  NotStarted,
  Processing,
  Success,
  Error,
}

/**
 * FileHandler reporting configuration (customization) interface
 * @interface
 */
export interface IFileHandleConfiguration {
  section: FileSegments;
  sectionHeader: string;
  uploadHeaderButtonText: string;
  uploadDownloadButtonText: string;
  uploadPageTitle: string;
}

/**
 * Props passed 
 */
export interface IFileHandler {
  configuration: IFileHandleConfiguration;
}

/**
 * Component for managing the display, upload, download of files 
 * @returns 
 */
export const FileHandler = (props: IFileHandler) => {
  const { configuration } = props;
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const [isUpload, setIsUpload] = useState<boolean>(false);

  /**
   * Event handler for file upload request
   * @event
   * @param event 
   */
  const handleOnInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList: File[] = Array.from(event.currentTarget.files ?? []);
    const combinedList = [...uploadFiles, ...fileList];    //  combine w/ existing upload list

    // Removing Duplicates
    // Retrieve the first index at which the current item can be found within the array and compare 
    // it with the current index for that iteration. 
    // If these values match then this is the first time item occurs within the array => add it to list.
    // If the values do not match this means the item has been encountered before => don't add to list.
    const uniqueList = combinedList.filter((item, index, self) =>
      index === self.findIndex(i => i.name === item.name)
    );

    setUploadFiles(uniqueList);
    setIsUpload(true);
  };

  /**
   * Event handler for file upload cancel
   */
  const handleUploadCancel = (event: MouseEvent<HTMLElement>) => {
    setUploadFiles([]);
    setIsUpload(false);
  };

  /**
   * Event handler for file upload submission
   * @event 
   * @param {MouseEvent<HTMLElement>} event 
   */
  const handleUploadSubmission = (event: MouseEvent<HTMLElement>) => {
    setUploadFiles([]);
    setIsUpload(false);
  };

  return (
    <>
      {(isUpload)
        ? <Upload uploadFiles={uploadFiles} configuration={configuration}
          onClickHandleSubmission={handleUploadSubmission} onClickHandleCancel={handleUploadCancel}
          />
        : <Display configuration={configuration} onInputChange={handleOnInputChange} />
      }
    </>
  );
};
