import React, { HTMLAttributes } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import styled from '@emotion/styled';

/**
 * Styling
 */
const TextboxWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 42px;

  & label {
    height: 20px;
    margin-bottom: 9px;
  }

  & .MuiTextField-root {
    height: 42px;
  }

  & .MuiInputBase-root {
    height: 45px;
    border-radius: 0px;
  }
`;

/**
 * Props type definition
 */
type TextboxLabelProps = HTMLAttributes<HTMLDivElement> &
  TextFieldProps & {
    labelText?: string | undefined;
  };

/**
 * Textbox with Label
 * @param {TextboxLabelProps} props
 * @returns
 */
export const TextboxLabel = (props: TextboxLabelProps) => {
  const newProps = { ...props };
  delete newProps.labelText;

  return (
    <TextboxWithLabel>
      <label>{props.labelText}</label>
      <TextField {...newProps} />
    </TextboxWithLabel>
  );
};
