import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Button, Container, Typography } from '@mui/material';
import { HttpService, IHeaders } from 'shared/service/http/http-service';
import { getAccessToken } from 'shared/service/http/token-retriever';
import { GuidHelper } from 'shared/utils/guid-helper';
import { vlogger } from 'shared/service/logger/vlogger';
import { IClientMappingModel } from 'App/Insights/Report';
import { BusinessResult } from 'shared/model/business-result';
import { VelocityErrorHelper } from 'shared/utils/velocity-error-helper';
import styled from '@emotion/styled';


/**
 * Styles
 */
const ConfigurationPageContainer = styled(Container)`
  padding: 2rem;

  & .lumio {
    display: inline-flex;
    margin: 1rem 2rem;
    align-items: center;

    & button {
      margin: 1rem;
    }
  }
`;


/**
 * Configuration page
 * @returns
 */
export const Configuration = () => {
  const { instance } = useMsal();

  const onClickHandler = async () => {
    //  call backend to update Lumio client configuration map
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getAccessToken(instance)}`
    };
    const httpService = new HttpService(vlogger, baseUrl, headers);

    const result =
      await httpService.get<BusinessResult<IClientMappingModel[]>>('api/v1/lumio/report/updateclientmapping', {}, headers, uuid);

    if (result === null) return `Error in request: ${uuid}`;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }

    return undefined;
  };

  return (
    <ConfigurationPageContainer>
      <Typography variant='h3'>Configuration Page</Typography>

      <Container className="lumio">
        <label>Update Configuration</label>
        <Button variant="outlined" onClick={onClickHandler}>Submit</Button>
      </Container>


    </ConfigurationPageContainer>
  );
};
Configuration.displayName = 'Configuration';
