import React, { ChangeEvent } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import styled from '@emotion/styled';
import appConstants from 'App/app-constants';


const SearchBoxDiv = styled.div`
  .hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

/**
 * Props passed 
 */
interface ISearchBarProps {
  id?: string;
  searchQuery: string;
  setSearchQuery: (event: ChangeEvent<HTMLInputElement>) => void;
}

/**
 * User data grid component
 * @param {ISearchBarProps} props 
 * @returns 
 */
export const SearchBar = (props: ISearchBarProps) => {
  const { id, searchQuery, setSearchQuery } = props;


  return (
    <SearchBoxDiv id={id ?? 'search-box'} className={'search-box'} >
      <label htmlFor='search'><span className='hidden'>Search</span></label>

      <TextField id="search" variant="outlined" placeholder="Search"
        value={searchQuery}
        onInput={setSearchQuery}
        InputProps={{
          type: 'search', startAdornment: (
            <InputAdornment position="start" >
              <img src={`${appConstants.iconDirectory}/icons-search.svg`} alt="search" aria-label="search" />
            </InputAdornment>
          ),
          'aria-label': 'search input field'
        }}
      />
    </SearchBoxDiv>
  );
};
