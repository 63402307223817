import { Outlet } from 'react-router-dom';

/**
 * Administration page
 * @returns
 */
export const Administration = () => {
  return (
    <Outlet />
  );
};
