import React, { MouseEvent } from 'react';
import { useAppSelector } from 'redux/hooks';
import { IVipCompany } from 'redux/profileSlice';
import { Button } from '@mui/material';
import { DataGrid, GridColDef, GridCellParams, GridRowModel } from '@mui/x-data-grid';
import { ErrorOutline, Check } from '@mui/icons-material';
import { IUploadFileModel, UploadStatus } from 'shared/components/FileHandler/file-service';
import { UploadErrorMessage } from 'shared/components/FileHandler/UploadErrorMessage';
import { SortedDescendingIcon, SortedAscendingIcon } from 'shared/components/Datagrid/SortedIcon';
import styled from '@emotion/styled';
import appConstants from 'App/app-constants';
import { vlogger } from 'shared/service/logger/vlogger';


/**
 * Props passed 
 */
interface IUploadDataTableProps {
  listing: IUploadFileModel[];
  onClickRemoveRow: (event: MouseEvent<HTMLElement>, rowdata: GridRowModel) => void;
}

/**
 * Styling
 */
const UploadTableDiv = styled.div`
 & .MuiDataGrid-columnHeaders {
   & .MuiButtonBase-root {
     min-width: 70%;
   }
 }

 //  bold error messages
 & .client-cell {
   display: flex;
   align-items: center;
   font-weight: bold;
   white-space: normal;

   & div {
     display: block;
   }
 }

 //  error class for column status
 & .error {
    color: var(--color-error) !important;
 }

 & div.MuiDataGrid-cell {
    line-height: unset !important;
    // max-height: none !important;
    // white-space: normal;
  }
}
 `;


/**
* Report download data table
* @param {IUploadDataTableProps} props
*/
export const UploadDataTable = (props: IUploadDataTableProps) => {
  const { listing, onClickRemoveRow } = props;
  const companies: IVipCompany[] = useAppSelector((state) => state.app.companies);
  const maxErrors = appConstants.reportsUploadMaxNumberOfErrors;

  vlogger.verbose(`uploadDataTable: listing\n${JSON.stringify(listing)}`);

  //  DataGrid Column definition
  const columns: GridColDef[] = [
    {
      field: 'colStatus', headerName: ' ', align: 'center', width: 30, hideSortIcons: true,
      renderCell: (params: GridCellParams) => {
        const rowData: GridRowModel = params.row;
        const isError = rowData.colError.length > 0;
        const isSuccess = rowData.colStatus === UploadStatus.Success;

        return (
          <>
            {
              (isError)
                ? <ErrorOutline style={{ color: 'var(--color-error)' }} />
                : (isSuccess)
                  ? <Check style={{ color: 'var(--color-success)' }} />
                  : <>{null}</>
            }
          </>
        );
      }
    },
    { field: 'colName', headerName: 'Name', flex: 1, headerAlign: 'left', minWidth: 240, cellClassName: 'center-cell' },
    { field: 'colFormat', headerName: 'Format', headerAlign: 'left', width: 105, cellClassName: 'center-cell' },
    {
      field: 'colClient', headerName: 'Client', flex: 1, headerAlign: 'left', minWidth: 240,
      cellClassName: 'client-cell',
      renderCell: (params: GridCellParams) => {
        const rowData: GridRowModel = params.row;
        const isError = rowData.colError.length > 0;

        return (
          <>
            {
              (isError)
                ? <UploadErrorMessage maxErrors={maxErrors} rowId={rowData.id} errorMessage={rowData.colError} />
                : <div key={`ce${rowData.id}`}>{rowData.colClient}</div>
            }
          </>
        );
      }
    },
    {
      field: 'colRemove', headerName: ' ', minWidth: 130, align: 'center', hideSortIcons: true,
      renderCell: (params: GridCellParams) => {
        return (
          <Button variant="outlined" onClick={(e) => onClickRemoveRow(e, params.row)}>
            Remove
          </Button>
        );
      }
    },
    { field: 'colError' }
  ];


  let index = 1;
  const rows = listing.map(l => {
    const clientCell = companies.find(c => c.clientId === l.clientId)?.name ?? '';

    return {
      id: index++,
      colStatus: l.uploadStatus,
      colName: l.name,
      colFormat: l.format,
      colClient: clientCell,
      colRemove: ' ',
      colError: l.error
    };
  });

  return (
    <UploadTableDiv>
      <DataGrid columns={columns} rows={rows}
        disableColumnMenu
        disableRowSelectionOnClick
        autoHeight
        rowHeight={80}
        hideFooter={true}
        getRowClassName={(params) =>
          // `${params.getValue(params.id, 'colStatus') === UploadStatus.Error ? 'error' : ''}`
          `${params.row.colStatus === UploadStatus.Error ? 'error' : ''}`
        }
        slots={{
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              colError: false,
            }
          }
        }}
      />
    </UploadTableDiv>
  );
};
