import React, { useEffect, useState, MouseEvent } from 'react';
import { useMsal } from '@azure/msal-react';
import { useAppSelector } from 'redux/hooks';
import { Button, LinearProgress } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid';
import { IFileHandleConfiguration } from '.';
import { IUploadFileModel, FileService, UploadStatus } from 'shared/components/FileHandler/file-service';
import { FileChecks } from 'shared/components/FileHandler/file-checks';
import { UploadDataTable } from 'shared/components/FileHandler/UploadDataTable';
import styled from '@emotion/styled';
import { vlogger } from 'shared/service/logger/vlogger';
import appConstants from 'App/app-constants';


/**
 * Styling
 */
const UploadContainerStyle = styled.div`
 margin: 0rem 2rem 0rem 1rem;
 min-width: 60rem;   //  TODO:   remove once tablet is defined

 & h2 {
  line-height:0.375;
 }

 & p {
   font-size: 1.25rem;
 }

 & .MuiLinearProgress-root {
   margin-bottom: -0.5rem;
   background-color: var(--page-background);

   & span {
    background-color: var(--color-primary);
   }
 }

 & hr {
   margin-bottom: 1.25rem;
 }
`;

const UploadButtons = styled.div`
 display: flex;
 justify-content: flex-end;
 flex: 1;
 margin-top: 2.8125rem;

 & #submitUpload {
   margin-left: 1rem;
 }
`;

/**
 * Props passed 
 * @interface
 */
interface IReportUpload {
  uploadFiles: File[];
  configuration: IFileHandleConfiguration;
  onClickHandleCancel?: (event: MouseEvent<HTMLElement>) => void;
  onClickHandleSubmission?: (event: MouseEvent<HTMLElement>) => void;
}


/**
 * Upload to report store
 * @function
 */
export const Upload = (props: IReportUpload) => {
  const { uploadFiles, configuration, onClickHandleCancel, onClickHandleSubmission } = props;
  const { instance } = useMsal();
  const { companies } = useAppSelector((state) => state.app);
  const [list, setList] = useState<IUploadFileModel[]>([]);
  const [isListError, setIsListError] = useState<boolean>(false);
  const [isProgressVisible, setIsProgressVisible] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);


  // create initial list of files to upload
  useEffect(() => {
    const fileList: IUploadFileModel[] = [];
    const clientIds: number[] = companies.map(c => c.clientId);

    // loop over File array
    const filechecks = new FileChecks(clientIds);

    for (let i = 0; i < uploadFiles.length; i++) {
      const fileModel = filechecks.filenameFormat(uploadFiles[i]);
      fileList.push(fileModel);
    }

    setList(fileList);
    vlogger.verbose(`upload: fileList\n${JSON.stringify(fileList)}`);
  }, []);

  //  remove row click handler
  const onClickRemoveRow = (event: MouseEvent<HTMLElement>, rowdata: GridRowModel) => {
    if (rowdata) {
      const newList = list.filter(l => l.name !== rowdata.colName);
      setList(newList);
    }
  };

  //  does the list have any errors
  useEffect(() => {
    const isError = list.find(l => l.error.length > 0);
    setIsListError(!!isError);
  }, [list]);

  //  upload file list
  const onClickHandleUploadSubmission = async (event: MouseEvent<HTMLElement>) => {
    let isUploadErrorFound = false;
    setIsProgressVisible(true);

    try {
      //  loop through uploads
      for (let i = 0; i < list.length; i++) {
        setProgress((i / list.length) * 100);
        list[i].uploadStatus = UploadStatus.Processing;

        const data = new FormData();
        data.append('ClientId', `${list[i].clientId}`);
        data.append('Product', list[i].product);
        data.append('FileSegment', configuration.sectionHeader);
        data.append('File', list[i].file as Blob);

        const fileService = new FileService();
        const result = await fileService.uploadListing(instance, data);

        if (!result) {
          // success
          list[i].uploadStatus = UploadStatus.Success;
        } else {
          // failure
          isUploadErrorFound = true;
          list[i].uploadStatus = UploadStatus.Error;

          if (result.indexOf('virus') >= 0) {
            list[i].error = [`${appConstants.reportsErrorMessages['virusFound']}`];
          } else {
            list[i].error = [result];
          }
        }
      }
    }
    catch (e) {
      vlogger.error(`Upload: exception: ${JSON.stringify(e)}`);
    }
    finally {
      setIsProgressVisible(false);
      setProgress(0);
    }

    if (!isUploadErrorFound && !!onClickHandleSubmission)
      onClickHandleSubmission(event);
  };


  return (
    <UploadContainerStyle id='upload-container'>
      <h2>{configuration.uploadPageTitle}</h2>
      <p>Please review uploaded material to ensure complete and correct information</p>

      <LinearProgress variant="determinate" value={progress} className={(isProgressVisible) ? 'visible' : 'hidden'} />
      <hr className={'bar'} />

      <UploadDataTable key={'UploadDataTable'} listing={list} onClickRemoveRow={onClickRemoveRow} />

      <UploadButtons>
        <Button id="cancelUpload" variant="outlined" onClick={onClickHandleCancel}>Cancel</Button>
        <Button id="submitUpload" variant="contained" onClick={onClickHandleUploadSubmission}
          disabled={isListError ? true : false}
        >{configuration.uploadDownloadButtonText}
        </Button>
      </UploadButtons>
    </UploadContainerStyle >
  );
};
