import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { DisplayListingDataTable, IDisplayListingDataTableProps } from './DisplayListingDataTable';
import { IDirectoryListing } from './file-service';
import { HtmlSpan } from 'shared/components/HtmlSpan';
import { ArrowForward } from '@mui/icons-material';
import { products, productsPromotion } from 'App/app-constants';
import styled from '@emotion/styled';


//  number of licensed product listings to display
const numberToList = 5;

/**
 * Listing display interface
 * @interface
 */
interface IListingObject {
  name: string;
  product: string;
  isLicensed: boolean;
  listing: IDirectoryListing[];
}


/**
 * Props passed 
 */
type IDisplayAllReportsProps = IDisplayListingDataTableProps;

/**
 * Styling
 */
const DisplayAllReportsDiv = styled.div`
  & .MuiDataGrid-root {
    min-height: unset;
  }
 `;

const DisplayLicensedProductDiv = styled.div`
  margin-bottom: 2.5rem;
 `;

const DisplayPromoBannerDiv = styled.div`
  color: var(--primary-text);
  background-color: var(--color-primary);
  padding: 1.25rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
  content-visibility: auto;

  & .promo-title {
    font-size: 1.25rem;
    font-weight: 600;
    white-space: nowrap;
    margin-right: 1.5rem;
  }

  & .promo-link {
    color: var(--primary-text);
    width: 7.5rem;
    margin-left: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .learn-more {
    margin-right: 10px;
  }
 `;

/**
 * Display all reports for licensed products
 * @param {IDisplayAllReportsProps} props 
 */
export const DisplayAllReports = (props: IDisplayAllReportsProps) => {
  const { listing, isAdmin, section, onMenuClick } = props;
  const { actor } = useAppSelector((state) => state.profile);
  const [licensedProducts, setLicensedProducts] = useState<IListingObject[]>([]);
  const [promoProducts, setPromoProducts] = useState<IListingObject[]>([]);


  //  bucket listing by product
  useEffect(() => {
    const licensedProd: IListingObject[] = [];
    const unLicensedProd: IListingObject[] = [];

    const productKeys = Object.keys(products);
    productKeys.forEach((value, index) => {

      //  licensed
      const entries = Object.entries(actor);
      const licenseKey = `is${value.toUpperCase()}`;
      const licenseKVP = entries.find(e => e[0] === licenseKey);

      const o: IListingObject = {
        name: products[value],
        product: value,
        isLicensed: (licenseKVP) ? licenseKVP[1] : false,
        listing: listing.filter(l => l.directory.includes(value)),
      };


      if (o.isLicensed) {
        licensedProd.push(o);
      } else {
        unLicensedProd.push(o);
      }
    });


    setLicensedProducts(licensedProd);
    setPromoProducts(unLicensedProd);
  }, [listing]);


  return (
    <DisplayAllReportsDiv id='display-all-reports'>
      {licensedProducts.map((item, index) => {
        const itemName = item.name.replace(' ', '');

        return (
          <DisplayLicensedProductDiv key={`LicensedProducts${item.product}`}>
            {(index > 0) && <hr className={'bar'} key={`hr${itemName}`} />}

            <h4 key={`subheader${itemName}`}>{item.name}</h4>

            <DisplayListingDataTable key={`LicensedProducts${itemName}`}
              listing={item.listing} isAdmin={isAdmin} numberToDisplay={numberToList}
              onMenuClick={onMenuClick} section={section}
            />
          </DisplayLicensedProductDiv>
        );
      })
      }

      {promoProducts.map((item) => {
        const pp = productsPromotion[item.product];
        const itemName = item.name.replace(' ', '');

        return (
          <DisplayPromoBannerDiv key={`PromoProducts${itemName}`}>
            <HtmlSpan innerHtml={item.name} className='promo-title' key={`title${itemName}`} />

            <HtmlSpan innerHtml={pp.promoLine} className='promo-tagline' key={`tagline${itemName}`} />

            <a href={pp.learnMoreLink} className='promo-link text-link' key={`link${itemName}`}>
              <HtmlSpan innerHtml={pp.learnMoreLinkText} className={'learn-more'} key={`linkSpan${itemName}`} />
              <ArrowForward />
            </a>

          </DisplayPromoBannerDiv>
        );
      })
      }

    </DisplayAllReportsDiv>
  );
};
