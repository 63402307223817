import { FileHandler, FileSegments, IFileHandleConfiguration } from 'shared/components/FileHandler';
import styled from '@emotion/styled';

const TrainingContainer = styled.div`
`;

/**
 * Display existing stored reports
 * @function
 */
export const Training = () => {

  const TrainingConfiguration: IFileHandleConfiguration = {
    section: FileSegments.Training,
    sectionHeader: 'Training',
    uploadHeaderButtonText: 'Upload Training',
    uploadDownloadButtonText: 'Upload Training',
    uploadPageTitle: 'Upload Training',
  };

  return (
    <TrainingContainer id='training-container'>
      <FileHandler configuration={TrainingConfiguration} />
    </TrainingContainer>
  );
};
