import React, { MouseEvent } from 'react';
import { GridFooter, GridFooterContainer, GridSlotsComponentsProps } from '@mui/x-data-grid';
import { Button } from '@mui/material';


/**
 * Props passed 
 */
interface IBranchGoalDataTableFooterProps {
  isDisabled: boolean;
  canSave: boolean;
  handleSaveClick: (event: MouseEvent<HTMLButtonElement>) => void;
  handleCancelClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides extends IBranchGoalDataTableFooterProps { }
}

/**
 * Branch Goal data grid FOOTER component
 * @param {IBranchGoalDataTableProps} props 
 * @returns 
 */
export const BranchGoalDataTableFooter = (
  props: NonNullable<GridSlotsComponentsProps['footer']>
) => {
  const { isDisabled, canSave, handleSaveClick, handleCancelClick } = props;


  return (
    <GridFooterContainer>
      <GridFooter sx={{
        border: 'none', // To delete double border.
      }}
      />

      <div className={`${canSave ? 'display-flex button-box' : 'invisible'}`}>
        <Button id='cancel-branch-goal' variant="outlined" onClick={(e) => handleCancelClick!(e)} >
          Cancel
        </Button>

        <Button id='save-branch-goal' variant="contained" disabled={isDisabled} onClick={(e) => handleSaveClick!(e)} >
          Save
        </Button>
      </div>
    </GridFooterContainer>
  );
};
