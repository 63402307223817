import React, { useCallback, useEffect } from 'react';
import { IDashboardRequest } from 'App/Dashboards/dashboard-request';
import { IDictionary } from 'shared/interfaces/idictionary';
import { useAppSelector } from 'redux/hooks';
import { SSOUrlGenerator } from 'shared/looker/sso-url-generator';
import { CardMedia } from '@mui/material';
import { useVipContext } from 'shared/hooks/useVipContext';
import { usePathname } from 'shared/hooks/usePathname';
import { StringHelper } from 'shared/utils/string-helper';
import { DefaultUserPermissions } from './looker-constants';
import { HashHelper } from 'shared/utils/hash-helper';
import { vlogger } from 'shared/service/logger/vlogger';
import appConstants from 'App/app-constants';
import styled from '@emotion/styled';

const external_user_id_hash_length = 10;

//  TODO:  check if scrollIntoView will work for Looker dashboard popups
//  https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView

/**
 * Styling
 */
const LookerContainer = styled(CardMedia)`
width: 100%;
display: flex;
overflow:hidden;
margin: 0;
padding: 0;

  & iframe {
    border: 0px;
    min-width: 100%;
    height: 1000px;
    top: 0px;
    left: 0px;
  }
`;

/**
 * Display looker dashboard
 * @function
 */
export const Dashboard = () => {
  const { flatRoutes } = useVipContext();
  const { user, company, actor } = useAppSelector((state) => state.profile);

  const emailDomain = user.email.split('@')[1];
  const userAttributes: IDictionary<string, string> = {
    'email': user.email,
    'client_id': `${actor.clientId}`,
    'domain_id': `${actor.domainId}`,
    // 'looker_internal_email_domain_allowlist': `${emailDomain},myvelocity.com`
    // 'looker_internal_email_domain_allowlist': 'myvelocity.com'
  };

  //  get route segment
  const lastLoc = usePathname().slice(-1)[0].toLowerCase();
  const thisRoute = flatRoutes.find(r => StringHelper.SpaceAndSpecialCharacterRemover(r.title?.toLowerCase()) === lastLoc);

  vlogger.verbose(`Dashboard: name: ${lastLoc}`);
  vlogger.verbose(`Dashboard: thisRoute: ${thisRoute?.displayName}, ${thisRoute?.path}`);

  //  create external user_id
  const hash_str = `${JSON.stringify(userAttributes)}_` +
    `${JSON.stringify(DefaultUserPermissions)}_` +
    `${JSON.stringify(thisRoute?.model || '')}`;
  const externalUserIdHash = HashHelper.CreateHashCode(hash_str);
  const externalUserId: string =
    `${company.clientId}_${user.email.substring(0, user.email.indexOf('@'))}_` +
    externalUserIdHash.substring(0, external_user_id_hash_length - 1);
  // const externalUserId: string =
  //   `${company.clientId}_${user.email.substring(0, user.email.indexOf('@'))}`;

  vlogger.verbose(`Dashboard: externalUserId: ${externalUserId}`);

  //  determine Looker dashboard id
  const dashboardId = (!actor.isHosted && thisRoute?.onPremId) ? thisRoute.onPremId : thisRoute?.id;

  //  dashboard requests / event handling
  const request: IDashboardRequest = {
    title: 'Dashboard',
    url: `/dashboards-next/${dashboardId || ''}`,
    first_name: user.firstName,
    last_name: user.lastName,
    user_attributes: userAttributes,
    group_id: [2],
    external_user_id: `${externalUserId}`,
    external_group_id: `${company.clientId}`,
    userPermissions: DefaultUserPermissions,
    models: [thisRoute?.model ?? ''],
  };

  const handleLookerPropertyChangeEvent = useCallback((event: MessageEvent) => {
    const iframe = document.getElementById('looker-iframe') as HTMLIFrameElement | null;
    if (iframe && event.source === iframe.contentWindow
      && event.origin === `https://${import.meta.env.VITE_APP_LOOKER_EMBED_HOST}`) {

      const lookerEvent = JSON.parse(event.data);
      vlogger.verbose(`lookerEvent: ${JSON.stringify(lookerEvent)}`);

      if (lookerEvent.type === 'page:properties:changed') {
        vlogger.verbose(`iFrameHeight: ${lookerEvent.height}`);

        const iid = document.getElementById('looker-iframe');
        if (iid)
          iid.style.height = `${Math.max(lookerEvent.height, appConstants.dashboard_min_height)}px`;
      } else if (lookerEvent.type === 'env:client:dialog') {
        vlogger.verbose(`dialog opened: dialogType: ${lookerEvent.dialogType}`);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleLookerPropertyChangeEvent);
    return () => {
      window.removeEventListener('message', handleLookerPropertyChangeEvent);
    };
  }, [handleLookerPropertyChangeEvent]);

  const ssoUrlGenerator = new SSOUrlGenerator();
  vlogger.verbose(`iframe src: ${JSON.stringify(ssoUrlGenerator.get_url(request))}`);

  return (
    <>
      {/*  Check for valid user (if invalid userId return empty page)   */}
      {((user.userId ?? 0) < 0)
        ? <>{null}</>
        :
        <LookerContainer className={'looker-iframe-container'} key={`lic-${lastLoc}`} >
          <iframe id="looker-iframe" src={ssoUrlGenerator.get_url(request)}
            title={'dashboard'} aria-label={'dashboard'}
          />
        </LookerContainer>
      }
    </>
  );
};
Dashboard.displayName = 'Dashboard';
