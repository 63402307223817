import { VelocityError } from './velocity-error';

/* istanbul ignore file */

/**
 * BusinessResult class that holds result or error
 * @class
 */
export class BusinessResult<T> {
  result: T = <T>(<unknown>null);
  errors = Array<VelocityError>();
  operationId = '';
}
