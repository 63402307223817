import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { IconButton } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import appConstants from 'App/app-constants';
import styled from '@emotion/styled';


/**
 * Styling
 */
const DisplayUserDiv = styled.div`
  & img {
    height: 30px;
  }

  & label {
    // margin: 0 0.5rem 0 0;
    font-family: 'WhitneySSM', sans-serif;;
    font-weight: bold;
    font-size: 1.25rem;
    text-align: right;
    color: var(--color-secondary);
    vertical-align: middle;
  }

  & .chevron-down {
    height: 2rem;
    width: 2rem;
    color: var(--color-secondary);
    margin-top: 0px;
  }
`;

/**
 * Display User component
 * @returns 
 */
export const DisplayUser = () => {
  const user = useAppSelector((state) => state.profile.user);
  const [displayName, setDisplayName] = useState<string>('');

  useEffect(() => {
    if (user)
      setDisplayName(user.displayName ?? 'Demo User');
  }, [user]);

  return (
    <DisplayUserDiv>
      <IconButton aria-label="user icon" color="inherit" size="large">
        <img src={`${appConstants.iconDirectory}/icons-user.svg`} alt="user icon" aria-label="user icon" />
      </IconButton>
      <label>{displayName}</label>
      <ExpandMore className='chevron-down' aria-label='user menu' />
    </DisplayUserDiv>
  );
};
