import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { vlogger } from 'shared/service/logger/vlogger';

//  https://www.npmjs.com/package/react-error-boundary

/**
 * Fallback component on error boundary
 * @param {FallbackProps}
 */
export const ErrorFallback = (props: FallbackProps) => {
  const { error, resetErrorBoundary } = props;

  vlogger.error('Error Boundary - Something went wrong:\n' +
    `name: ${error.name}\n` +
    `msg: ${error.message}`);
  vlogger.debug(`stack:${error.stack}`);


  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.name}:{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};
