import React, { useEffect, useRef } from 'react';
import { Container } from '@mui/material';
import styled from '@emotion/styled';
import appConstants from 'App/app-constants';

/**
 * Styles
 */
const HomePageContainer = styled(Container)`
  // padding: 2rem;
  padding: 0rem !important;
  margin-left: -0rem;
  margin-right: -1rem;

  & .image {
    background-size: cover;
    min-height: 445px;
    // height: 555px;
    width: 100%;
    padding: 0;
    position: relative;
  }

  & #tag {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;
      color: var(--primary-text);
      width: 80%;
    }

  & p {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 1rem 9rem;
  }
 `;


/**
 * Home page
 * @returns
 */
export const Home = () => {
  return (
    <HomePageContainer id='home-page' maxWidth={false}>
      <div className='image' style={{
        backgroundImage: `url(${appConstants.imageDirectory}/home-vip.jpg)`
      }}
      >
        <h1 id='tag'>Welcome to Velocity Solutions&apos;<br />VIP website.</h1>
      </div>

      <p>
        Please look around and let us know what you think. Updates are happening
        frequently so come back regularly to see our new features.
      </p>
      <br /> <br />
    </HomePageContainer >
  );
};
Home.displayName = 'Home';
