import React, { useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useAppSelector } from 'redux/hooks';
import { useNavigate } from 'react-router-dom';
import { DisplayUser } from 'App/User/DisplayUser';
import { Menu, MenuItem } from '@mui/material';
import { ApplicationSection, hasAccess, Permission } from 'App/User/user-role';
import styled from '@emotion/styled';


const HeaderUser = styled.div`
  text-align: right;
  width: 325px;
  position: absolute;
  right: 0px;
  margin-top:5px;
  padding: 15px 35.6px 15px 0px;
`;

/**
 * Username menu pulldown
 * @returns 
 */
export const UserMenu = () => {
  const user = useAppSelector((state) => state.profile.user);
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleLogout = () => {
    setAnchorEl(null);
    instance.logoutRedirect();
  };

  // const handleAbout = () => {
  //   setAnchorEl(null);
  // };

  const handleAdmin = () => {
    navigate('/administration/user-settings');
    setAnchorEl(null);
  };

  //  check access to UserAdmin section
  const isUserAdmin = hasAccess(user, ApplicationSection.USER_ADMIN, Permission.VIEW);

  if (isAuthenticated) {
    return (
      <>
        <HeaderUser id="header-user" onClick={(e) => setAnchorEl(e.currentTarget)}>
          {user.displayName &&
            <DisplayUser />
          }
        </HeaderUser>
        <Menu id="menu-appbar" anchorEl={anchorEl} keepMounted
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open} onClose={() => setAnchorEl(null)}
        >
          {/* <MenuItem onClick={handleAbout}>About</MenuItem> */}

          {isUserAdmin &&
            <MenuItem onClick={handleAdmin}>Administration</MenuItem>
          }
          <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
        </Menu>
      </>
    );
  } else {
    return (<>{null}</>);
  }
};
