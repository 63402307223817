import React, { useEffect } from 'react';
import { GaOptions } from 'react-ga4/types/ga4';
import ReactGA from 'react-ga4';
import gaConstants from './ga-constants';


/**
 * Props passed 
 */
export interface IGAnalytics {
  pathname: string;
  userId?: number;
}

/**
 * GAnalytics component
 * @param { IGAnalytics } props  - component props
 * @see  https://github.com/codler/react-ga4
 */
export const GAnalytics = (props: IGAnalytics) => {
  const { pathname, userId } = props;

  useEffect(() => {
    //  Don't initialize GA until user has been identified
    if (!!userId && userId > 0) {
      const gaOptions: GaOptions = {
        userId: `${userId}`,
        anonymizeIp: false,
      };
      const gaTagOptions = {
        // 'debug_mode': true
      };
      ReactGA.initialize(gaConstants.measurementId, { gaOptions: gaOptions, gtagOptions: gaTagOptions, testMode: gaConstants.testMode });
    }
  }, [userId]);

  useEffect(() => {
    const title = pathname.split('/').pop();    //  not sure this will pass the title correctly
    ReactGA.send({ hitType: 'pageview', page: `${pathname}`, title: `${title ? title : 'Home'}` });
  }, [pathname]);

  return (
    <div className='invisible' />
  );
};
