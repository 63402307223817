import { IPublicClientApplication } from '@azure/msal-browser';
import { getAccessToken } from 'shared/service/http/token-retriever';
import { Page } from 'powerbi-client';
import { HttpService, IHeaders, IProblemDetails } from './httpservice';
import { HttpService as ApiHttpService } from 'shared/service/http/http-service';
import { EmbedToken } from './ApiModels/EmbedToken';
import { GenerateTokenRequest } from './ApiModels/GenerateTokenRequest';
import { TokenAccessLevel } from './ApiModels/TokenAccessLevel';
import { IClientMappingModel } from './Report';
import { vlogger } from 'shared/service/logger/vlogger';
import { GuidHelper } from 'shared/utils/guid-helper';
import { VelocityErrorHelper } from 'shared/utils/velocity-error-helper';

/*
 *   PowerBi Constants
 */
const apiBaseUrl = 'https://api.powerbi.com/v1.0/myorg';

/**
 * Lumio PowerBi instance access token results
 * @interface
 */
export interface ITokenResult {
  access_token: string;
  token_type: string;
  expires_on: Date;
  scopes: string[];
}

/**
 * Report in group details
 * @interface
 */
export interface IReportInGroup {
  id: string;
  name: string;
  description?: string;
  embedUrl: string;
  webUrl: string;
  reportType?: string;
  appid?: string;
  datasetId: string;
}

interface IODataResponse<T> {
  value: T;
}


/**
 * Report Service Interface  - use for DI (or DI like)
 * @interface
 */
export interface IReportService {
  getReportId(clientMapping: IClientMappingModel[], clientId: number): string;
  getWorkspaceId(clientMapping: IClientMappingModel[], clientId: number): string;
  getPowerBiAccessToken(pca: IPublicClientApplication): Promise<string>;
  getPowerBiEmbedToken(workspaceId: string, reportId: string, access_token: string): Promise<string>;
  getEmbedUrl(workspaceId: string, reportId: string, accessToken: string): Promise<string>;
  getPowerBiPagesInGroup(workspaceId: string, reportId: string, accessToken: string): Promise<Page[] | undefined>;
  getPagesForClient(clientId: number, instance: IPublicClientApplication): Promise<Page[] | undefined>;

}

/** 
 * Class representing the Report Service http interface object
 * @class
 * @implements IReportService
 */
export class ReportService implements IReportService {

  /**
   * Get the first report id for a given clientId from client mapping array
   * @param {IClientMappingModel[]} clientMapping Lumio client mapping array
   * @param {number} clientId 
   * @returns {string}
   */
  getReportId(clientMapping: IClientMappingModel[], clientId: number): string {
    const client = clientMapping?.find(e => e.institutionId === clientId);
    const content = client?.contents.find(c => c.type === 'report');
    return content?.id ?? '';
  }

  /**
   * Get workspace id for given clientId from client mapping array
   * @param {IClientMappingModel[]}  clientMapping Lumio client mapping array
   * @param {number} clientId 
   * @returns {string}
   */
  getWorkspaceId(clientMapping: IClientMappingModel[], clientId: number): string {
    const client = clientMapping?.find(e => e.institutionId === clientId);
    return client?.workspaceId ?? '';
  }

  /**
   * Get client mapping array from VIP operational db
   * @param {IPublicClientApplication} instance MSAL public client
   * @returns {Promise<IClientMappingModel[] | null>}
   */
  async getClientMapping(instance: IPublicClientApplication): Promise<IClientMappingModel[] | null> {
    //  call backend to update Lumio client configuration map
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getAccessToken(instance)}`
    };
    const httpService = new ApiHttpService(vlogger, baseUrl, headers);

    const result =
      await httpService.get<IClientMappingModel[]>('api/v1/lumio/report/getclientmapping', {}, headers, uuid);

    if (result === null) {
      vlogger.informational(`Error in request: ${uuid}`);
      return null;
    }
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }

    return result.result;
  }

  /**
   * Get PowerBi access token for PowerBI API
   * @param {IPublicClientApplication} pca MSAL public client
   * @returns {Promise<string>} powerbi embed access token
   */
  async getPowerBiAccessToken(pca: IPublicClientApplication): Promise<string> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getAccessToken(pca)}`,
    };
    const httpService = new ApiHttpService(vlogger, baseUrl);

    const result =
      await httpService.get<ITokenResult>('api/v1/lumio/report/getaccesstoken', {}, headers, uuid);

    if (result === null) return '';
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
      return '';
    }

    const accessToken = result.result?.access_token;
    vlogger.verbose(`powerbi token: ${accessToken}`);
    return accessToken ?? '';
  }

  /**
   * Get PowerBi embed token for embedding charts
   * @param {string} workspaceId PowerBi workspace id (unique to each client)
   * @param {string} reportId PowerBi report id (unique to each report)
   * @param {string} accessToken PowerBi API access token
   * @returns {Promise<string>}
   * @remarks This token allows charts to be embedded (vs access token to access PowerBi API)
   */
  async getPowerBiEmbedToken(workspaceId: string, reportId: string, accessToken: string): Promise<string> {
    if (!workspaceId || !reportId) return '';

    const httpService = new HttpService(apiBaseUrl);

    const uuid = GuidHelper.NewGuid();
    const headers: IHeaders = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${accessToken}`,
    };

    const request: GenerateTokenRequest = {
      accessLevel: TokenAccessLevel.View,     //  This may not be necessary
      lifetimeInMinutes: 600,
    };
    const result: EmbedToken | IProblemDetails =
      await httpService.post(`/groups/${workspaceId}/reports/${reportId}/GenerateToken`, request, headers, uuid);

    if ('token' in result) {
      const et = result as EmbedToken;
      return et.token;
    } else {
      const pd = result as IProblemDetails;
      const message = `statuscode: ${pd.statusCode}, title: ${pd.title}, details: ${pd.details}`;
      vlogger.error(message);
    }
    return '';
  }


  /**
   * Returns list of pages within the specified workspace and report
   * @param {string} workspaceId 
   * @param {string} reportId 
   * @param {string} accessToken PowerBi access token
   * @returns {Promise<Page[]|undefined>}
   */
  async getPowerBiPagesInGroup(workspaceId: string, reportId: string, accessToken: string): Promise<Page[] | undefined> {
    if (!workspaceId || !reportId) return;

    const httpService = new HttpService(apiBaseUrl);

    const uuid = GuidHelper.NewGuid();
    const headers: IHeaders = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${accessToken}`,
    };

    const result: IODataResponse<Page[]> | IProblemDetails =
      await httpService.get(`/groups/${workspaceId}/reports/${reportId}/pages`, {}, headers, uuid);

    if (result === null) return [];
    if ('value' in result) {
      const response = result as IODataResponse<Page[]>;
      return response.value;
    } else {
      const pd = result as IProblemDetails;
      const message = `statuscode: ${pd.statusCode}, title: ${pd.title}, details: ${pd.details}`;
      vlogger.error(message);
    }

    return [];
  }

  /**
   * Get url to embed PowerBI report
   * @param {string} workspaceId PowerBi workspace id (unique to each client)
   * @param {string} reportId PowerBi report id (unique to each report)
   * @param {string} accessToken PowerBi API access token
   * @returns {Promise<string>}
   */
  async getEmbedUrl(workspaceId: string, reportId: string, accessToken: string): Promise<string> {
    if (!workspaceId || !reportId) return '';

    const httpService = new HttpService(apiBaseUrl);

    const uuid = GuidHelper.NewGuid();
    const headers: IHeaders = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${accessToken}`,
    };
    const params = { 'accessLevel': 'View' };

    const result =
      await httpService.get<IODataResponse<IReportInGroup[]>>(`/groups/${workspaceId}/reports`, params, headers, uuid);

    if (result === null) return '';
    if ('value' in result) {
      const response = result as IODataResponse<IReportInGroup[]>;
      const r = response.value.find(r => r.id === reportId);
      return r?.embedUrl ?? '';
    } else {
      const pd = result as IProblemDetails;
      const message = `statuscode: ${pd.statusCode}, title: ${pd.title}, details: ${pd.details}`;
      vlogger.error(message);
    }
    return '';
  }

  /**
   * Returns PowerBi report pages for given client id
   * @param clientId 
   * @param {IPublicClientApplication} instance MSAL public client
   * @returns {Promise<Page[] | undefined>}
   */
  async getPagesForClient(clientId: number, instance: IPublicClientApplication): Promise<Page[] | undefined> {
    const powerbiAccessToken = await this.getPowerBiAccessToken(instance);
    const clientMapping = await this.getClientMapping(instance);

    if (clientMapping) {
      const workspaceId = this.getWorkspaceId(clientMapping, clientId);
      const reportId = this.getReportId(clientMapping, clientId);
      const pages = await this.getPowerBiPagesInGroup(workspaceId, reportId, powerbiAccessToken);
      return pages;
    }

    return undefined;
  }
}
