import React, { Fragment, useEffect, useState } from 'react';
import { HtmlSpan } from 'shared/components/HtmlSpan';
import appConstants from 'App/app-constants';

/**
 * Props passed 
 */
export interface IUploadErrorMessageProps {
  maxErrors: number;
  rowId: number;
  errorMessage: string[];
}

/**
 * Upload Error Message display in client column
 * @param {IUploadErrorMessageProps} props 
 */
export const UploadErrorMessage = (props: IUploadErrorMessageProps) => {
  const { maxErrors, rowId, errorMessage } = props;
  const [displayErrors, setDisplayErrors] = useState<string[]>([]);

  useEffect(() => {
    const n = Math.min(maxErrors, errorMessage.length);
    const de = errorMessage.slice(0, n);
    setDisplayErrors(de);
  }, []);

  return (
    <div id={`ce${rowId}errors`} key={`ce${rowId}`}>
      {displayErrors.map((e: string, index: number) => {
        return (
          <Fragment key={`ce${rowId}${index}f`}>
            <HtmlSpan key={`ce${rowId}${index}`} innerHtml={e} />
            <br key={`ce${rowId}${index}br`} />
          </Fragment>
        );
      }
      )}
      <HtmlSpan key={`ce${rowId}format`} innerHtml={appConstants.reportsAcceptedFilenaming} />
    </div>
  );
};
