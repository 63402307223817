import styled from '@emotion/styled';

/**
 * Styling
 */
const LabelWithErrorSpan = styled.span`
  & .error {
    font-weight: bold;
    color: var(--color-error);
  }
`;

/**
 * Label with error props
 * @interface
 * @param {string | undefined} id
 * @param {string | undefined} className
 * @param {string} message label message
 * @param {string} value  display value
 * @param {boolean} isError display value as error when true
 */
export interface LabelWithErrorProps {
  id?: string;
  className?: string;
  message: string;
  value: string;
  isError: boolean;
}

/**
 * Label with error
 * @returns
 */
export const LabelWithError = (props: LabelWithErrorProps) => {
  const { id, className, message, value, isError } = props;

  return (
    <LabelWithErrorSpan id={id} className={`${className ? className : ''}`}>
      <label>{message}</label>
      <label className={`${isError ? 'error' : ''}`}>{value}</label>
    </LabelWithErrorSpan>
  );
};
