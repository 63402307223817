import { IDashboardRequest } from 'App/Dashboards/dashboard-request';
import * as forge from 'node-forge';
import { UrlHelper } from 'shared/utils/url-helper';
import environment from '../environment';
import appConstants from 'App/app-constants';

export class SSOUrlGenerator {

  /**
   * Generate Looker SSO request url
   * @param request contains the dashboard request parameters
   * @returns Looker SSO request url
   */
  get_url(request: IDashboardRequest) {
    //  const embed domain string
    //    required for Looker to emit Javascript message events
    //    https://docs.looker.com/reference/embedding/sso-embed
    const embed_domain_string =
      (environment.LookerEmbedDomain)
        ? `?embed_domain=${encodeURIComponent(UrlHelper.NoTrailingSlash(environment.LookerEmbedDomain))}`
        : '';

    const url_data = {
      host: environment.LookerEmbedHost,
      secret: environment.LookerEmbedSecret,
      external_user_id: request.external_user_id,
      first_name: request.first_name,
      last_name: request.last_name,
      group_ids: request.group_id,
      external_group_id: request.external_group_id,
      permissions: request.userPermissions,
      models: request.models,
      // access_filters: {},
      user_attributes: request.user_attributes,
      session_length: appConstants.session_length,
      embed_url: UrlHelper.AppendUrl('/embed/', `${request.url}${embed_domain_string}`),
      force_logout_login: true  // Note:  when false then there is intermittent dashboard loading issues
    };

    return 'https://' + this.created_signed_embed_url(url_data);
  }

  /**
   * Generate the SSO url signature
   * @param options 
   * @returns 
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private created_signed_embed_url(options: any) {

    const host = options.host;
    const secret = options.secret;

    // user options
    const json_external_user_id = JSON.stringify(options.external_user_id);
    const json_first_name = JSON.stringify(options.first_name);
    const json_last_name = JSON.stringify(options.last_name);
    const json_permissions = JSON.stringify(options.permissions);
    const json_models = JSON.stringify(options.models);
    const json_group_ids = JSON.stringify(options.group_ids);
    const json_external_group_id = JSON.stringify(options.external_group_id || '');
    const json_user_attributes = JSON.stringify(options.user_attributes || {});
    const json_access_filters = JSON.stringify(options.access_filters || {});

    // url/session specific options
    const embed_path = UrlHelper.AppendUrl('/login/embed/', encodeURIComponent(options.embed_url));
    const json_session_length = JSON.stringify(options.session_length);
    const json_force_logout_login = JSON.stringify(options.force_logout_login);

    // computed options
    const json_time = JSON.stringify(Math.floor((new Date()).getTime() / 1000));
    const json_nonce = JSON.stringify(this.nonce(16));

    // compute signature
    let string_to_sign = '';
    string_to_sign += host + '\n';
    string_to_sign += embed_path + '\n';
    string_to_sign += json_nonce + '\n';
    string_to_sign += json_time + '\n';
    string_to_sign += json_session_length + '\n';
    string_to_sign += json_external_user_id + '\n';
    string_to_sign += json_permissions + '\n';
    string_to_sign += json_models + '\n';
    string_to_sign += json_group_ids + '\n';
    string_to_sign += json_external_group_id + '\n';
    string_to_sign += json_user_attributes + '\n';
    string_to_sign += json_access_filters;

    const hmac = forge.hmac.create();
    hmac.start('sha1', secret);
    hmac.update(this.forceUnicodeEncoding(string_to_sign));

    const signature = forge.util.encode64(hmac.digest().getBytes()).trim();

    // construct query string
    const query_params = {
      nonce: json_nonce,
      time: json_time,
      session_length: json_session_length,
      external_user_id: json_external_user_id,
      permissions: json_permissions,
      models: json_models,
      access_filters: json_access_filters,
      first_name: json_first_name,
      last_name: json_last_name,
      group_ids: json_group_ids,
      external_group_id: json_external_group_id,
      user_attributes: json_user_attributes,
      force_logout_login: json_force_logout_login,
      signature: signature
    };

    const params = new URLSearchParams(query_params);
    const query_string = params.toString();
    return UrlHelper.AppendUrl(host, embed_path + '?' + query_string);
  }

  /**
   * Generate the Looker request nonce
   * @param len the length of the nonce (number of characters)
   * @returns nonce
   */
  nonce(len: number) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    while (text.length < len) {
      const randomByte = forge.random.getBytesSync(1);
      const index = randomByte.charCodeAt(0) % possible.length;
      text += possible.charAt(index);
    }

    return text;
  }

  /**
   * Verify that the signature is unicode encoded
   * @param string signature
   * @returns unicode signature
   */
  forceUnicodeEncoding(string: string) {
    return decodeURIComponent(encodeURIComponent(string));
  }
}
