
/**
 * User Settings Enums
 */
export enum EditMenuOptions {
  None = 'NONE',
  Edit = 'EDIT',
  Delete = 'DELETE',
  ResetPassword = 'RESET_PASSWORD',
}
