import React from 'react';
import { Outlet } from 'react-router-dom';
import { useVipContext } from 'shared/hooks/useVipContext';
import { RouteCategory } from '../shared/service/router/route-control';
import { Sidebar } from './Sidebar';
import { Header } from './Header';
import { Footer } from './Footer';
import styled from '@emotion/styled';
import { vlogger } from 'shared/service/logger/vlogger';


/**
 * Styling of MainContent
 */
const MainContent = styled('main')`
  display: flex;
  position: relative;
  background-color: var(--page-background);

  //  home page specific css
  &:has( #home-page ) #content-area  {
    margin-left: -1rem !important;
  }

  //  overflow must be visible to collapsible menu widget to be sticky
  overflow-x: visible;

  & #content-area {
    //  set overflow hidden to keep right edge aligned with page
    overflow-x: hidden;
  }
`;

/**
 * defines the page layout
 * @param
 * @returns
 */
export const MainPageLayout = () => {
  const { routes } = useVipContext();
  vlogger.verbose(`sidebar routes: ${JSON.stringify(routes)}`);

  //  number of layout pages (AuthGuard, MainLayoutPage)
  const routeDepth = -2;

  return (
    <>
      <Header />
      <MainContent >
        <Sidebar routes={routes} routeDepth={routeDepth} routeCategory={RouteCategory.MAIN_PAGE} />
        <div id='content-area'>
          <Outlet />
        </div>
      </MainContent >
      <Footer />
    </>
  );
};
