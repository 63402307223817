
/**
 * User Settings Enums
 */
export enum EditMenuOptions {
  None = 'NONE',
  Add = 'RESEND_EMAIL',
  Edit = 'EDIT',
  Delete = 'DELETE',
}
