/* eslint-disable no-useless-escape */
/**
 * URL Helper library
 * @class
 */
export class UrlHelper {
  /**
   * Concatenate two urls (or append one url to another)
   * @param url1
   * @param url2
   * @returns url without missing or duplicate '/'
   */
  static AppendUrl(url1: string, url2: string): string {
    const url1EndsWithSlash = url1.endsWith('/');
    const url2StartsWithSlash = url2.startsWith('/');

    if (url1EndsWithSlash && url2StartsWithSlash) {
      return url1.slice(0, -1) + url2;
    } else if (url1EndsWithSlash || url2StartsWithSlash) {
      return url1 + url2;
    } else {
      return url1 + '/' + url2;
    }
  }

  /**
   * Remove trailing slash from url
   * @param url
   * @returns
   */
  static NoTrailingSlash(url: string): string {
    return url.endsWith('/') ? url.slice(0, -1) : url;
  }

  /**
   * Completely encode URI
   * @param url
   * @returns url with all special characters encoded
   * @remarks url should NOT contain the protocol (ie, 'https://')
   */
  static EncodeURLAll(url: string): string {
    return encodeURIComponent(url)
      .replace(/\-/g, '%2D')
      .replace(/\_/g, '%5F')
      .replace(/\./g, '%2E')
      .replace(/\!/g, '%21')
      .replace(/\~/g, '%7E')
      .replace(/\*/g, '%2A')
      .replace(/\'/g, '%27')
      .replace(/\(/g, '%28')
      .replace(/\)/g, '%29');
  }

  /**
   * Completely decode URI
   * @param url
   * @returns url with all special characters decoded
   * @remarks url should NOT contain the protocol (ie, 'https://')
   */
  static DecodeURLAll(url: string): string {
    return decodeURIComponent(url)
      .replace(/\%2D/g, '-')
      .replace(/\%5F/g, '_')
      .replace(/\%2E/g, '.')
      .replace(/\%21/g, '!')
      .replace(/\%7E/g, '~')
      .replace(/\%2A/g, '*')
      .replace(/\%27/g, '\'')
      .replace(/\%28/g, '(')
      .replace(/\%29/g, ')');
  }
}
