import { PublicClientApplication, InteractionType, Logger, EventType, PerformanceEvent, EventMessage } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { CssBaseline } from '@mui/material';
import { LOGINREQUEST, MSAL_CONFIG } from 'App/auth-configuration';
import { User } from 'App/User/User';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ErrorComponent } from 'shared/components/ErrorComponent';
import { Waiting } from 'shared/components/Waiting';
import { vlogger } from 'shared/service/logger/vlogger';


/**
 * Authentication Guard
 */
export const AuthGuard = () => {
  //  ADB2C (MSAL) Authentication
  const msalInstance = new PublicClientApplication(MSAL_CONFIG);
  msalInstance.initialize().then(() => {
    msalInstance.setLogger(new Logger(MSAL_CONFIG.system?.loggerOptions || {}, 'msal-react', '1.0.0'));
  });

  //  Event handlers
  msalInstance.enableAccountStorageEvents();
  useEffect(() => {
    const callbackId = msalInstance.addEventCallback((message: EventMessage) => {
      // This will be run every time an event is emitted after registering this callback
      if (
        message.eventType === EventType.LOGIN_FAILURE ||
        message.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
        message.eventType === EventType.SSO_SILENT_FAILURE ||
        message.eventType === EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE
      ) {
        //  short term (before 6/24) - changed to informational for debugging purposes
        // vlogger.debug(`Msal Login Failure Event Message:\n${JSON.stringify(message)}`);
        vlogger.informational(`Msal Login Failure Event Message:\n${JSON.stringify(message)}`);
      }
    });

    return () => {
      // This will be run on component unmount
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId);
      }
    };
  }, []);

  useEffect(() => {
    const callbackId = msalInstance.addPerformanceCallback((events: PerformanceEvent[]) => {
      events.forEach(event => {
        vlogger.verbose(`${JSON.stringify(event)}`);
      });
    });

    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId);
      }
    };
  }, []);


  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={LOGINREQUEST}
        errorComponent={ErrorComponent}
        loadingComponent={() => <Waiting delay={500} />}
      >
        <User>
          <CssBaseline />
          <Outlet />
        </User>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};
