import React, { MouseEvent } from 'react';
import { Checkbox } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridRowModel } from '@mui/x-data-grid';
import { IUserRole } from 'shared/service/profile/role-profile';
import { RoleEditMenu } from './RoleEditMenu';
import { SortedAscendingIcon, SortedDescendingIcon } from 'shared/components/Datagrid/SortedIcon';
import styled from '@emotion/styled';


/**
 * Styling
 */
const DataGridDiv = styled.div`
  width: 100%;
`;

/**
 * Props passed 
 */
interface IRoleDataTableProps {
  roles: IUserRole[];
  onEditClick: (event: MouseEvent<HTMLDivElement>, rowdata: GridRowModel) => void;
}


/**
 * Roles data grid component
 * @param {IUserDataTableProps} props 
 * @returns 
 */
export const RoleDataTable = (props: IRoleDataTableProps) => {
  const { roles, onEditClick } = props;
  const rowsPerPage: number[] = [10, 20, 50];
  const [paginationModel, setPaginationModel] = React.useState({ pageSize: 20, page: 0 });


  //  DataGrid Column definition
  const columns: GridColDef[] = [
    {
      field: 'colEnabled', headerName: 'Enabled', width: 125, headerAlign: 'left',
      renderCell: (params: GridCellParams) => (
        // <div className='enable-role' onClick={(e) => onCheckboxClick(e, params.row)}>
        <Checkbox checked={params.row} disabled inputProps={{ 'aria-label': 'enabled' }} />
        // </div>
      )
    },
    { field: 'colPriority', headerName: 'Priority', minWidth: 125, flex: 1, headerAlign: 'left' },
    { field: 'colName', headerName: 'Name', minWidth: 150, flex: 1, headerAlign: 'left' },
    { field: 'colDescription', headerName: 'Description', minWidth: 180, flex: 1, headerAlign: 'left' },
    {
      field: 'colEdit', headerName: ' ', width: 30, align: 'center', hideSortIcons: true,
      renderCell: (params: GridCellParams) => (
        <div className='edit-role' onClick={(e) => onEditClick(e, params.row)}>
          <RoleEditMenu aria-label="role edit menu" />
        </div>
      )
    },
  ];

  let index = 1;
  const rows = roles.map(r => {
    return {
      id: index++,
      colName: r.name,
      colDescription: r.description,
      colEnabled: r.isEnabled,
      colPriority: r.priority,
      colEdit: ' '
    };
  });

  return (
    <DataGridDiv id='roles-table'>
      <DataGrid rows={rows} columns={columns}
        pagination
        pageSizeOptions={rowsPerPage}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        autoHeight
        disableColumnMenu
        disableRowSelectionOnClick
        slots={{
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
        }}
      />
    </DataGridDiv>
  );
};
