import React, { useLayoutEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import styled from '@emotion/styled';


/**
 * Styling
 */
const LoadingStyle = styled.div`
  .backdrop {
    z-index: 100;
    color: var(--color-secondary-light);;
    background-color: transparent;
  }
`;

export interface ILoadingProps {
  isLoading: boolean;
  delay?: number;
}

export const Loading = (props: ILoadingProps) => {
  const { isLoading, delay } = props;
  const [open, setOpen] = useState(false);

  // const initialDelay = delay || 250;
  const initialDelay = delay !== undefined ? delay : 250;

  useLayoutEffect(() => {
    let timer: NodeJS.Timer | null = null;

    if (isLoading) {
      timer = setTimeout(() => { setOpen(true); }, initialDelay);
    } else {
      setOpen(false);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isLoading]);

  return (
    <LoadingStyle>
      <Backdrop className="backdrop" open={open} data-testid="backdrop">
        <CircularProgress color="inherit" size={100} />
      </Backdrop>
    </LoadingStyle>
  );
};
