import React, { PropsWithChildren, ReactElement, useState } from 'react';
import { vlogger } from 'shared/service/logger/vlogger';
import { IRoutePath } from 'shared/interfaces/iroute-path';
import { RouterService } from 'shared/service/router/router-service';
import { IVipContext, VipContext } from './VipContext';
import { useEffectOnce } from 'shared/hooks/useEffectOnce';


/**
 * VipProvider props type definition
 * @type {PropsWithChildren}
 */
export type VipProviderProps = PropsWithChildren<Record<string, unknown>>;

/**
 * VipContext provider
 * @param {VipProviderProps} props
 * @returns return context element
 */
export function VipProvider({ children }: VipProviderProps): ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [routes, setRoutes] = useState<IRoutePath[]>([{}] as IRoutePath[]);
  const [flatRoutes, setFlatRoutes] = useState<IRoutePath[]>([{}] as IRoutePath[]);

  //  Fetch and save routes
  //  React 18 development mode executes useEffect twice
  //  **  https://github.com/reactwg/react-18/discussions/18
  //  **  https://beta.reactjs.org/learn/synchronizing-with-effects#fetching-data
  //  **  https://dev.to/ag-grid/react-18-avoiding-use-effect-getting-called-twice-4i9e
  //  TODO:  update to use either React Query or React Router 6.4+
  useEffectOnce(() => {
    const fetchRoutes = async () => {
      const [routes, flatRoutes] = await new RouterService(vlogger).getRoutes();
      setRoutes(routes);
      setFlatRoutes(flatRoutes);
    };
    fetchRoutes();

    return () => {
      setRoutes([]);
      setFlatRoutes([]);
    };
  });

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue: IVipContext = {
    isLoading,
    setIsLoading: (isLoading: boolean) => setIsLoading(isLoading),
    routes,
    flatRoutes,
  };

  //  add context to hierarchy
  return (
    <VipContext.Provider value={contextValue}>
      {children}
    </VipContext.Provider>
  );
}
