import React from 'react';
import { useMsal } from '@azure/msal-react';
import { useAppSelector } from 'redux/hooks';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Toolbar } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { UserMenu } from 'App/User/UserMenu';
import { GAnalytics } from 'shared/components/GAnalytics';
import { useTimeout } from 'shared/hooks/useTimeout';
import { vlogger } from 'shared/service/logger/vlogger';
import styled from '@emotion/styled';
import appConstants from 'App/app-constants';
import gaConstants from 'shared/components/GAnalytics/ga-constants';

const PageHeader = styled(Toolbar)`
  height: 100px;
`;

const AdminPageSubHeader = styled.div`
  width: 100%;
  height: 2.75rem;
  background-color: var(--color-primary);
  font-size: 1rem;
  color: var(--primary-text);
  display: flex;

  .subheader {
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    & .chevron-left {
      height: 2rem;
      width: 2rem;
      margin-left: 1rem;
      margin-right: -0.25rem;
    }
  }
  `;

/**
 * Site wide common Header tag
 */
export const Header = () => {
  const { instance } = useMsal();
  const user = useAppSelector((state) => state.profile.user);
  const navigate = useNavigate();
  const location = useLocation();

  const isAdminPage = !!matchPath('administration/*', location.pathname);

  const handleReturnHome = () => {
    navigate('/');
    // navigate(-1);
  };

  //  timeout user after inactivity period
  //  activity is defined as click, scroll, or touchmove
  useTimeout(() => {
    vlogger.warning(`user (${user.displayName}) was timed out due to inactivity`);
    instance.logoutRedirect();
  },
    appConstants.session_timeout,
    ['click', 'scroll', 'touchmove']
  );

  return (
    <header>
      <PageHeader>
        <img src={`${appConstants.imageDirectory}/vip-horizontal-white.png`} onClick={handleReturnHome}
          className={'logo'} alt="velocity solutions" aria-label="velocity solutions"
        />
        <UserMenu />
      </PageHeader>


      {isAdminPage &&
        <AdminPageSubHeader>
          <div className='subheader' onClick={handleReturnHome} aria-label="back to dashboard">
            <ChevronLeft className='chevron-left' />
            <span>RETURN TO DASHBOARD</span>
          </div>
        </AdminPageSubHeader>
      }

      {!gaConstants.testMode &&
        <GAnalytics pathname={location.pathname} userId={user.userId} />
      }
    </header>
  );
};
