import React, { useState, useEffect, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import { Autocomplete, Button, Checkbox, Container, Divider, Drawer, TextField } from '@mui/material';
import { VelocityUser } from 'shared/types/velocityUser';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { defaultCompany, defaultUser, IVipCompany, IVipUser } from 'redux/profileSlice';
import { addUpdateUser } from 'redux/appSlice';
import { IUserRole } from 'shared/service/profile/role-profile';
import { ApplicationSection, canAccessRole, hasAccess, isFIRole, Permission, toPermission, UserRole, UserRoleDisplay } from 'App/User/user-role';
import { IUserClientRequest, UserSettingsService } from './user-settings-service';
import { CloseBar } from 'shared/components/CloseBar';
import { ClientMultiSelect } from './ClientMultiSelect';
import styled from '@emotion/styled';
import { EmailHelper } from 'shared/utils/email-helper';
import appConstants from 'App/app-constants';
import { vlogger } from 'shared/service/logger/vlogger';


/**
 * Styling
 */
const AdminUserDrawer = styled(Drawer)`
 & .MuiPaper-root {
   height: 100%;
  //  width: max(500px, 42rem);
   font-family: 'WhitneySSM', sans-serif;
   font-weight: normal;
  }

  & .MuiContainer-root {
    
    & .element-spacing {
      margin: 1.625rem 0rem;
    }

    & h2 {
      margin: 1rem 0rem;
     }

    & .button-box {
      margin: 2.875rem 0rem;
      justify-content: space-between;

      & > button {
        width: 18.75rem;
        height: 3.75rem;
      }
    }

    & .button-radio {
      width: 8.5rem;
      height: 3.75rem;
    }

    label {
      margin: 0.625rem 0rem;
      display: block;
    }

    & .vip-textbox {
      display: flex;
      flex-direction: column;

      & .MuiInputBase-root {
        width: 300px;
        height: 45px;
      }

      &.add-edit-textbox-lastname {
        padding-left: 20px;
      }

      &.add-edit-textbox-email .MuiInputBase-root,
      &.add-edit-textbox-client .MuiAutocomplete-root,
      &.add-edit-textbox-client .MuiInputBase-root
       {
        width: 100%; 
      }

      & #client-selector {
        margin: 0rem;
    }
  }
} 
`;


/**
 * Props passed 
 */
interface IAddEditUserProps {
  actionType: string;
  isOpen: boolean;
  editUser: VelocityUser | null;
  onCloseClick: () => void;
  registerNewUser: (user: VelocityUser) => Promise<void>;
}

interface ICompanyName {
  name: string;
  clientId: number;
}

interface IRoleName {
  name: string;
  role: UserRole;
}


/**
 * Add / Edit User component
 * @param props 
 * @returns 
 */
export const AddEditUser = (props: IAddEditUserProps) => {
  const { actionType, isOpen, editUser, onCloseClick, registerNewUser } = props;
  const dispatch = useAppDispatch();
  const companies: IVipCompany[] = useAppSelector((state) => state.app.companies);
  const roles: IUserRole[] = useAppSelector((state) => state.app.roles);
  const user: IVipUser = useAppSelector((state) => state.profile.user);

  const { instance } = useMsal();
  const [isActive, setIsActive] = useState<boolean>(editUser ? !editUser.isDisabled : true);

  const [roleValue, setRoleValue] = useState<IRoleName>({ name: UserRoleDisplay[UserRole.FI_USER], role: UserRole.FI_USER });
  const [companyValue, setCompanyValue] = useState<ICompanyName>({
    name: appConstants.default_companyName,
    clientId: appConstants.default_clientId
  });

  const userSettingsService = new UserSettingsService();
  const [clientSelection, setClientSelection] = useState<IVipCompany[]>([]);
  const [originalSelection, setOriginalSelection] = useState<IVipCompany[]>([]);


  //  flag if currently in edit mode
  //  used to disable some fields that aren't editable
  const isEditMode = actionType.toLowerCase().startsWith('e');

  //  is user a FI user
  const userHasFIrole = isFIRole(user.userRole);

  //  dom references
  const emailRef = useRef<HTMLInputElement>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const n =
      (editUser?.companyName)
        ? editUser.companyName
        : (userHasFIrole)
          ? companies.find(c => c.clientId === user.clientId)?.name ?? appConstants.default_companyName
          : appConstants.default_companyName;

    let cid = companies.find(c => c.name === editUser?.companyName)?.clientId ?? appConstants.default_clientId;
    if (userHasFIrole)
      cid = user.clientId;


    const dv: ICompanyName | null = {
      name: n,
      clientId: cid,
    };
    setCompanyValue(dv);

    const r: IRoleName = {
      name: UserRoleDisplay[(editUser?.userRole ?? defaultUser.userRole) as UserRole],
      role: editUser?.userRole ?? defaultUser.userRole
    };
    setRoleValue(r);
  }, [editUser]);

  useEffect(() => {
    async function fetchData() {
      const cs =
        editUser
          ? (await userSettingsService.getClientsByUserId(instance, editUser, true)) ?? [defaultCompany]
          : [defaultCompany];

      setClientSelection(cs);
      setOriginalSelection(cs);
    }

    if (roleValue.role === UserRole.CSM_USER)
      fetchData();
  }, [roleValue]);

  //  set options
  const companyOptions: ICompanyName[] = companies.map(c => {
    return {
      name: c.name,
      clientId: c.clientId
    };
  });


  const userRole = roles.find(r => r.name === user.userRole);
  const roleOptions: IRoleName[] = [];
  roles.forEach(r => {
    const role = r.name as UserRole;
    if (r.isEnabled && canAccessRole(userRole, r)) {
      roleOptions.push({
        name: UserRoleDisplay[role],
        role: role
      });
    }
  });

  const onChangeClientSelection = (cs: IVipCompany[]) => {
    setClientSelection(cs);
  };

  const handleSaveClick = async () => {
    const userEmail = (emailRef.current?.value ?? '').trim();
    const ua_clientId = !isMultiSelect && companyValue?.clientId || defaultUser.clientId;

    const formUser: IVipUser = {
      firstName: (firstNameRef.current?.value ?? '').trim(),
      lastName: (lastNameRef.current?.value ?? '').trim(),
      email: userEmail,
      clientId: ua_clientId,
      isDisabled: !isActive,
      isDeleted: false,
      isBeta: false,
      userRole: roleValue.role,
      permission: toPermission(roleValue.role),
    };

    const response =
      await dispatch(addUpdateUser({ pca: instance, formUser: formUser, user: user }));
    vlogger.informational(`Adding or Updating user: ${formUser.email}`);

    //  update userId if an add
    const newUser = (response.payload as IVipUser[]).find(u => u.email === userEmail);

    //  update any selected clients
    const allSelection = [...originalSelection, ...clientSelection];
    const originalSelectionClientIds = originalSelection.map(o => o.clientId);
    const clientSelectionClientIds = clientSelection.map(c => c.clientId);
    allSelection.forEach(async (e) => {
      const elementRemoved = originalSelectionClientIds.includes(e.clientId) &&
        !clientSelectionClientIds.includes(e.clientId);

      const req: IUserClientRequest = {
        userId: editUser?.userId ?? newUser?.userId,
        clientId: e.clientId,
        isEnabled: !elementRemoved,
      };
      await userSettingsService.addUserClient(instance, req);

      if (!elementRemoved)
        vlogger.debug(`Updating UserClient user_id(${editUser?.userId}), clientId(${e.clientId})})`);
      else
        vlogger.debug(`Disabling UserClient user_id(${editUser?.userId}), clientId(${e.clientId})})`);
    });

    //  only send registration email when adding new user
    if (!editUser) {
      const vUser: VelocityUser = {
        ...formUser,
        companyName: companyValue.name,
        isVelocityUser: EmailHelper.isVelocityEmail(userEmail),
      };
      await registerNewUser(vUser);
    }

    onCloseClick();
  };

  //  show multi select client window
  const isMultiSelect =
    (roleValue.role === UserRole.CSM_USER) &&
    hasAccess(user, ApplicationSection.CSM_ADMIN, Permission.EDIT);

  return (
    <AdminUserDrawer anchor={'right'} open={isOpen} aria-label='close add/edit user drawer' >
      <CloseBar onClick={() => onCloseClick()} />

      <Container >
        <h2>{actionType} Profile</h2>

        <form noValidate autoComplete="off">
          <div className='display-flex element-spacing'>
            <div className='vip-textbox'>
              <label htmlFor='firstName'>First Name</label>
              <TextField id='firstName' variant='outlined' required className='textbox'
                inputRef={firstNameRef}
                defaultValue={editUser?.firstName || ''}
              />
            </div>

            <div className='vip-textbox add-edit-textbox-lastname'>
              <label htmlFor='lastName'>Last Name</label>
              <TextField id='lastName' variant='outlined' required className='textbox'
                inputRef={lastNameRef}
                defaultValue={editUser?.lastName || ''}
              />
            </div>
          </div>

          <div className='vip-textbox add-edit-textbox-email element-spacing'>
            <label htmlFor='email'>Email</label>
            <TextField id='email' variant='outlined' required className='textbox'
              disabled={isEditMode} inputRef={emailRef}
              defaultValue={editUser?.email || ''}
            />
          </div>

          <div className='vip-textbox add-edit-textbox-client element-spacing'>
            <label htmlFor='role-selection'>Role</label>
            <Autocomplete id="role-selection" autoComplete autoSelect disableClearable
              options={roleOptions}
              getOptionLabel={(option) => option.name}
              renderInput={(params) =>
                <TextField
                  {...params}
                  variant="outlined" className='render-input'
                />
              }
              onChange={(event, newValue) => { setRoleValue(newValue); }}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              value={roleValue}
            />
          </div>

          {!isMultiSelect &&
            <div className='vip-textbox add-edit-textbox-client element-spacing'>
              <label htmlFor='client-selection'>Client</label>
              <Autocomplete id="client-selection" autoComplete autoSelect disableClearable
                disabled={userHasFIrole}
                options={companyOptions}
                getOptionLabel={(option) => `(${option.clientId})${option.name}`}
                renderInput={(params) =>
                  <TextField {...params} variant="outlined" className='render-input' />
                }
                isOptionEqualToValue={(option, value) => option.clientId === value.clientId}
                onChange={(event, newValue) => { setCompanyValue(newValue); }}
                value={companyValue}
              />
            </div>
          }

          {isMultiSelect &&
            <div className='element-spacing'>
              <ClientMultiSelect companies={companies} selected={clientSelection} onChangeSelected={onChangeClientSelection} />
            </div>
          }

          <div className='display-flex element-spacing'>
            <Button variant="outlined" className='button-radio'
              onClick={() => setIsActive(!isActive)}
            >
              <Checkbox checked={isActive} className='checkbox' />
              <label>Active</label>
            </Button>
          </div>

          <Divider />

          <div className='display-flex button-box'>
            <Button id='close-edit-user' variant="outlined" onClick={() => onCloseClick()} >
              Cancel
            </Button>

            <Button id='save-edit-user' variant="contained" onClick={handleSaveClick} >
              Save
            </Button>
          </div>
        </form>
      </Container>
    </AdminUserDrawer >
  );
};
