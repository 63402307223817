import React, { useRef, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Alert, Button, Checkbox, Container, FormControlLabel, Snackbar } from '@mui/material';
import { TextboxLabel } from 'shared/components/Textbox/TextboxLabel';
import { useNavigate } from 'react-router-dom';
import { RegistrationService, ISendRegistrationRequest } from './registration-service';
import styled from '@emotion/styled';

// TODOs: remove ResendRegistration component, but keep/refactor registration-service.ts

/**
 * Styling of MainContent
 */
const ResendRegistrationContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & label {
    font-weight: bold;
    font-size: 20px;
    color: var(--secondary-text);
    line-height: 1.5;
  }

  & .MuiInputBase-root {
    width: 500px;
  }

  & button.cancel-button {
    width: 200px;
    margin-top: 45px;
  }
  
  button.submit-button {
    width: 200px;
    margin: 45px 0px 0px 20px;
  }

  & #response-label {
    font-weight: normal;
    margin: 2rem 0rem;
  }
`;


/**
 * Resend Registration component
 * @returns
 */
export const ResendRegistration = () => {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState<boolean>(false);
  const [responseState, setResponseState] = useState('');
  const returnResponseInput = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();
  const { instance } = useMsal();

  const onCancelClick = () => {
    navigate(-1);
  };


  const handleSubmitClick = async () => {
    const resendEmail = document.querySelector('#resend-email') as HTMLInputElement;
    const email = emailRef.current;
    const returnResponse = returnResponseInput.current;

    const request: ISendRegistrationRequest = {
      email: resendEmail.value,
      includeComponents: returnResponse?.checked ?? false,
    };

    const result =
      await new RegistrationService().sendRegistrationEmail(instance, request);


    if (result === null || result.sent !== true) {
      setResponseState('');
      setSnackbarOpen(true);
    } else {
      setSnackbarSuccessOpen(true);
      if (resendEmail)
        resendEmail.value = '';
      if (returnResponse?.checked)
        setResponseState(JSON.stringify(result));
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarSuccessOpen(false);
  };

  return (
    <ResendRegistrationContainer>
      <TextboxLabel id='resend-email' labelText='Email' variant='outlined' required
        className='textbox' ref={emailRef}
      />
      <div id="return-response" className='hidden'>
        <FormControlLabel id='response-checkbox' control={<Checkbox inputRef={returnResponseInput} />}
          label="Return Response" labelPlacement='start'
        />
      </div>

      <div className='display-flex'>
        <Button id='cancel' variant="outlined" onClick={() => onCancelClick()}
          className='cancel-button'
        >
          Cancel
        </Button>

        <Button id='submit' variant="contained" onClick={handleSubmitClick}
          className='submit-button'
        >
          Submit
        </Button>
      </div>

      <label id='response-label'>{responseState}</label>

      {/* submission success or failure */}
      <Snackbar id={'snackbar-success'} open={snackbarSuccessOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert severity='success'>Submission successful.</Alert>
      </Snackbar>

      <Snackbar id={'snackbar-result'} open={snackbarOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert severity='error'>Submission error.</Alert>
      </Snackbar>
    </ResendRegistrationContainer>
  );
};
