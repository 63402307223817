import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { AccountInfo } from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { fetchCompanies, fetchRoles, fetchUsers } from 'redux/appSlice';
import { fetchClientFeatures, fetchFeatureFlags } from 'redux/featureSlice';
import { companyAdd, defaultCompany, fetchUser, IVipCompany, IVipUser } from 'redux/profileSlice';
import { ApplicationSection, Permission, canImpersonateHelper, hasAccess, hasFilteredClientsByUserId, hasFilteredUsersByUserId } from 'App/User/user-role';
import { vlogger } from 'shared/service/logger/vlogger';
import { VipContext } from 'shared/context/VipContext';


/**
 * All information pertaining to the user
 * @param props 
 * @returns 
 */
export const User = ({ children }: PropsWithChildren<Record<string, unknown>>) => {
  const dispatch = useAppDispatch();

  const user: IVipUser = useAppSelector(state => state.profile.user);
  const companies: IVipCompany[] = useAppSelector(state => state.app.companies);

  const { accounts, instance, inProgress } = useMsal();
  const account: AccountInfo | null = useAccount(accounts[0] || {});

  const { setIsLoading } = useContext(VipContext);

  //  Set User
  useEffect(() => {
    const username = account?.username.toLowerCase() || 'bad name';
    dispatch(fetchUser({ pca: instance, username: username }));
  }, [account]);

  //  Based on user permissions, prefetch data
  useEffect(() => {
    setIsLoading(true);

    if (user.isDisabled) {
      vlogger.error(`User (${user.displayName}) is disabled ... Logging out`);
      vlogger.error(`login progress state: ${inProgress}`);
      instance.logoutRedirect();
    }

    //  company dropdown
    if (canImpersonateHelper(user)) {
      if (hasFilteredClientsByUserId(user.userRole))
        dispatch(fetchCompanies({ pca: instance, userId: user.userId }));
      else
        dispatch(fetchCompanies({ pca: instance }));
    } else {
      dispatch(fetchCompanies({ pca: instance, clientId: user.clientId }));
    }

    //  fetch all users and roles
    const isUserAdmin = hasAccess(user, ApplicationSection.USER_ADMIN, Permission.VIEW);
    if (isUserAdmin) {
      const filtered = hasFilteredUsersByUserId(user.userRole);
      dispatch(fetchUsers({ pca: instance, userId: user.userId, userFilter: filtered }));
      dispatch(fetchRoles(instance));
    }

    //  fetch feature flags and client feature mapping
    dispatch(fetchFeatureFlags({ pca: instance }));

    const isClientAdmin = hasAccess(user, ApplicationSection.CLIENT_ADMIN, Permission.VIEW);
    if (isClientAdmin) {
      dispatch(fetchClientFeatures({ pca: instance }));
    } else {
      dispatch(fetchClientFeatures({ pca: instance, clientId: user.clientId }));
    }

    // setIsLoading(false);
  }, [user]);

  //  Set company in user profile
  useEffect(() => {
    const c = companies.find(c => c.clientId === user.clientId) ?? defaultCompany;
    dispatch(companyAdd(c));
  }, [user, companies]);

  return (
    <>
      {children}
    </>
  );
};
