import React from 'react';
import bv from 'shared/version.json';

const thisYear = new Date().getFullYear();
const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const Footer = () => {
  return (
    <footer>
      <span>Copyright&copy; 2020-{thisYear} &emsp;  Velocity Solutions, LLC   &emsp;&emsp;   All rights reserved.</span>
      <span id='version' className={'version'}>{bv.version}</span>
      <span id='build-time' className={'version'}>{bv.date}</span>
      <span id='timezone' className={'version'}>{timeZoneName}</span>
    </footer>
  );
};
