import React, { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { IVipUser } from 'redux/profileSlice';
import { ApplicationSection, Permission, hasAccess } from 'App/User/user-role';
import { MoreVert } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import { EditMenuOptions } from './user-settings-constants';


/**
 * User Edit/Delete/Resend pulldown
 * @returns 
 */
export const UserEditMenu = () => {
  const user: IVipUser = useAppSelector((state) => state.profile.user);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  //  Close user edit menu
  const handleUserEditClick = () => {
    setAnchorEl(null);
  };

  const editUser = hasAccess(user, ApplicationSection.USER_ADMIN, Permission.EDIT);
  const disableUser = hasAccess(user, ApplicationSection.USER_ADMIN, Permission.DISABLE);

  return (
    <>
      <div onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVert className='kabob' titleAccess='Edit User' aria-label='edit user' />
      </div>

      <Menu id="menu-user-edit" anchorEl={anchorEl} keepMounted
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open} onClose={() => setAnchorEl(null)}
      >
        {editUser &&
          <MenuItem onClick={handleUserEditClick} data-useredit={EditMenuOptions.Edit} aria-label="edit user">
            Edit User
          </MenuItem>
        }

        {disableUser &&
          <MenuItem onClick={handleUserEditClick} data-useredit={EditMenuOptions.Delete} aria-label="delete user">
            Delete User
          </MenuItem>
        }

        <MenuItem onClick={handleUserEditClick} data-useredit={EditMenuOptions.ResetPassword} aria-label="reset password">
          Reset Password
        </MenuItem>
      </Menu>
    </>
  );
};
