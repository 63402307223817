/**
 * Static String Helper class
 * @class
 */
export class StringHelper {
  /**
   * Convert string to boolean
   * @param s input string
   * @returns true iff s in ["true", "yes", "1"] (case insensitive)
   */
  static ToBoolean(s: string | null | undefined): boolean {
    const i = s?.toLowerCase().trim() || '';

    if (i === 'true' || i === 'yes' || i === '1') {
      return true;
    }

    return false;
  }

  /**
   * Convert string to nullable number
   * @param s input string
   * @returns number or null
   */
  static ToNumber(s: string | null | undefined): number | null {
    // eslint-disable-next-line eqeqeq
    if (s == null || Number.isNaN(s)) return null;

    return Number(s);
  }

  /**
   * Remove all spaces from a string
   * @param s string to be returned w/ space removed
   * @param rs replacement string
   * @returns s with space being replaced by either rs or ''
   */
  static SpaceRemover(s: string | null | undefined, rs = ''): string {
    return s ? s.replace(/\s+/g, rs) : '';
  }

  /**
   * Remove all spaces and special characters from a string
   * @param s string to be returned w/ space removed
   * @param rs replacement string
   * @returns s with space being replaced by either rs or ''
   */
  static SpaceAndSpecialCharacterRemover(s: string | null | undefined, rs = ''): string {
    return s ? s.replace(/[^A-Z0-9]/gi, rs) : '';
  }

  /**
   * Convert a value that may represent a number to a hex string
   * @param value value to be converted to hex string
   * @returns hex string representation of the value or empty string
   */
  static ToHexString(value: number | string | null | undefined): string {
    let num: number;

    if (typeof value === 'number') {
      num = value;
    } else if (typeof value === 'string') {
      num = Number(value);
      if (isNaN(num)) {
        return '';
      }
    } else {
      return '';
    }

    return num.toString(16);
  }

  /**
   * Convert a hex string to a string
   * @param hexString hex string to be converted to a string
   * @returns string representation of the hex string
   */
  static FromHexString(s: string): string | null {
    const bytes = Buffer.from(s, 'hex');
    const str = bytes.toString('utf8');
    return str;
  }
}
