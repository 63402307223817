import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import styled from '@emotion/styled';

/**
 * Styling
 */
const WaitingStyle = styled.div`
  z-Index: 100;
  color: #fff;
`;

/**
 * Props interface  (default values set at end of file)
 * @interface
 */
export interface IWaitingProps {
  delay: number;
}

/**
 * Waiting spinner
 * @param {IWaitingProps} props
 * @returns
 */
export const Waiting = (props: IWaitingProps) => {
  const { delay } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setOpen(true);
    }, delay);
    return () => clearTimeout(id);
  }, []);

  return (
    <WaitingStyle>
      <Backdrop open={open}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </WaitingStyle>
  );
};

/**
 * Default props
 */
Waiting.defaultProps = { delay: 2000 };
