import React from 'react';
import { Outlet, useRoutes } from 'react-router-dom';
import { useVipContext } from 'shared/hooks/useVipContext';
import { Header } from './Header';
import { Footer } from './Footer';

import styled from '@emotion/styled';


/**
 * Styling of MainContent
 */
const MainContent = styled('main')`
  display: flex;
  position: relative;
  background-color: var(--page-background);

  //  overflow must be visible to collapsible menu widget to be sticky
  overflow-x: visible;

  & #content-area {
    //  set overflow hidden to keep right edge aligned with page
    overflow-x: hidden;
  }
`;

/**
 * defines the page layout
 * @param
 * @returns
 */
export const AnonymousPageLayout = () => {

  return (
    <>
      <Header />
      <MainContent>
        <div id='content-area'>
          <Outlet />
        </div>
      </MainContent>
      <Footer />
    </>
  );
};
