import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { activateLoading, appReducer, deactivateLoading } from 'redux/appSlice';
// import { authenticationReducer } from 'redux/loginSlice';
import { profileReducer } from 'redux/profileSlice';
import { featureReducer } from 'redux/featureSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    // authentication: authenticationReducer,
    profile: profileReducer,
    feature: featureReducer,
  },
  devTools: (import.meta.env.VITE_APP_IS_REDUX_DEVTOOLS?.toLowerCase().trim() === 'true')
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

//  RootState
export const rootState: RootState = store.getState();
export const isLoading: boolean = rootState.app.loading || rootState.profile.loading;
