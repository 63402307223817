import React, { ChangeEvent, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { useMsal } from '@azure/msal-react';
import { GridRowModel, GridValidRowModel } from '@mui/x-data-grid';
import { Alert, Snackbar } from '@mui/material';
import { format } from 'date-fns-tz';
import { ApplicationSection, Permission, canImpersonateHelper, hasAccess } from 'App/User/user-role';
import { SearchBar } from 'shared/components/SearchBar';
import { ClientSelector } from 'shared/components/ClientSelector';
import { NotAuthorized } from 'shared/components/NotAuthorized';
import { BranchGoalsService, IBranchGoals, IBranchGoalsRequest } from './branch-goals-service';
import { BranchGoalDataTable, IBranchGoalDataRow } from './BranchGoalDataTable';
import styled from '@emotion/styled';
import { vlogger } from 'shared/service/logger/vlogger';


/**
 * Styling
 */
const BranchGoalsContent = styled.div`
  margin: 0px 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & #content-header {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    align-items: center;

    & #client-selector {
      width: 22rem;
      margin-left: 0px;
    }
  }
`;

/**
 * FI Branch Goals page
 * @returns 
 */
export const BranchGoals = () => {
  const { instance } = useMsal();
  const { user, actor } = useAppSelector((state) => state.profile);

  const [branchGoals, setBranchGoals] = useState<(IBranchGoals & { isError?: boolean })[]>([]);
  const [savedBranchGoals, setSavedBranchGoals] = useState<IBranchGoalDataRow[]>([]);
  const [filteredBranchGoals, setFilteredBranchGoals] = useState<IBranchGoalDataRow[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState<boolean>(false);
  const [snackbarErrorOpen, setSnackbarErrorOpen] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  // Verify user has authorization
  const hasClientSelector = canImpersonateHelper(user);
  const canEditBranchGoal = hasAccess(user, ApplicationSection.BRANCH_GOAL_ADMIN, Permission.EDIT);
  const hasBranchGoalAuthorization = hasAccess(user, ApplicationSection.BRANCH_GOAL_ADMIN, Permission.VIEW);
  if (!hasBranchGoalAuthorization)
    return (<NotAuthorized />);

  //  Implement branch-goals
  const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const branchGoalsService = new BranchGoalsService();

  async function fetchData() {
    setBranchGoals([]);  // reset

    const request: IBranchGoalsRequest = { clientId: actor.clientId, orderBy: false };
    const bg = await branchGoalsService.getBranchGoals(instance, request);
    setBranchGoals(bg ?? []);
  }

  useEffect(() => {
    fetchData();
  }, [actor]);

  // set branch goal data rows
  useEffect(() => {
    const rows: IBranchGoalDataRow[] = branchGoals.map(bg => {
      return {
        id: bg.branchNumber,
        clientId: bg.clientId,
        branchName: bg.branchName,
        branchNumber: bg.branchNumber,
        goal: bg.goal ?? 0,
        effectiveDate: bg.effectiveDate ?? format(new Date(), 'yyyy-MM-dd'),
        created: bg.created && format(new Date(bg.created), 'MM/dd/yyyy', { timeZone: timeZoneName }),
        lastModified: bg.lastModified && format(new Date(bg.lastModified), 'MM/dd/yyyy', { timeZone: timeZoneName }),
        isError: bg.isError ?? false,
        isChanged: false,
      };
    });

    setSavedBranchGoals(rows);
  }, [branchGoals]);

  //  Search filtering
  useEffect(() => {
    const lowerSearchText = searchText?.toLowerCase() ?? '';
    const filteredSearchBranchGoals = (!searchText)
      ? savedBranchGoals
      : savedBranchGoals.filter(f => {
        return (
          f.branchName?.toLowerCase().includes(lowerSearchText) ||
          f.branchNumber.toString().includes(lowerSearchText) ||
          f.lastModified?.includes(lowerSearchText)
        );
      });

    setFilteredBranchGoals(filteredSearchBranchGoals);
  }, [searchText, savedBranchGoals]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  /**
   * Handle row updates
   * 
   */
  const processRowUpdate = (newRow: GridRowModel): GridValidRowModel => {
    vlogger.debug('processRowUpdate event fired');
    const rows: IBranchGoalDataRow[] = savedBranchGoals.map((row) => {
      return {
        id: row.id,
        clientId: row.clientId,
        branchNumber: row.branchNumber,
        branchName: row.branchName,
        goal: (row.id === newRow.id) ? newRow.colGoal : row.goal,
        effectiveDate: (row.id === newRow.id) ? newRow.colEffectiveDate : row.effectiveDate,
        created: row.created,
        lastModified: row.lastModified,
        isError: (row.id === newRow.id) ? newRow.isError : row.isError,
        isChanged: row.isChanged ||
          ((row.id === newRow.id) && (row.goal !== newRow.colGoal || row.effectiveDate !== newRow.colEffectiveDate)),
      };
    });
    setSavedBranchGoals(rows);

    setIsDisabled(rows.findIndex(r => r.isError) >= 0);

    return newRow;
  };


  /**
   * Save click event handler
   * @param {MouseEvent<HTMLButtonElement>} event cancel click event
   */
  const handleSaveClick = async () => {
    vlogger.informational('Adding or Updating referral goals');

    const addGoals = savedBranchGoals
      .filter(r => r.isChanged && !r.isError)
      .map(r => {
        return {
          clientId: r.clientId,
          branchNumber: r.branchNumber,
          updatedBy: user.userId,
          goal: r.goal,
          effectiveDate: r.effectiveDate,
        };
      });

    const response = await branchGoalsService.addBranchGoals(instance, addGoals);
    if (response)
      setSnackbarSuccessOpen(true);
    else {
      setSnackbarErrorOpen(true);
      vlogger.error('Error: writing branch goals', undefined, true);
    }

    await fetchData();
  };

  /**
   * Cancel click event handler
   * @param {MouseEvent<HTMLButtonElement>} event cancel click event
   */
  const handleCancelClick = async () => {
    await fetchData();
  };

  /**
   * Snackbar handlers
   */
  const handleSnackbarSuccessClose = () => {
    setSnackbarSuccessOpen(false);
  };

  const handleSnackbarErrorClose = () => {
    setSnackbarErrorOpen(false);
  };

  return (
    <BranchGoalsContent id='branch-goals-content'>
      <div id='content-header'>
        <ClientSelector isVisible={hasClientSelector} isDisabled={!hasClientSelector} />
        <SearchBar id='search-bar' searchQuery={searchText} setSearchQuery={(e) => handleSearchChange(e)} />
      </div>

      <div id='content-body' className={'width-100'}>
        <BranchGoalDataTable key={'BranchGoalDataTable'} branchGoals={filteredBranchGoals}
          isDisabled={isDisabled}
          canEdit={canEditBranchGoal}
          processRowUpdate={processRowUpdate}
          handleSaveClick={handleSaveClick}
          handleCancelClick={handleCancelClick}
        />
      </div>

      {/* upload success or failure */}
      <Snackbar id={'snackbar-error-result'} open={snackbarErrorOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={handleSnackbarErrorClose}
      >
        <Alert severity='error'>Error occurred while saving referral goals.</Alert>
      </Snackbar>

      <Snackbar id={'snackbar-success-result'} open={snackbarSuccessOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={handleSnackbarSuccessClose}
      >
        <Alert severity='success'>Saved</Alert>
      </Snackbar>
    </BranchGoalsContent>
  );
};
