import React from 'react';
import { useAppSelector } from 'redux/hooks';
import { IFeatureFlagEnabled, featureFlagValues } from 'shared/interfaces/iclient-feature';
import { Divider, Drawer, IconButton, List } from '@mui/material';
import { DoubleArrow } from '@mui/icons-material';
import { IRoutePath } from 'shared/interfaces/iroute-path';
import { RouteCategory } from 'shared/service/router/route-control';
import { ClientSelector } from 'shared/components/ClientSelector';
import { isFIRole } from 'App/User/user-role';
import { SidebarList } from './SidebarList';
import { StringHelper } from 'shared/utils/string-helper';
import styled from '@emotion/styled';


/**
 * Styling
 */
const SidebarDiv = styled.div`
  display: flex;

  & .menuClose {
    display: none !important;
  }

  & > .MuiIconButton-root {
    margin-top: 1rem;
    width: 1.25rem;
    height: 2.5rem;
    background-color: var(--navigation-background);
    border: solid 1px var(--hr);
    border-radius: 0px;

    &.menuOpenButton {
      border-right: solid 0px var(--hr);
      transform: rotateY(180deg);
    }

    &.menuCloseButton {
      border-left: solid 0px var(--hr);
      transform: rotateY(0deg);
    }

    &#menu-toggle {
      position: sticky;
      top: 2rem;
      z-index: 5;
      background-color:  var(--navigation-background);
    }

    & .MuiSvgIcon-root {
      width: 0.75rem;
    }
  }

  & .MuiDrawer-root {
    background-color: var(--navigation-background);
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-bottom: solid 2px var(--hr);

    & .MuiPaper-root {
      position: relative !important;
      border-right: 0 !important;
      background-color: transparent;
      overflow: hidden;
    }
  }
`;

/**
 * Props
 */
interface ISidebarProps {
  routes: IRoutePath[];
  routeDepth: number;
  routeCategory: RouteCategory;
}

/**
 * Sidebar menu
 * @param props routes
 */
export const Sidebar = (props: ISidebarProps) => {
  const { routes, routeDepth, routeCategory } = props;
  const [open, setOpen] = React.useState(true);


  const { user, actor } = useAppSelector((state) => state.profile);
  const featureFlags = useAppSelector((state) => state.feature.featureFlags);
  const clientsFeatures = useAppSelector((state) => state.feature.clientsFeatures);

  const cf = clientsFeatures.filter(cf => cf.clientId === actor.clientId);
  const ArsFpFeatureId = featureFlags.find(ff => ff.featureName === featureFlagValues['ars_fp'])?.featureId;
  const isARSFpEnabled = cf.find(i => i.featureFlagId === ArsFpFeatureId)?.isEnabled || false;
  const InsightsFeatureId = featureFlags.find(ff => ff.featureName === featureFlagValues['insights'])?.featureId;
  const isInsightsEnabled = cf.find(i => i.featureFlagId === InsightsFeatureId)?.isEnabled || false;

  const isFeatureFlagEnabled: IFeatureFlagEnabled = {
    isARSFpEnabled: isARSFpEnabled,
    isInsightsEnabled: isInsightsEnabled,
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  //  is user a FI user
  const userHasFIrole = isFIRole(user.userRole);

  return (
    <SidebarDiv id='sidebar'>
      <Drawer id='navbar' variant='persistent' anchor='left' open={open}
        aria-label='navigation menu' className={open ? '' : 'menuClose'}
      >
        {routeCategory === RouteCategory.MAIN_PAGE &&
          <ClientSelector isVisible={!userHasFIrole} isDisabled={userHasFIrole} />
        }
        <List component='nav' disablePadding key='menu' aria-labelledby='navbar'>
          <Divider />
          {routes.map((route) => {
            const elementKey = StringHelper.SpaceRemover(`top-${route.displayName}`, '-').toLowerCase();
            return <SidebarList route={route} depth={routeDepth} routeCategory={routeCategory} isFeatureFlagEnabled={isFeatureFlagEnabled} key={elementKey} />;
          })}
        </List>
      </Drawer>
      <IconButton id='menu-toggle' aria-label='open drawer' onClick={handleDrawerOpen}
        className={open ? 'menuOpenButton' : 'menuCloseButton'} size='small' edge='end'
      >
        <DoubleArrow />
      </IconButton>
    </SidebarDiv>
  );
};
