import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { vlogger } from 'shared/service/logger/vlogger';

/**
 * Http Service Headers
 * @interface
 */
export interface IHeaders {
  [key: string]: string;
}

/**
 * Problems Details interface
 * @interface
 */
export interface IProblemDetails {
  statusCode: number;
  title?: string;
  type?: string;
  details?: string;
  instance?: string;
}

/**
 * Http Service Interface  - use for DI (or DI like)
 * @interface
 * @remark  this interface is tailored for PowerBI
 */
export interface IHttpService {
  get<T>(relativeUrl: string, params: object, headers: IHeaders, operationId: string): Promise<T | IProblemDetails | null>;
  post<S, T>(relativeUrl: string, body: S, headers: IHeaders, operationId: string): Promise<T | IProblemDetails | null>;
}

/** 
* Class representing a Http Service object
* @class
* @implements IHttpService
*/
export class HttpService implements IHttpService {
  private _axiosConfig: AxiosRequestConfig;

  /**
* Constructor
* @constructor
* @param {string} baseUrl website root url
* @param {IHeaders} headers http context headers applied to ALL http transactions
*/
  public constructor(baseUrl: string | undefined) {
    if (baseUrl === undefined) {
      throw 'Base address is not defined.';
    }

    this._axiosConfig = {
      baseURL: baseUrl,
    };
  }


  /**
 * Http Post
 * @async
 * @param {string} relativeUrl Relative URL to the endpoint
 * @param {S} body Post body
 * @param {IHeaders} headers http context headers
 * @param {string} requestId Operation, Correlation, or Request ID for tracking request
 * @returns {T} post results or error
 */
  async post<S, T>(relativeUrl: string, body: S, headers: IHeaders = {}, requestId = ''): Promise<T | IProblemDetails> {
    const axiosConfig: AxiosRequestConfig = {
      ...this._axiosConfig,
      headers: this._addRequestId(headers, requestId)
    };

    const result = await axios.post<T>(relativeUrl, body, axiosConfig)
      .then((response) => this._handleResponse<T>(response))
      .catch((error) => this._handleError<IProblemDetails>(error));

    return result;
  }

  /**
* Http Get
* @async
* @param {string} relativeUrl Relative URL to the endpoint
* @param {object} params query string parameters
* @param {IHeaders} headers http context headers
* @param {string} requestId Operation or Correlation ID for tracking request
* @returns {T}  get results or error
*/
  async get<T>(relativeUrl: string, params: object = {}, headers: IHeaders = {}, requestId = ''): Promise<T | IProblemDetails> {
    const axiosConfig: AxiosRequestConfig = {
      ...this._axiosConfig,
      params: params,
      headers: this._addRequestId(headers, requestId)
    };

    const result = await axios.get<T>(relativeUrl, axiosConfig)
      .then((response) => this._handleResponse<T>(response))
      .catch((error) => this._handleError<T>(error));

    return result;
  }

  /**
 * Add header context requestId
 * @param {IHeaders} headers existing header dictionary
 * @param {string} requestId  unique http transaction identifier
 * @returns {IHeaders} new header dictionary
 */
  private _addRequestId(headers: IHeaders, requestId: string): IHeaders {
    return { ...headers, 'RequestId': requestId, 'OperationId': requestId };
  }

  /**
* Http success response handler
* @param {AxiosResponse} response http response object
* @returns {T, string}  contains http response plus requestId
*/
  private _handleResponse<T>(response: AxiosResponse): T {
    const result = response.data as T;
    const requestId = response.headers['requestid'];

    vlogger.verbose(`Http Response: url: ${response.config.url} status: ${response.status}, ` +
      `statusText: ${response.statusText}, requestId: ${requestId}`);

    return result;
  }

  /**
   * Http error response handler
   * @param {AxiosError} error http error object
   * @returns {T, string}  contains http response plus requestId
   */
  private _handleError<T>(error: AxiosError): T {
    const result = (error.response?.data || {}) as T;
    const requestId = error.response?.headers['requestid'] ?? '';

    vlogger.error(`Http Error: name: ${error?.name}, status: ${error?.code || 500}, ` +
      `url: ${error?.config?.baseURL}/${error?.config?.url}, 
      requestId: ${requestId}, ` +
      `message: ${error?.message}`, false);
    vlogger.verbose(`Config Headers: ${JSON.stringify(error?.config?.headers)}`);
    vlogger.verbose(`Config Data: ${JSON.stringify(error?.config?.data)}`);
    vlogger.verbose(`Stack: ${error.stack || 'undefined'}`);

    return result;
  }
}
