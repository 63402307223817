/* eslint-disable react/no-multi-comp */
import React from 'react';
import { ListItem, ListItemText } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ListItemLink = (props: any) => {
  const { primary, to, id, expandMore, expandLess, isLink, depth, onClick, className } = props;
  const newProps = { ...props };
  delete newProps.expandMore; //  delete expandMore and expandList from props
  delete newProps.expandLess;
  delete newProps.isLink;
  delete newProps.depth;

  const renderLink = React.useMemo(
    // eslint-disable-next-line react/display-name
    () => React.forwardRef<HTMLAnchorElement>((props, ref) => <NavLink to={to} ref={ref} id={id} {...props} />),
    [to]
  );

  if (!isLink || typeof to !== 'string') {
    return (
      <ListItem button id={id} sx={{ paddingLeft: `${depth}rem` }} {...newProps}>
        <ListItemText primary={primary} />
        {expandMore && <ExpandMore />}
        {expandLess && <ExpandLess />}
      </ListItem>
    );
  }

  return (
    <ListItem button component={renderLink} sx={{ paddingLeft: `${depth}rem` }}>
      <ListItemText primary={primary} />
    </ListItem>
  );
};
