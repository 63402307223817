import { useEffect, useRef, useState } from 'react';


//  https://dev.to/ag-grid/react-18-avoiding-use-effect-getting-called-twice-4i9e

export const useEffectOnce = (effect: () => void | (() => void)) => {
  const destroyFunction = useRef<void | (() => void)>();
  const effectCalled = useRef(false);
  const renderAfterCalled = useRef(false);
  const [val, setVal] = useState<number>(0);

  if (effectCalled.current) {
    renderAfterCalled.current = true;
  }

  useEffect(() => {
    // only execute the effect first time around
    if (!effectCalled.current) {
      destroyFunction.current = effect();
      effectCalled.current = true;
    }

    // this forces one render after the effect is run
    setVal(val + 1);

    return () => {
      // if the component didn't render since the useEffect was called,
      // we know it's the dummy React cycle
      if (!renderAfterCalled.current) {
        return;
      }
      if (destroyFunction.current) {
        destroyFunction.current();
      }
    };
  }, []);
};
