import { IRoutePath, IDashboardRouteValue } from 'shared/interfaces/iroute-path';
import { ILogger } from 'shared/service/logger/vlogger';
import { UrlHelper } from 'shared/utils/url-helper';
import { StringHelper } from 'shared/utils/string-helper';

/**
 * Router parser
 * Recursive class that dynamically parses an IRoutePath
 * @class
 */
export class RouterParser {
  private _flatRoutes: IRoutePath[];
  private _logger: ILogger;

  /**
   * CTOR
   * @constructor
   */
  constructor(logger: ILogger) {
    this._flatRoutes = [];
    this._logger = logger;
  }


  /**
   * Parse and combine routes
   * @param prelimaryRoutes Guidance from route.ts file
   * @param dashboardRouteValues Looker dashboard information
   * @param routeLocation don't set; used by recursive calls
   * @returns [routes, flatRoutes]
   */
  public parse(
    prelimaryRoutes: IRoutePath[],
    dashboardRouteValues: IDashboardRouteValue[],
    routeLocation = ''
  ): [IRoutePath[], IRoutePath[]] {
    const routes: IRoutePath[] = [];

    prelimaryRoutes.map((d) => {
      const thisRouteLocation = UrlHelper.AppendUrl(routeLocation, d.path || '');

      if (thisRouteLocation.toLowerCase().indexOf('dashboard') > 0 && !d.path) {
        const rp = this.getDashboardRoutePath(d, dashboardRouteValues);
        const compressedTitle = StringHelper.SpaceAndSpecialCharacterRemover(rp.title || '');
        rp.path = encodeURI(UrlHelper.AppendUrl(routeLocation, compressedTitle));
        routes.push(rp);

        this.addToFlatRoute({ ...rp });
      } else {
        d.path = encodeURI(thisRouteLocation);
        routes.push(d);

        this.addToFlatRoute({ ...d });
      }

      if (d.children && d.children.length > 0) {
        const [rtroutes] = this.parse(d.children, dashboardRouteValues, thisRouteLocation);
        // const [rtroutes, unused] = this.parse(d.children, dashboardRouteValues, thisRouteLocation);
        d.children = rtroutes;
      }
    });

    return [routes, this._flatRoutes];
  }

  /**
   * Merge routing components together to generate a Dashboard route
   * @param r common routing part
   * @param dashboardRouteValues dashboard specific routing part
   * @returns dashboard routing element
   */
  private getDashboardRoutePath(r: IRoutePath, dashboardRouteValues: IDashboardRouteValue[]): IRoutePath {
    const rtn: IRoutePath = { ...r };
    const dn = StringHelper.SpaceAndSpecialCharacterRemover(rtn.displayName.toLowerCase());
    const drv = dashboardRouteValues.find((d) => dn === StringHelper.SpaceAndSpecialCharacterRemover(d.title?.toLowerCase()));

    if (drv) {
      rtn.id = drv.id;
      rtn.onPremId = drv.onPremId;
      rtn.title = drv.title;
      rtn.model = drv.model;
      rtn.description = drv.description;
    }

    return rtn;
  }

  /**
   * Add item to the FlatRoute array
   * @param item
   */
  private addToFlatRoute(item: IRoutePath) {
    const frp = { ...item };
    frp.children = [];
    this._flatRoutes.push(frp);
  }
}
