

/**
 * Looker user permissions
 */
export const DefaultUserPermissions = [
  'access_data',
  'create_alerts',
  'download_without_limit',
  'explore',
  'schedule_look_emails',
  // 'schedule_external_look_emails',
  'see_drill_overlay',
  'see_lookml',
  'see_lookml_dashboards',
  'see_looks',
  'see_user_dashboards',
  'send_to_integration'
];
