import React, { MouseEvent } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { HtmlSpan } from 'shared/components/HtmlSpan';
import styled from '@emotion/styled';

/**
 * Styling
 */
const ModalBox = styled(Box)`
  .close {
    position: fixed;
    top: 0.5rem;
    right: 0.5rem;
    color: var(--color-tertiary-dark);
  }

  & h4 {
    color: var(--color-primary-dark);
    text-align: center;
  }

  & p {
    margin: 1rem 0;
    text-align: center;
    line-height: 2;
  }

  & button {
    width: 100px;
  }

  & div#modal-buttons {
    width: 100%;
    margin: 0.5rem 0rem;
    display: inline-flex;
    justify-content: space-around;
  }
`;

/**
 * Props passed 
 */
interface IModalYesNo {
  id: string;
  disableBackdropClick?: boolean;
  open: boolean;
  className?: string;
  title: string;
  text: string;
  onYesClick: (event: MouseEvent<HTMLElement>) => void;
  onNoClick: (event: MouseEvent<HTMLElement>) => void;
}

/**
 * Display existing stored reports
 * @function
 */
export const ModalYesNo = (props: IModalYesNo) => {
  const { id, disableBackdropClick, open, className, title, text, onYesClick, onNoClick } = props;

  // onClose={onNoClick} 

  return (
    <Modal id={id} open={open}
      disableEnforceFocus={disableBackdropClick ?? false}
      aria-labelledby={'modal-title'} aria-describedby={'modal-text'}
    >
      <ModalBox className={`modal ${(className) ? className : ''}`}>
        <Typography id="modal-title" component="h4">
          <HtmlSpan innerHtml={title} />
        </Typography>
        <div className="close" onClick={onNoClick} aria-label="close">
          <Close />
        </div>
        <Typography id="modal-text" component="p">
          <HtmlSpan innerHtml={text} />
        </Typography>
        <div id='modal-buttons'>
          <Button variant="outlined" onClick={onNoClick}>No</Button>
          <Button variant="contained" onClick={onYesClick}>Yes</Button>
        </div>
      </ModalBox>
    </Modal>
  );
};
