import { IPublicClientApplication } from '@azure/msal-browser';
import { defaultUser, IVipUser } from 'redux/profileSlice';
import { VelocityErrorHelper } from 'shared/utils/velocity-error-helper';
import { HttpService, IHttpService, IHeaders } from 'shared/service/http/http-service';
import { getAccessToken } from 'shared/service/http/token-retriever';
import { ILogger, vlogger } from 'shared/service/logger/vlogger';
import { toPermission, UserRole } from 'App/User/user-role';
import { GuidHelper } from 'shared/utils/guid-helper';
import { JwtHelper } from 'shared/utils/jwt-helper';


/**
 * Interface for UserAccount request
 * @interface
 */
export interface IUserAccountRequest {
  firstName?: string;
  lastName?: string;
  email: string;
  userId?: number;
  clientId?: number;
  companyName?: string;

  isDisabled?: boolean;
  isDeleted?: boolean;
  isBeta?: boolean;
  userRole?: string;

  created?: Date;
  last_modified?: Date;

  returnEntity?: boolean;
  operationId?: string;
}

/**
 * Interface for UserAccount response
 * @interface
 * @public
 */
export interface IUserAccountResponse {
  userId?: number;
  isDisabled?: boolean;

  email?: string;
  firstName?: string;
  lastName?: string;
  clientId?: number;

  isBeta?: boolean;
  userRole: UserRole;
}

/**
 * Interface for UserAccount request to Identity Provider
 * @remarks extends IUserAccountRequest with includeComponents
 * @interface
 * @public
 */
interface IIdentityUserAccountRequest extends IUserAccountRequest {
  includeComponents?: boolean;
}

/**
 * Interface for UserAccount response from Identity Provider
 * @interface
 * @public
 */
export interface IIdentityUserAccountResponse {
  sent: boolean;
  subject?: string;
  body?: string;
  signature?: string;
  pLength?: number;
}

/**
 * UserProfile Service Interface
 * @interface
 */
export interface IUserProfileService {
  getUser(userId: string): Promise<IVipUser | null>;
  getUsers(userId?: number): Promise<IVipUser[] | null>;
  getUsersByUserId(userId: number): Promise<IVipUser[] | null>;
  addUpdateUser(user: IVipUser, returnEntity?: boolean): Promise<IVipUser | null>;
  registerUserAccount(request: IIdentityUserAccountRequest, includeComponents: boolean): Promise<IIdentityUserAccountResponse | null>;
  resetUserPassword(request: IIdentityUserAccountRequest, includeComponents: boolean): Promise<IIdentityUserAccountResponse | null>;
}

/** 
 * Service representing a User Profile service object
 * @class
 * @implements IUserProfileService
 */
export class UserProfileService implements IUserProfileService {
  private _pca: IPublicClientApplication;
  private _logger: ILogger;
  private _httpService: IHttpService;

  /**
   *  Default account user response based on default user
   * @private
   * @property
   */
  private _defaultUserAccountResponse: IUserAccountResponse = {
    userId: defaultUser.userId,
    firstName: defaultUser.firstName,
    lastName: defaultUser.lastName,
    email: defaultUser.email,
    clientId: defaultUser.clientId,
    isDisabled: defaultUser.isDisabled ?? true,
    isBeta: defaultUser.isBeta ?? false,
    userRole: defaultUser.userRole,
  };

  constructor(pca: IPublicClientApplication, logger: ILogger) {
    this._pca = pca;
    this._logger = logger;

    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const headers: IHeaders = { 'Content-Type': 'application/json' };
    this._httpService = new HttpService(this._logger, baseUrl, headers);
  }


  /**
   * Fetch user account profile
   * @param userId unique user identifier (typically email)
   * @returns user account profile
   * @async
   * @public
   */
  async getUser(userId: string): Promise<IVipUser | null> {
    const uuid = GuidHelper.NewGuid();
    const request: IUserAccountRequest = { email: userId };
    const accessToken = await getAccessToken(this._pca);
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      // 'X-CID': `${JwtHelper.GetClientId(accessToken)}`
    };

    const result =
      await this._httpService.post<IUserAccountRequest, IUserAccountResponse>('api/v1/user/getUserAccount', request, headers, uuid);

    if (result === null) return null;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }

    let userAccount: IVipUser;
    if (!result.result) {
      this._logger.error(`getUserProfile was unable to return results.   operationId: ${uuid}`);
      userAccount = defaultUser;
    } else {
      userAccount = this.responseMapperToVipUser(result.result);
    }

    return userAccount;
  }


  /**
   * Fetch all users
   * @param uuid 
   * @returns IVipUser[] of all Vip registered users
   * @async
   * @public
   */
  async getUsers(userId?: number): Promise<IVipUser[] | null> {
    const uuid = GuidHelper.NewGuid();
    const accessToken = await getAccessToken(this._pca);
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      // 'X-CID': `${JwtHelper.GetClientId(accessToken)}`
    };
    const request: { id: number | null } = { id: userId || null };

    const result =
      await this._httpService.get<IUserAccountResponse[]>('api/v1/user/getAllUsers', request, headers, uuid);

    if (result === null) return null;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }
    if (!result.result) {
      this._logger.error(`getUsers was unable to return results.   operationId: ${uuid}`);
      result.result = [this._defaultUserAccountResponse];
    }

    const users: IVipUser[] = [];
    result.result.forEach(u => {
      const user: IVipUser = this.responseMapperToVipUser(u);
      users.push(user);
    });

    return users;
  }

  /**
   * Fetch users by userId
   * @param userId 
   * @returns IVipUser[] of all Vip registered users
   */
  async getUsersByUserId(userId?: number): Promise<IVipUser[] | null> {
    if (!userId) {
      this._logger.error(`getUsersByUserId was unable to return results.   userId: ${userId}`);
      return null;
    }

    const uuid = GuidHelper.NewGuid();
    const request: { userId: number } = { userId: userId };
    const accessToken = await getAccessToken(this._pca);
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      // 'X-CID': `${JwtHelper.GetClientId(accessToken)}`
    };
    const result =
      await this._httpService.get<IUserAccountResponse[]>('api/v1/userClient/getUsersByUserId', request, headers, uuid);

    if (result === null) return null;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }
    if (!result.result) {
      this._logger.error(`getUsers was unable to return results.   operationId: ${uuid}`);
      result.result = [this._defaultUserAccountResponse];
    }

    const users: IVipUser[] = [];
    result.result.forEach(u => {
      const user: IVipUser = this.responseMapperToVipUser(u);
      users.push(user);
    });

    return users;
  }

  /**
   * Add or update user
   * @param {IUserAccountRequest} user user account request
   * @param {boolean} returnEntity return entity flag 
   * @returns {IVipUser} added or updated user
   */
  async addUpdateUser(user: IUserAccountRequest, returnEntity: boolean = false): Promise<IVipUser | null> {
    const uuid = GuidHelper.NewGuid();
    const request: IUserAccountRequest = { ...user };
    request.returnEntity = returnEntity;
    const accessToken = await getAccessToken(this._pca);
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      // 'X-CID': `${JwtHelper.GetClientId(accessToken)}`
    };
    const result =
      await this._httpService.post<IUserAccountRequest, IUserAccountResponse>('api/v1/user/addUserAccount', request, headers, uuid);

    if (result === null) return null;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }
    vlogger.verbose(`${request.email} addUpdateUser successful`);

    // Two possible return values:  httpstatus 200 or 204 based on returnEntity parameter
    //    returnEntity = true  =>  result contains the user added (httpstatus 200)
    //    returnEntity = false =>  result is empty  (httpstatus 204)
    const response: IUserAccountResponse = result.result;
    return (response) ? this.responseMapperToVipUser(response) : defaultUser;
  }

  /**
   * Register a new user with identity provider
   * @param {IIdentityUserAccountRequest} request register user account request
   * @returns {IIdentityUserAccountResponse} register user account response
   */
  async registerUserAccount(request: IIdentityUserAccountRequest, includeComponents: boolean = false): Promise<IIdentityUserAccountResponse | null> {
    const uuid = GuidHelper.NewGuid();
    request.includeComponents = includeComponents;
    const accessToken = await getAccessToken(this._pca);
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      // 'X-CID': `${JwtHelper.GetClientId(accessToken)}`
    };
    const result =
      await this._httpService.post<IIdentityUserAccountRequest, IIdentityUserAccountResponse>('api/v1/user/registerUserAccount', request, headers, uuid);

    if (result === null) return null;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }

    if (result === null || result.errors.length !== 0 || result.result.sent !== true) {
      VelocityErrorHelper.Logify(result?.errors ?? [], result?.operationId, false, false);
      return null;
    } else {
      vlogger.verbose(`${request.email} registration successful`);
      return result.result;
    }
  }

  /**
 * Reset user password with identity provider
 * @param {IIdentityUserAccountRequest} request register user account request
 * @returns {IIdentityUserAccountResponse} register user account response
 */
  async resetUserPassword(request: IIdentityUserAccountRequest, includeComponents: boolean = false): Promise<IIdentityUserAccountResponse | null> {
    const uuid = GuidHelper.NewGuid();
    request.includeComponents = includeComponents;
    const accessToken = await getAccessToken(this._pca);
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      // 'X-CID': `${JwtHelper.GetClientId(accessToken)}`
    };
    const result =
      await this._httpService.post<IIdentityUserAccountRequest, IIdentityUserAccountResponse>('api/v1/user/resetpassworduseraccount', request, headers, uuid);

    if (result === null) return null;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }

    if (result === null || result.errors.length !== 0 || result.result.sent !== true) {
      VelocityErrorHelper.Logify(result?.errors ?? [], result?.operationId, false, false);
      return null;
    } else {
      vlogger.verbose(`${request.email} reset password successful`);
      return result.result;
    }
  }

  /**
   * Common mapper use to map IVipUser to IUserAccountRequest
   * @param  {IUserAccountResponse} r user account response
   * @returns {IVipUser }User endpoint request
   * @remarks all missing values are set to defaultUser values
   * @private
   */
  private responseMapperToVipUser(r: IUserAccountResponse): IVipUser {
    const userRole = r.userRole ?? defaultUser.userRole;

    return {
      ...defaultUser,
      ...r,
      permission: toPermission(userRole),
    };
  }
}
