import { IVipUser } from 'redux/profileSlice';
import { IDictionary } from 'shared/interfaces/idictionary';
import { IUserRole } from 'shared/service/profile/role-profile';
import { EmailHelper } from 'shared/utils/email-helper';

/**
 * Global User Roles
 */

/**
 * UserRole Enum declarations
 */
export enum UserRole {
  FI_USER = 'FI_USER',
  FI_ADMIN = 'FI_ADMIN',
  SUPPORT = 'SUPPORT',
  TRAINING = 'TRAINING',
  CSM_USER = 'CSM_USER',
  CSM_ADMIN = 'CSM_ADMIN',
  ADMINISTRATOR = 'ADMINISTRATOR',
  DEVELOPER = 'DEVELOPER',
}

/**
 * UserRole mapping from enum to display name
 */
export const UserRoleDisplay: IDictionary<UserRole, string> = {
  [UserRole.FI_USER]: 'FI User',
  [UserRole.FI_ADMIN]: 'FI Admin',
  [UserRole.SUPPORT]: 'Support',
  [UserRole.TRAINING]: 'Training',
  [UserRole.CSM_USER]: 'CSM User',
  [UserRole.CSM_ADMIN]: 'CSM Admin',
  [UserRole.ADMINISTRATOR]: 'Administrator',
  [UserRole.DEVELOPER]: 'Developer'
};

export function getUserRoleByDisplayValue(value: string): UserRole | null {
  const entry =
    Object.entries(UserRoleDisplay).find(([key, val]) => val === value);
  return entry ? entry[0] as UserRole : null;
}

/**
 * What UserRoles a user can see
 * @param {UserRole} userRole the logged in user's role
 * @param {UserRole} role the UserRole that the logged in user wishes to see
 */
export function canAccessRole(userRole: IUserRole | undefined, role: IUserRole): boolean {
  return ((role.priority ?? 10) <= (userRole?.priority ?? -1));
}


export enum ApplicationSection {
  DASHBOARD = 'DASHBOARD',
  REPORT = 'REPORT',
  TRAINING = 'TRAINING',
  SELF_ADMIN = 'SELF_ADMIN',
  USER_ADMIN = 'USER_ADMIN',
  BRANCH_GOAL_ADMIN = 'BRANCH_GOAL_ADMIN',
  CLIENT_ADMIN = 'CLIENT_ADMIN',
  CSM_ADMIN = 'CSM_ADMIN',
  STATUS = 'STATUS',
  STATS = 'STATS'
}

export enum Permission { 'NONE', 'VIEW', 'EDIT', 'ADD', 'DISABLE', 'DELETE' }

export type SectionPermission = IDictionary<ApplicationSection, Permission | undefined>;

/**
 * Permissions by role
 */
export const FIUserPermissions: SectionPermission = {
  [ApplicationSection.DASHBOARD]: Permission.VIEW,
  [ApplicationSection.REPORT]: Permission.VIEW,
  [ApplicationSection.TRAINING]: Permission.VIEW,
  [ApplicationSection.SELF_ADMIN]: Permission.EDIT,
  [ApplicationSection.USER_ADMIN]: Permission.NONE,
  [ApplicationSection.BRANCH_GOAL_ADMIN]: Permission.NONE,
  [ApplicationSection.CLIENT_ADMIN]: Permission.NONE,
  [ApplicationSection.CSM_ADMIN]: Permission.NONE,
  [ApplicationSection.STATUS]: Permission.NONE,
  [ApplicationSection.STATS]: Permission.NONE
};

export const FIAdminPermissions: SectionPermission = {
  [ApplicationSection.DASHBOARD]: Permission.VIEW,
  [ApplicationSection.REPORT]: Permission.VIEW,
  [ApplicationSection.TRAINING]: Permission.VIEW,
  [ApplicationSection.SELF_ADMIN]: Permission.EDIT,
  [ApplicationSection.USER_ADMIN]: Permission.DISABLE,
  [ApplicationSection.BRANCH_GOAL_ADMIN]: Permission.VIEW,
  [ApplicationSection.CLIENT_ADMIN]: Permission.NONE,
  [ApplicationSection.CSM_ADMIN]: Permission.NONE,
  [ApplicationSection.STATUS]: Permission.NONE,
  [ApplicationSection.STATS]: Permission.NONE
};

export const SupportPermissions: SectionPermission = {
  [ApplicationSection.DASHBOARD]: Permission.VIEW,
  [ApplicationSection.REPORT]: Permission.VIEW,
  [ApplicationSection.TRAINING]: Permission.VIEW,
  [ApplicationSection.SELF_ADMIN]: Permission.EDIT,
  [ApplicationSection.USER_ADMIN]: Permission.VIEW,
  [ApplicationSection.BRANCH_GOAL_ADMIN]: Permission.VIEW,
  [ApplicationSection.CLIENT_ADMIN]: Permission.VIEW,
  [ApplicationSection.CSM_ADMIN]: Permission.NONE,
  [ApplicationSection.STATUS]: Permission.NONE,
  [ApplicationSection.STATS]: Permission.NONE
};

export const TrainingPermissions: SectionPermission = {
  [ApplicationSection.DASHBOARD]: Permission.VIEW,
  [ApplicationSection.REPORT]: Permission.DELETE,
  [ApplicationSection.TRAINING]: Permission.DELETE,
  [ApplicationSection.SELF_ADMIN]: Permission.EDIT,
  [ApplicationSection.USER_ADMIN]: Permission.DISABLE,
  [ApplicationSection.BRANCH_GOAL_ADMIN]: Permission.EDIT,
  [ApplicationSection.CLIENT_ADMIN]: Permission.VIEW,
  [ApplicationSection.CSM_ADMIN]: Permission.NONE,
  [ApplicationSection.STATUS]: Permission.NONE,
  [ApplicationSection.STATS]: Permission.NONE
};

export const CSMUserPermissions: SectionPermission = {
  [ApplicationSection.DASHBOARD]: Permission.VIEW,
  [ApplicationSection.REPORT]: Permission.DELETE,
  [ApplicationSection.TRAINING]: Permission.DELETE,
  [ApplicationSection.SELF_ADMIN]: Permission.EDIT,
  [ApplicationSection.USER_ADMIN]: Permission.DISABLE,
  [ApplicationSection.BRANCH_GOAL_ADMIN]: Permission.EDIT,
  [ApplicationSection.CLIENT_ADMIN]: Permission.VIEW,
  [ApplicationSection.CSM_ADMIN]: Permission.NONE,
  [ApplicationSection.STATUS]: Permission.NONE,
  [ApplicationSection.STATS]: Permission.NONE
};

export const CSMAdminPermissions: SectionPermission = {
  [ApplicationSection.DASHBOARD]: Permission.VIEW,
  [ApplicationSection.REPORT]: Permission.DELETE,
  [ApplicationSection.TRAINING]: Permission.DELETE,
  [ApplicationSection.SELF_ADMIN]: Permission.EDIT,
  [ApplicationSection.USER_ADMIN]: Permission.DISABLE,
  [ApplicationSection.BRANCH_GOAL_ADMIN]: Permission.VIEW,
  [ApplicationSection.CLIENT_ADMIN]: Permission.DISABLE,
  [ApplicationSection.CSM_ADMIN]: Permission.DISABLE,
  [ApplicationSection.STATUS]: Permission.NONE,
  [ApplicationSection.STATS]: Permission.VIEW
};

export const AdministratorPermissions: SectionPermission = {
  [ApplicationSection.DASHBOARD]: Permission.DELETE,
  [ApplicationSection.REPORT]: Permission.DELETE,
  [ApplicationSection.TRAINING]: Permission.DELETE,
  [ApplicationSection.SELF_ADMIN]: Permission.DELETE,
  [ApplicationSection.USER_ADMIN]: Permission.DELETE,
  [ApplicationSection.BRANCH_GOAL_ADMIN]: Permission.EDIT,
  [ApplicationSection.CLIENT_ADMIN]: Permission.DELETE,
  [ApplicationSection.CSM_ADMIN]: Permission.DELETE,
  [ApplicationSection.STATUS]: Permission.VIEW,
  [ApplicationSection.STATS]: Permission.DELETE
};

export const DeveloperPermissions: SectionPermission = {
  [ApplicationSection.DASHBOARD]: Permission.DELETE,
  [ApplicationSection.REPORT]: Permission.DELETE,
  [ApplicationSection.TRAINING]: Permission.DELETE,
  [ApplicationSection.SELF_ADMIN]: Permission.DELETE,
  [ApplicationSection.USER_ADMIN]: Permission.DELETE,
  [ApplicationSection.BRANCH_GOAL_ADMIN]: Permission.DELETE,
  [ApplicationSection.CLIENT_ADMIN]: Permission.DELETE,
  [ApplicationSection.CSM_ADMIN]: Permission.DELETE,
  [ApplicationSection.STATUS]: Permission.DELETE,
  [ApplicationSection.STATS]: Permission.DELETE
};

/**
 * Convert user role to permission by application section matrix (key-value mapping)
 * @param  {UserRole} role user role
 * @returns {SectionPermission} key value map of application section to permission for a user role
 */
export function toPermission(role?: UserRole): SectionPermission {
  let permission: SectionPermission = FIUserPermissions;

  if (!role || role === UserRole.FI_USER)
    permission = FIUserPermissions;
  else if (role === UserRole.FI_ADMIN)
    permission = FIAdminPermissions;
  else if (role === UserRole.SUPPORT)
    permission = SupportPermissions;
  else if (role === UserRole.TRAINING)
    permission = TrainingPermissions;
  else if (role === UserRole.CSM_USER)
    permission = CSMUserPermissions;
  else if (role === UserRole.CSM_ADMIN)
    permission = CSMAdminPermissions;
  else if (role === UserRole.ADMINISTRATOR)
    permission = AdministratorPermissions;
  else if (role === UserRole.DEVELOPER)
    permission = DeveloperPermissions;

  return permission;
}

/**
 * Helper function to check if user is authorized for a specific action
 * @param user logged in user
 * @param applicationSection 
 * @param requiredPermission min permission required to access this applicationSection
 * @returns true = is authorized (has access)
 */
export function hasAccess(user: IVipUser, applicationSection: ApplicationSection, requiredPermission: Permission): boolean {
  const userPermission = user.permission[applicationSection] ?? Permission.NONE;
  return userPermission >= requiredPermission;
}

/**
 * 
 * AUTHORIZATION HELPER FUNCTION
 * 
 */

/**
 * Can impersonate multiple clients (FIs)
 * @param {IVipUser} user logged in user
 * @returns true = authorized
 */
export function canImpersonateHelper(user?: IVipUser): boolean {
  const role = user?.userRole;
  const email = EmailHelper.isVelocityEmail(user?.email);
  return (role && email)
    ? (role === UserRole.CSM_USER || role === UserRole.CSM_ADMIN ||
      role === UserRole.SUPPORT || role === UserRole.TRAINING ||
      role === UserRole.DEVELOPER || role === UserRole.ADMINISTRATOR)
    : false;
}

/**
 * Can filter user settings by 'Administration' role
 * @param {UserRole} role user's assigned role
 * @returns true = authorized
 */
export function canUserFilterByAdmin(role?: UserRole): boolean {
  return (role)
    ? (role === UserRole.ADMINISTRATOR || role === UserRole.DEVELOPER)
    : false;
}

/**
 * Has access to a subset of clients
 * @param {UserRole} role user's assigned role
 * @returns true = authorized
 */
export function hasFilteredClientsByUserId(role?: UserRole): boolean {
  return (role)
    ? (role === UserRole.CSM_USER)
    : false;
}

/**
 * Has access to a subset of users
 * @param {UserRole} role user's assigned role
 * @returns true = authorized
 */
export function hasFilteredUsersByUserId(role?: UserRole): boolean {
  return (role)
    ? (role === UserRole.FI_ADMIN || role === UserRole.CSM_USER)
    : false;
}

/**
 * Is the assigned UserRole to a FI employee (default UserRole)
 * @param {UserRole} role user's assigned role
 * @returns true = has FI role
 */
export function isFIRole(role?: UserRole): boolean {
  return (role)
    ? (role === UserRole.FI_USER || role === UserRole.FI_ADMIN)
    : true;   // default role
}


/**
 * Is given developer beta feature privileges
 * @param {IVipUser} user logged in user
 * @returns true = authorized
 */
export function isDeveloperHelper(user?: IVipUser): boolean {
  const role = user?.userRole;
  const email = EmailHelper.isVelocityEmail(user?.email);
  return (role && email)
    ? (role === UserRole.DEVELOPER)
    : false;
}

/**
 * Is site admin priveleges
 * @param {IVipUser} user logged in user
 * @returns true = authorized
 */
export function isAdmin(user?: IVipUser): boolean {
  const role = user?.userRole;
  const email = EmailHelper.isVelocityEmail(user?.email);
  return (role && email)
    ? (role === UserRole.ADMINISTRATOR || role === UserRole.DEVELOPER)
    : false;
}
