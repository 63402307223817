import { IDictionary } from './idictionary';


/**
 * Feature Flag constants
 */
export const featureFlagValues: IDictionary<string, string> = {
  insights: 'INSIGHTS',
  ars_fp: 'ARS_FP',
  undetermined: 'UNDETERMINED'
};


/**
 * Feature Flags
 * @interface
 * @public
 */
export interface IFeatureFlag {
  featureId: number;
  featureName: string;
  featureDescription: string;
  created?: Date;
  last_modified?: Date;
}

/**
 * Client Feature interface
 * @interface
 * @public
 */
export interface IClientFeature {
  id?: number;
  clientId: number;
  featureFlagId: number;
  isEnabled: boolean;
  created?: Date;
  last_modified?: Date;
}

/**
 * Interface for storing FeatureFlags enablement
 * @interface
 * @public
 */
export interface IFeatureFlagEnabled {
  isARSFpEnabled: boolean;
  isInsightsEnabled: boolean;
}
