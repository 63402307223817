import { products } from 'App/app-constants';
import { IVipCompany } from 'redux/profileSlice';


/**
 * Static Product Mapping Helper class
 * @class
 */
export class ProductHelper {

  /**
   * Return list of product abbreviations for a specific company
   */
  static companyToAbbrev(c: IVipCompany): string[] {
    const abbrev: string[] = [];

    const productKeys = Object.keys(products);

    productKeys.forEach(value => {
      switch (value) {
        case 'ars':
          if (c.isARS)
            abbrev.push('ARS');
          break;
        case 'cp':
          if (c.isCP)
            abbrev.push('CP');
          break;
        case 'mrpc':
          if (c.isMRPC)
            abbrev.push('MRPC');
          break;
        case 'ils':
          if (c.isILS)
            abbrev.push('ILS');
          break;
        case 'ics':
          if (c.isICS)
            abbrev.push('ICS');
          break;
        case 'ois':
          if (c.isOIS)
            abbrev.push('OIS');
          break;
        default:
          break;
      }
    });

    return abbrev;
  }


  /**
   * 
   * @param {IVipCompany[]} arr list of companies
   * @param {IVipCompany} element new company to insert into the company list
   * @returns {IVipCompany[]}  updated alphabetical list of companies that include 'element'
   */
  static insertInOrder(arr: IVipCompany[], element: IVipCompany): IVipCompany[] {
    let index = arr.findIndex((e) => e.name >= element.name);

    //  not found so add to end of list
    if (index < 0) {
      index = arr.length;
    }

    //  if not in arr list then add it
    if (arr[index]?.clientId !== element?.clientId) {
      arr.splice(index, 0, element);
    }

    return arr;
  }
}
