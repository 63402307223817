import { FileHandler, FileSegments, IFileHandleConfiguration } from 'shared/components/FileHandler';
import styled from '@emotion/styled';

const ReportsContainer = styled.div`
`;

/**
 * Display existing stored reports
 * @function
 */
export const Reports = () => {

  const ReportsConfiguration: IFileHandleConfiguration = {
    section: FileSegments.Reports,
    sectionHeader: 'Reports',
    uploadHeaderButtonText: 'Upload Reports',
    uploadDownloadButtonText: 'Upload Reports',
    uploadPageTitle: 'Upload Reports',
  };

  return (
    <ReportsContainer id='reports-container'>
      <FileHandler configuration={ReportsConfiguration} />
    </ReportsContainer>
  );
};
