import { ReactElement, useState, FocusEvent } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { parseISO } from 'date-fns';
import { StyledTextField } from 'shared/components/Textbox/StyleTextField';
import { vlogger } from 'shared/service/logger/vlogger';


/**
 * Date Input props
 * @interface
 */
export default interface IDateInputProps {
  tabIndex: number;
  required: boolean;
  name: string;
  label?: string | ReactElement;
  id: string;
  maxDate?: Date;
  minDate?: Date;
  disableFuture?: boolean;
  disablePast?: boolean;
  errorMessage: string;
  readOnly?: boolean;
  error?: boolean;
  disabled: boolean;
  changeHandler: (value: string | undefined) => void;
  defaultValue?: string;
  format?: string;
}

/**
* Is value a valid Date object
* @param {Date | string | null | undefined} value 
* @returns {boolean} true = valid date object
*/
export const isValidDateObject = (value?: Date | string | null): boolean => {
  if (!value) return false;

  if (typeof value === 'string')
    return !isNaN(Date.parse(value));

  if (value instanceof Date)
    return !isNaN(Date.parse(`${value}`));

  return false;
};


/**
 * Date Picker
 * @param {IDateInputProps} props
 * @returns 
 */
export const DateInput = (props: IDateInputProps) => {
  const { tabIndex, id, disabled, maxDate, minDate, disableFuture, disablePast, defaultValue, format,
    readOnly, error, errorMessage, name, required, changeHandler } = props;
  const [datePickerValue, setDatePickerValue] = useState<Date | null>(
    (defaultValue && parseISO(defaultValue)) || null
  );

  return (
    <DatePicker
      disabled={disabled}
      maxDate={maxDate}
      minDate={minDate}
      className="datePicker"
      // tabIndex={tabIndex}
      views={['year', 'month', 'day']}
      disableFuture={disableFuture}
      disablePast={disablePast}
      value={datePickerValue}
      format={format}
      readOnly={readOnly}
      onChange={(newDateValue: Date | null) => {
        setDatePickerValue(newDateValue);

        if (isValidDateObject(newDateValue))
          changeHandler(newDateValue?.toString());
      }}
      slots={{ textField: StyledTextField }}
      slotProps={{
        textField: {
          name: name,
          id: id,
          required: required,
          helperText: error ? errorMessage : ' ',
          error: error,
          fullWidth: true,
          autoComplete: 'off',
          onBlur: (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setDatePickerValue(new Date(e.target.value));

            if (isValidDateObject(e.target.value))
              changeHandler(e.target.value);
            vlogger.verbose(e.target.value);
          }
        },
        // inputAdornment: { position: 'start' }
      }}
    />
  );
};
