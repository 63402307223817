import { IPublicClientApplication } from '@azure/msal-browser';
import { defaultCompany, IVipCompany } from 'redux/profileSlice';
import { VelocityErrorHelper } from 'shared/utils/velocity-error-helper';
import { HttpService, IHttpService, IHeaders } from 'shared/service/http/http-service';
import { getAccessToken } from 'shared/service/http/token-retriever';
import { ILogger } from 'shared/service/logger/vlogger';
import { GuidHelper } from 'shared/utils/guid-helper';


/**
 * Interface for Company request
 * @interface
 */
export interface ICompanyRequest {
  clientId?: number;
  name?: string;
  orderBy?: boolean;
}

/**
 * Interface for Company response
 * @interface
 */
export interface ICompanyResponse {
  name: string;
  clientId?: number;
  domainId?: number;
  corpId?: number;
  corporationId?: number;
  isActive?: boolean;
  isHosted?: boolean;
  isRepresentment?: boolean;
  isARS?: boolean;
  isCP?: boolean;
  isILS?: boolean;
  isMRPC?: boolean;
  isICS?: boolean;
  isOIS?: boolean;
  isODM?: boolean;
  isMultiMonthRevenueModel?: boolean;
}

/**
 * CompanyProfile Service Interface  - use for DI (or DI like)
 * @interface
 */
export interface ICompanyProfileService {
  getCompanies(): Promise<IVipCompany[] | null>;
  getCompanyByClientId(clientId: number): Promise<IVipCompany[] | null>;
}

/** 
 * Service representing a Company Profile service object
 * @class
 * @implements ICompanyProfileService
 */
export class CompanyProfileService implements ICompanyProfileService {
  private _pca: IPublicClientApplication;
  private _logger: ILogger;
  private _httpService: IHttpService;

  //   default account user response based on default user
  private _defaultCompanyResponse: ICompanyResponse = { ...defaultCompany };

  constructor(pca: IPublicClientApplication, logger: ILogger) {
    this._pca = pca;
    this._logger = logger;
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const headers: IHeaders = { 'Content-Type': 'application/json' };
    this._httpService = new HttpService(this._logger, baseUrl, headers);
  }

  /**
 * Fetch company profiles
 * @returns company profiles
 * @remarks function that can be mocked
 */
  async getCompanies(): Promise<IVipCompany[] | null> {
    const uuid = GuidHelper.NewGuid();
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getAccessToken(this._pca)}`
    };
    const result =
      await this._httpService.get<ICompanyResponse[]>('api/v1/client/getAllClients', {}, headers, uuid);

    if (result === null) return null;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }
    if (!result.result) {
      this._logger.error(`getCompanyProfiles was unable to return results.   operationId: ${uuid}`);
      result.result = [this._defaultCompanyResponse];
    }

    return result.result as IVipCompany[];
  }


  /**
   * Fetch company by clientId
   * @param clientId 
   * @returns IVipCompany[] Vip registered client
   */
  async getCompanyByClientId(clientId: number): Promise<IVipCompany[] | null> {
    const uuid = GuidHelper.NewGuid();
    const request: ICompanyRequest = { clientId: clientId };
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getAccessToken(this._pca)}`
    };
    const result =
      await this._httpService.get<ICompanyResponse>('api/v1/client/getClientById', request, headers, uuid);

    if (result === null) return null;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }
    if (!result.result) {
      this._logger.error(`getCompanyByClientId was unable to return results.   operationId: ${uuid}`);
      result.result = this._defaultCompanyResponse;
    }

    return [result.result as IVipCompany];
  }


  /**
   * Fetch company by userId
   * @param userId 
   * @returns IVipCompany[] of all Vip registered clients associated with a userId
   */
  async getCompaniesByUserId(userId: number): Promise<IVipCompany[] | null> {
    const uuid = GuidHelper.NewGuid();
    const request: { userId: number } = { userId: userId };
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getAccessToken(this._pca)}`
    };

    const result =
      await this._httpService.get<ICompanyResponse[]>('api/v1/userClient/getClientsByUserId', request, headers, uuid);

    if (result === null) return null;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }
    if (!result.result) {
      this._logger.error(`getCompaniesByUserId was unable to return results.   operationId: ${uuid}`);
      result.result = [this._defaultCompanyResponse];
    }

    return result.result as IVipCompany[];
  }
}

