import React, { MouseEvent } from 'react';
import { DataGrid, GridColDef, GridCellParams, GridRowModel } from '@mui/x-data-grid';
import { format } from 'date-fns-tz';
import { DisplayListingMenu } from './DisplayListingMenu';
import { IDirectoryListing } from 'shared/components/FileHandler/file-service';
import { SortedDescendingIcon, SortedAscendingIcon } from 'shared/components/Datagrid/SortedIcon';
import { NothingAvailableOverlay } from 'shared/components/Datagrid/NothingAvailableOverlay';
import styled from '@emotion/styled';


//  number of licensed product listings to display
const maxToDisplay = 100;

/**
 * Props passed 
 */
export interface IDisplayListingDataTableProps {
  listing: IDirectoryListing[];
  isAdmin: boolean;
  numberToDisplay?: number;
  section: string;
  onMenuClick: (event: MouseEvent<HTMLDivElement>, rowdata: GridRowModel) => void;
}

/**
 * Styling
 */
const DisplayTableDiv = styled.div`
& .MuiDataGrid-columnHeaderWrapper {
  & .MuiButtonBase-root {
    min-width: 70%;
    display: flex;
  }
}
`;

/**
 * Display reports data table
 * @param {IDisplayListingDataTableProps} props
 */
export const DisplayListingDataTable = (props: IDisplayListingDataTableProps) => {
  const { listing, isAdmin, numberToDisplay, section, onMenuClick } = props;

  const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

  //  DataGrid Column definition
  const columns: GridColDef[] = [
    { field: 'colName', headerName: 'Name', flex: 3, headerAlign: 'left', minWidth: 260 },
    { field: 'colDate', headerName: 'Date', flex: 1, headerAlign: 'left', minWidth: 90 },
    { field: 'colFormat', headerName: 'Format', flex: 1, headerAlign: 'left', minWidth: 90 },
    {
      field: 'colMenu', headerName: ' ', width: 30, align: 'center', hideSortIcons: true,
      renderCell: (params: GridCellParams) => (
        <div onClick={(e) => onMenuClick(e, params.row)}>
          <DisplayListingMenu isAdmin={isAdmin} aria-label="display listing menu" />
        </div>
      )
    },
  ];

  let index = 1;

  const rows = listing.map(l => {
    return {
      id: index++,
      colName: l.name,
      colDate: format(new Date(l.filedate), 'yyyy/MM/dd', { timeZone: timeZoneName }),
      colFormat: l.format,
      colMenu: ''
    };
  });


  return (
    <DisplayTableDiv>
      <DataGrid columns={columns} rows={rows}
        paginationModel={{ page: 0, pageSize: numberToDisplay ?? maxToDisplay }}
        sortingOrder={['desc', 'asc']}
        initialState={{
          sorting: { sortModel: [{ field: 'colDate', sort: 'desc' }] },
        }}
        disableColumnMenu
        disableRowSelectionOnClick
        hideFooter={true}
        autoHeight
        slots={{
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
          noRowsOverlay: NothingAvailableOverlay,
        }}
        slotProps={{
          noRowsOverlay: { text: `${section}` }
        }}
      />
    </DisplayTableDiv>
  );
};
