import { v4 as uuidv4 } from 'uuid';

/**
 * Static Guid Helper class
 * @class
 */
export class GuidHelper {
  /**
   * RFC4122: The version 4 UUID is meant for generating UUIDs from truly-random or pseudo-random numbers.
   * uuidv4 generates guids from cryprtographically-string random
   * @returns GUID (uuid)
   */
  static NewGuid(): string {
    return uuidv4();
  }

  /**
   * Verifies if a string is  GUID
   * @param guid to be verified
   * @returns true = is guid
   */
  static isGuid(guid: string): boolean {
    const regexGuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regexGuid.test(guid);
  }
}
