import { TextField } from '@mui/material';
import styled from '@emotion/styled';


/**
 * Styled MUI Text Field with VS design
 */
export const StyledTextField = styled(TextField)`
  && {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 20px;
  }

  & .MuiOutlinedInput-root {
    border-radius: 0;
    margin-bottom: 0px;
    height: 45px;

    input {
      padding: 12px 12px;
    }

    @font-face {
      font-family: "password";
      font-style: normal;
      font-weight: 400;
      src: url(https://velocitysolutionswebassets.azureedge.net/webassets/fonts/password.ttf);
    }

    &.Mui-error {
      background-color: var(--color-error-light);
      border: solid 2px var(--color-error);

      fieldset {
        border-color: var(--color-error);
      }
    }
  }

  & .MuiFormHelperText-root {
    font-weight: bold;
    font-size: 12px;
    margin-left: 0;
    transform: translateY(+3px);
    font-size: 1rem;


    &.Mui-error {
      color: var(--color-error);
    }
  }
`;
