import React, { useState, useRef } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { addUpdateRole } from 'redux/appSlice';
import { useMsal } from '@azure/msal-react';
import { Button, Checkbox, Container, Divider, Drawer, TextField } from '@mui/material';
import { default_userRole, IUserRole } from 'shared/service/profile/role-profile';
import { CloseBar } from 'shared/components/CloseBar';
import styled from '@emotion/styled';


/**
 * Styling
 */
const AdminRoleDrawer = styled(Drawer)`
  & .MuiPaper-root {
    height: 100%;
    width: max(500px, 42rem);
    font-family: 'WhitneySSM', sans-serif;
    font-weight: normal;
  }

  & .MuiContainer-root {
    margin: 0px;
    color: var(--secondary-text);

  & .role-edit-boxes {
    display: flex;
    // flex-direction: row;
    flex-wrap: wrap;
  }

  & .vip-textbox {
    display: flex;
    flex-direction: column;
    margin-top: 2.625rem;
    // font-size: 1.25rem;

    label {
      height: 1.25rem;
      margin-bottom: 0.5rem;
    }

    .textbox {
      div {
        width: 100%
        height: 2.8125rem;
        border-radius: 0px;
      }
    }

    .render-input div {
      border-radius: 0px;
    }
  }

  & button.role-checkbox {
    height: 3.75rem;
    margin: 2.8125rem 1.25rem 2.8125rem 0px;

      span {
        justify-content: left;
        margin-left: -5px;
      }

      label {
        font-size: 1.25rem;
        color: var(--secondary-text);
        line-height: 1.5;
      }
    }


  & button.cancel-button {
    width: 18.75rem;
    margin-top: 2.8125rem ;
  }
  
  button.save-button {
    width: 18.75rem;
    margin: 2.8125rem  0px 0px 1.25rem;
  }
} 
`;


/**
 * Props passed 
 */
interface IAddEditRoleProps {
  actionType: string;
  isOpen: boolean;
  editRole: IUserRole | null;
  onCloseClick: () => void;
}


/**
 * Add / Edit User component
 * @param {IAddEditRoleProps} props 
 * @returns 
 */
export const AddEditRole = (props: IAddEditRoleProps) => {
  const { actionType, isOpen, editRole, onCloseClick } = props;
  const dispatch = useAppDispatch();

  const { instance } = useMsal();
  const [isEnabled, setIsEnabled] = useState<boolean>(editRole?.isEnabled ?? false);

  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);
  const isEnabledRef = useRef<HTMLInputElement>(null);


  //  flag if currently in edit mode
  //  used to disable some fields that aren't editable
  const isEditMode = actionType.toLowerCase().startsWith('e');


  const handleSaveClick = async () => {

    const role: IUserRole = {
      name: nameRef.current?.value ?? '',
      description: descriptionRef.current?.value ?? '',
      isEnabled: isEnabledRef.current?.checked ?? false,
    };

    await dispatch(addUpdateRole({ pca: instance, role: role }));

    onCloseClick();
  };

  return (
    <AdminRoleDrawer anchor={'right'} open={isOpen} aria-label='close add/edit user drawer' >
      <CloseBar onClick={() => onCloseClick()} />

      <Container >
        <h2>{actionType} Role</h2>

        <form noValidate autoComplete="off">
          <div className='display-flex role-edit-boxes'>
            <div className='vip-textbox  width-100'>
              <label htmlFor='roleName'>Role</label>
              <TextField id='roleName' variant='outlined' required className='textbox'
                disabled={isEditMode} defaultValue={editRole?.name || default_userRole}
                inputRef={nameRef}
              />
            </div>

            <div className='vip-textbox  width-100'>
              <label htmlFor='roleDescription'>Description</label>
              <TextField id='roleDescription' variant='outlined' required className='textbox'
                defaultValue={editRole?.description || ''} inputRef={descriptionRef}
              />
            </div>
          </div>

          <div className='display-flex'>
            <Button variant="outlined" className='role-checkbox' onClick={() => setIsEnabled(!isEnabled)} >
              <Checkbox checked={isEnabled} className='checkbox' inputRef={isEnabledRef} />
              <label>IsEnabled</label>
            </Button>
          </div>

          <Divider />

          <div className='display-flex'>
            <Button id='close-edit-user' variant="outlined" onClick={() => onCloseClick()}
              className='cancel-button'
            >
              Cancel
            </Button>

            <Button id='save-edit-user' variant="contained" onClick={handleSaveClick}
              className='save-button'
            >
              Save
            </Button>
          </div>
        </form>
      </Container>
    </AdminRoleDrawer >
  );
};
